import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import axios from "axios";
import NewAfmModal from "./NewAfmModal";
import { UserContext } from "./providers/UserProvider";
const vals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

const AfmList = () => {
  const [clients, setClients] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { user } = useContext(UserContext);
  useEffect(() => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/getAfmClients.php"
      )
      .then((response) => setClients(response.data));
  }, []);
  return (
    <>
      <Header />
      <NewAfmModal
        open={openModal}
        setOpen={setOpenModal}
        setClients={setClients}
      />
      <div className="container mx-auto my-10 pb-10 px-4">
        <div>
          <button
            type="button"
            onClick={(e) => setOpenModal(true)}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Adaugă dosar
          </button>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300 border-spacing-y-2 border-separate">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Nr. Contract
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Nume complet
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  CNP
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  STARE DOSAR
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  VERIFICAT
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  GOLDEN HAND
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white space-y-4 gap-4 border-spacing-2">
              {clients
                ? clients.map((person) => (
                    <tr
                      key={person.ID}
                      className={
                        person.VERIFICAT == 0
                          ? "bg-green-100"
                          : (person.VERIFICAT == 1 &&
                              person.VERIFICAT_EMAIL == user.email) ||
                            person.GOLDEN == 1
                          ? "bg-white mt-10"
                          : "bg-yellow-100 mt-10"
                      }
                    >
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        <span>
                          {person.ID}

                          <svg
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                              "w-4 h-4 " +
                              (vals
                                .map((val) => person["VAL_" + val] == 1)
                                .every((el) => el == true) == true
                                ? "fill-green-500"
                                : vals
                                    .map((val) => person["VAL_" + val] == 0)
                                    .every((el) => el == false) == false
                                ? "fill-yellow-500"
                                : vals
                                    .map((val) => person["VAL_" + val] == 2)
                                    .every((el) => el == false) == false
                                ? "fill-red-500"
                                : "")
                            }
                          >
                            <circle cx="5" cy="5" r="5" />
                          </svg>
                        </span>
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only">NUME</dt>
                          <dd className="mt-1 text-gray-700">
                            {person.NUME || person.PRENUME
                              ? person.NUME + " " + person.PRENUME
                              : person.NUME_FIRMA}
                          </dd>
                          <dt className="sr-only sm:hidden">CNP</dt>
                          <dd className="mt-1 text-gray-500 sm:hidden">
                            {person.CNP ? person.CNP : person.CUI}
                          </dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {person.NUME || person.PRENUME
                          ? person.NUME + " " + person.PRENUME
                          : person.NUME_FIRMA}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {person.CNP ? person.CNP : person.CUI}
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.VERIFICAT == 0
                          ? "Neverificat"
                          : person.GOLDEN == 0
                          ? "Verificat"
                          : "Complet"}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.VERIFICAT_EMAIL}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {person.GOLDEN_EMAIL}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <Link to={"/clientafm/" + person.ID}>
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            // onClick={updateClient}
                          >
                            Vezi
                            <span className="sr-only">, {person.NUME}</span>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AfmList;
