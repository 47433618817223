import AdministrareCalculatorDashboard from "../components/AdministrareCalculatorDashboard";
import Header from "../Header";

const AdministrareCalculator = () => {
  return (
    <>
      <Header />
      <div>
        <AdministrareCalculatorDashboard />
      </div>
    </>
  );
};

export default AdministrareCalculator;
