import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addNewPanou } from "../../api/panouri";
import { addNewInvertor } from "../../api/invertoare";

/* Modal pentru adaugarea unui pacient */
const AddNewInvertor = ({ open, setOpen, fetchData }) => {
  const cancelButtonRef = useRef(null);
  const [newInvertor, setNewInvertor] = useState({
    nume: "",
    putere: "",
    stoc_ramas: "",
  });

  const navigator = useNavigate();

  /* Functie pentru modificarea valorilor noului pacient */
  const onChangeNewPatient = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "putere" || name === "stoc_ramas") {
      if (/^\d*$/.test(value) == true) {
        setNewInvertor((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setNewInvertor((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  /* Functie pentru adaugarea unui pacient */
  const onSubmit = (e) => {
    e.preventDefault();
    const res = addNewInvertor(newInvertor).then((res) => {
      fetchData();
      setOpen(false);
    });
    toast.promise(
      res,
      {
        pending: "Adaugare...",
        success: "Adaugat cu succes!",
        error: "Eroare la adaugare!",
      },
      {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  useEffect(() => {
    if (!open) {
      setNewInvertor({
        nume: "",
        putere: "",
        stoc_ramas: "",
      });
    }
  }, [open]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <UserPlusIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Adaugă panou fotovoltaic
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Completează datele panoului pe care vrei să îl adaugi
                      </p>
                    </div>
                  </div>
                </div>
                <div className="gap-5 mt-6">
                  <div>
                    <div>
                      <label
                        htmlFor="nume"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Nume
                      </label>
                      <div className="mt-2">
                        <input
                          onChange={onChangeNewPatient}
                          type="text"
                          value={newInvertor.nume}
                          name="nume"
                          id="nume"
                          className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-10">
                      <div>
                        <label
                          htmlFor="putere"
                          className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                        >
                          Putere
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeNewPatient}
                            type="text"
                            value={newInvertor.putere}
                            name="putere"
                            id="putere"
                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="tip"
                          className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                        >
                          Tip
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeNewPatient}
                            type="text"
                            value={newInvertor.tip}
                            name="tip"
                            id="tip"
                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="stoc_ramas"
                          className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                        >
                          Stoc
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeNewPatient}
                            type="text"
                            value={newInvertor.stoc_ramas}
                            name="stoc_ramas"
                            id="stoc_ramas"
                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="pret"
                          className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                        >
                          Pret
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeNewPatient}
                            type="text"
                            value={newInvertor.pret}
                            name="pret"
                            id="pret"
                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-officialPrimary sm:col-start-2"
                    onClick={(e) => {
                      onSubmit(e);
                    }}
                  >
                    Adaugă
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Renunță
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddNewInvertor;
