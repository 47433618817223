import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import logo from "./showmine-logo-long.png";
import Header from "./Header";
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
// import { logo } from "./showmine-logo-png.png";
const buletin = [
  {
    id: 1,
    name: "acesta trebuie sa fie in termen de valabilitate la data inscrierii in aplicatie ",
  },
  {
    id: 2,
    name: "se va avea în vedere ca adresa de implementare înscrisa de solicitant în aplicatie sa coincida cu adresa din actul de identitate; nu se accepta adresa de resedinta sau viza de flotant; se accepta carte de identitate provizorie; ",
  },
  {
    id: 3,
    name: "se va verifica daca adresa de implementare apartine regiunii de dezvoltare în care s-a facut înscrierea în aplicatia informatica, în cazul în care adresa de domiciliu este în alta regiune, cererea va fi respinsa.",
  },
];

const anaf = [
  {
    id: 4,
    name: "trebuie sa fle emis pe numele solicitantului si se va identifica cu CNP (pentru persoane fizice) sau CIF (pentru parohii). Nu sunt acceptate certificatele emise pe numele/CNP-urile  coproprietarilor. Adresa din certificatul de atestare NU este obligatoriu sa fie identica cu cea a locului de implementare. Sunt eligibile si certificatele eliberate în SPV;   ",
  },
  {
    id: 5,
    name: "trebuie sa fie în termen de valabilitate la data înscrierii. Termenul de valabilitate pentru persoane fizice este de 90 de zile de la data emiterii si 30 de zile pentru unitati de cult;   ",
  },
  {
    id: 6,
    name: "se va avea în vedere sa nu fie înscrise sume la sectiunea A. OBLIGATII FISCALE SI ALTE OBLIGATII BUGETARE. În situatia în care sunt prevazute obligatii de plata restante la sectiunea A, iar valoarea acestora este mai mica sau egala cu totalul înscris la sectiunea B. SUME DE RAMBURSAT/RESTITUIT, se va considera ca solicitantul NU ARE OBLIGATII DE PLATA RESTANTE;   ",
  },
];

const primarie = [
  {
    id: 7,
    name: "trebuie sa fie emis pe numele solicitantului si se va identiflca cu CNP (pentru persoane fizice) sau CIF (pentru parohii). Nu sunt acceptate certificatele emise pe numele/CNP—urile coproprietarilor. Sunt eligibile si certificatele eliberate în SPV; se considera ca certificatul îndeplineste conditiile de conformitate inclusiv daca în continutul acestuia se regasesc atât CNP-ul solicitantului, cât si CNP—ul coproprietarului",
  },
  {
    id: 8,
    name: "trebuie sa fie în termen de valabilitate la data înscrierii. Termenul de valabilitate este de 30 de zile de la data emiterii; ",
  },
  {
    id: 9,
    name: "se va avea în vedere ca solicitantul sa nu aiba obligatii de plata restante. Sumele înscrise pe coloana „Curente” NU sunt considerate obligatii de plata restante;   ",
  },
  {
    id: 10,
    name: "se va avea în vedere ca la adresa unde se implementeaza proiectul, în cladirea deservita de sistemul de panouri fotovoltaice, sa nu se desfasoare activitati economice.   ",
  },
];

const cartefunciara = [
  {
    id: 11,
    name: "se va verifica valabilitatea de 60 de zile & extrasului, calculata de la momentul eliberarii (datamentionata pe ultima pagina & extrasului);   ",
  },
  {
    id: 12,
    name: "la Cap. A din extrasul de carte funciara: se verifica existenta constmctiei; se verifica daca amplasamentul (adresa sau nr. de carte funciara sau nr. cadastral) imobilului constructie coincide cu cel înscris de solicitant în aplicatie; se verifica la Observatii/qerinte, sau la Destinatie constructie din capitolul -Date referitoare la constructii— ca imobilul sa nu prevada ”activitate economica” sau sa nu prevada vreun tip de imobil destinat desfasurarii de activitati economice;   ",
  },
  {
    id: 13,
    name: "la Cap. B din extrasul de carte funciara: se verifica daca solicitantul este proprietar al imobilului constructie înscris la Cap. A; în cazul în care sistemul de panourifotovoltaice care deserveste, constructia se amplaseaza pe teren, se verifica, în plus fata de veriflcarea mentionata, daca solicitantul este proprietar sau detine un drept de folosinta asupra terenului înscris la Cap. A; nu conteaza daca pe imobilul respectiv sunt unul sau mai multi proprietari.   ",
  },
  {
    id: 14,
    name: "la Cap. C din extrasul de carte funciara: se verifica daca imobilul nu este afectat de sarcini în favoarea unei persoane juridice sau a unei entitati care desfasoara activitati economice, cu exceptia cazului în care este afectat de o ipoteca imobiliara.   ",
  },
  {
    id: 15,
    name: "În extrasul de care funciara nu trebuie sa existe înscrise mentiuni asupra imobilului constructie si teren în favoarea unei persoane juridice/entitate fara personalitate juridica, respectiv: existenta unui contract de comodat/locatiune/închiriere sau precontract de comodat/locatiune/închiriere/vânzare-cumparare, declararea ca sediu social/profesional al unui operator economic/întreprinderi individuale/PFA/orice alta entitate care desfasoara activitate economica sau ca patrimoniu de afectatiune (exemplele nu sunt limitative); în cazul în care din extrasul de carte funciara rezulta o constructie sau o parte a unei constructii în care îsi are sediul/punctul de lucru o întreprindere sau o entitate dintre cele mentionate anterior, fmantarea se poate acorda sub conditia ca implementarea proiectului (deservirea sistemului) sa se realizeze exclusiv pe imobilul/imobilele constructie/partea constructiei de tip locuinta (si anexe gOspodaresti) si doar cu prezentarea dovezii contorizarii separate & acesteia; cerinta contorizarii separate este valabila pentru toate persoanele juridice/PFA/entitate fara personalitate juridica, indiferent daca acestea au doar sediul social/profesional sau un punct de lucru în imobilul constructie respectiv;   ",
  },
  {
    id: 16,
    name: "In extrasul de carte funciara nu sunt înscrise mentiuni legate de: litigii în curs de solutionare la instantele judecatoresti, revendicari potrivit unei legi speciale sau dreptului comun sau proceduri de expr0priere pentru cauza de utilitate publica; ",
  },
  {
    id: 17,
    name: "De asemenea, daca adresa din cartea de identitate nu corespunde cu cea din extrasul de carte funciara si nu a fost încarcat în aplicatia informatica nomenclator stradal/ceniEcat/adeverinta/orice alt document emis de primarie, instalatorul va solicita persoanei fizice o declaratie pe propria raspundere din care sa rezulte ca adresa din cartea de identitate corespunde cu cea din extrasul CF prezentat. Ulterior, pâna la momentul decontarii, persoana fizica va depune la instalator documentul emis de Primarie care certifica identitatea dintre adresa de domiciliu si adresa imobilului înscris în CF. Aceste documente vor fi pastrate conform art. 4.1. litera q) din Contractul de participare în vederea decontarii. În cazul în care instalatia de panouri fotovoltaice se monteaza pe imobilul teren, iar acesta este înregistrat în alta carte funciara decât cea a constructiei, fiind prezentate doua extrase de carte funciara, în extrasul de carte funciara aferent terenului se vor urmari aceleasi elemente prevazute mai sus (cu exceptia dreptului de proprietate)   ",
  },
];
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
const GenerateReport = () => {
  const { id } = useParams();
  const [dosar, setDosar] = useState();
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFFFFF",
      paddingHorizontal: "10px",
      paddingVertical: "10px",
      fontFamily: "Open Sans",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      textAlign: "center",
    },
    header: {
      textAlign: "center",
      fontSize: "10px",
    },
    title: {
      textAlign: "center",
      fontSize: "22px",
      marginTop: "10px",
      marginBottom: "15px",
    },
    beneficiar: {
      marginTop: "4px",
      marginHorizontal: "10%",
      fontSize: "14px",
    },
    table: {
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      paddingTop: 8,
      paddingBottom: 8,
    },
    header2: {
      borderTop: "none",
    },
    bold: {
      fontWeight: 600,
    },
    // So Declarative and unDRY 👌
    row1: {
      width: "27%",
    },
    row2: {
      width: "15%",
    },
    row3: {
      width: "15%",
    },
    row4: {
      width: "20%",
    },
    row5: {
      width: "27%",
    },
    namestep: {
      marginTop: "20px",
      fontWeight: "bold",
      fontSize: "14px",
      marginHorizontal: "10%",
      border: 1,
      padding: "4px",
      borderColor: "#22618F",
      BorderStyle: "solid",
      backgroundColor: "rgba(0, 200, 255, 0.1)",
    },
    noproblem: {
      fontSize: "10px",
      marginHorizontal: "10%",
      marginTop: "2px",
    },
    textstep: {
      fontSize: "10px",
      backgroundColor: "rgba(255, 0, 0, 0.1)",
      marginHorizontal: "10%",
      padding: "4px",
      borderTop: 1,
      borderBottom: 1,
      borderRight: 1,
      borderLeft: 1,
      borderColor: "#22618F",
      BorderStyle: "solid",
    },
    viewdoc: {
      borderTop: 3,
      borderBottom: 3,
      borderRight: 3,
      borderLeft: 3,
      borderColor: "#22618F",
      BorderStyle: "solid",
    },
    image: {
      height: "37px",
      width: "93px",
      position: "absolute",
    },
  });

  const [steps, setSteps] = useState([
    {
      id: "01",
      name: "1. Act de identitate",
      description: "Important CNP si adresa",
      href: "#",
      vals: [1, 2, 3],
      texts: buletin,
      status: "current",
    },
    {
      id: "02",
      name: "2. Certificat de atestare fiscală ANAF",
      description: "Important CUI si CNP",
      href: "#",
      vals: [4, 5, 6],
      texts: anaf,
      status: "upcoming",
    },
    {
      id: "03",
      name: "3. Certificat de atestare fiscală Primărie",
      description: "Important impozit",
      href: "#",
      vals: [7, 8, 9, 10],
      texts: primarie,
      status: "upcoming",
    },
    {
      id: "04",
      name: "4. Extras de carte funciară",
      description: "Important ipoteca",
      href: "#",
      vals: [11, 12, 13, 14, 15, 16, 17],
      texts: cartefunciara,
      status: "upcoming",
    },
  ]);
  // Create Document Component
  const MyDocument = ({ client }) => (
    <Document>
      <Page style={styles.page}>
        <View>
          <Image style={styles.image} src={logo} />
          <Text style={styles.header}>SHOWMINE TECH S.R.L.</Text>
          <Text style={styles.header}>
            TARGOVISTE, STRADA CALAFAT NR. 9, jud. Dambovita
          </Text>
          <Text style={styles.header}>
            CIF:RO29021362 Reg. com.: J15/582/2011
          </Text>
          <Text style={styles.header}>
            OTP BANK ROMANIA – RO44 OTPV 4500 0099 5476 RO01
          </Text>
        </View>
        <Text style={styles.title}>
          Raport de evaluare dosar Casa Verde 2023
        </Text>
        <Text style={styles.beneficiar}>
          Beneficiar:{" "}
          <Text style={styles.bold}>
            {client.NUME} {client.PRENUME}
          </Text>
        </Text>
        <Text style={styles.beneficiar}>
          In urma raportului dosarul a fost considerat{" "}
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
            .map((val) => dosar?.["VAL_" + val] == 1)
            .every((element) => element === true) == true ? (
            <Text style={styles.bold}>ELIGIBIL</Text>
          ) : (
            <Text style={styles.bold}>NEELIGIBIL</Text>
          )}
          .
        </Text>
        <Text style={styles.beneficiar}>
          Mai jos sunt afisate problemele identificate pentru fiecare document:
        </Text>
        {steps.map((step) => {
          return (
            <>
              <View>
                <Text style={styles.namestep}>{step.name}</Text>
                {step.vals
                  .map((val) => dosar?.["VAL_" + val] == 1)
                  .every((element) => element === true) == true ? (
                  <Text style={styles.noproblem}>
                    Nu a fost identificată nici o problemă la acest document.
                  </Text>
                ) : (
                  ""
                )}

                {step.vals.map((val) => {
                  return (
                    <>
                      {step.vals
                        .map((val) => dosar?.["VAL_" + val] == 1)
                        .every((element) => element === true) == false ? (
                        <>
                          {dosar?.["VAL_" + val] == 2 ? (
                            <Text style={styles.textstep}>
                              {
                                step.texts.find((x) => {
                                  return x.id == val;
                                }).name
                              }
                            </Text>
                          ) : (
                            ""
                          )}
                          {/* <Text style={styles.textstep}>
                            {dosar?.["VAL_" + val] == 2
                              ? step.texts.find((x) => {
                                  return x.id == val;
                                }).name
                              : ""}
                          </Text> */}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </View>
            </>
          );
        })}
      </Page>
    </Document>
  );

  const { toPDF, targetRef } = usePDF({
    filename: "usepdf-example.pdf",
    page: { margin: Margin.MEDIUM },
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/getAfmClient.php?id=" +
          id
      )
      .then((response) => {
        setDosar(response.data.client);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {/* <PDFViewer>{document}</PDFViewer> */}
      <Header />
      {loading == true ? (
        <p>Loading....</p>
      ) : (
        <div>
          <Link to={"/clientafm/" + id}>
            <button
              className={
                "m-4 hover:bg-indigo-500 bg-indigo-600  flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
            >
              Inapoi la beneficiar
            </button>
          </Link>
          <div className="my-10">
            <button
              className={
                "my-10 mx-auto hover:bg-indigo-500 bg-indigo-600  flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
            >
              <PDFDownloadLink
                document={<MyDocument client={dosar} />}
                fileName={"raport_de_evaluare_" + dosar.NUME + ".pdf"}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Se încarcă documentul..." : "Descarcă acum!"
                }
              </PDFDownloadLink>
            </button>
          </div>
          <div className="">
            <PDFViewer width="100%" height="500px">
              <MyDocument client={dosar} />
            </PDFViewer>
          </div>
        </div>
      )}
    </>
  );
};

export default GenerateReport;
