import L from "leaflet";
import markerIcon from "./simpleIconSvg.svg";
import markerIconRed from "./svgRedIcon.svg";
import markerIconBlack from "./svgIconBlack.svg";

const iconPerson = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon,
  iconSize: new L.Point(60, 75),
  className: "leafl5 bg-transparent border-0",
});

const iconPersonRed = new L.Icon({
  iconUrl: markerIconRed,
  iconRetinaUrl: markerIconRed,
  iconSize: new L.Point(60, 75),
  className: "leafl5 bg-transparent border-0",
});

const iconPersonBlack = new L.Icon({
  iconUrl: markerIconBlack,
  iconRetinaUrl: markerIconBlack,
  iconSize: new L.Point(60, 75),
  className: "leafl5 bg-transparent border-0",
});

export { iconPerson, iconPersonRed, iconPersonBlack };
