import { useEffect, useState } from "react";
import CalculatorMain from "../components/calculator/CalculatorMain";
import CalculatorSidebar from "../components/calculator/CalculatorSidebar";
import Header from "../Header";
import { getAllPanouri } from "../api/panouri";
import { getAllInvertoare } from "../api/invertoare";
import { getAllAuxiliare } from "../api/auxiliare";
import { getAllBaterii } from "../api/baterii";
import { getAllContoare } from "../api/contoare";

const CalculatorSistem = () => {
  const [selectedPanou, setSelectedPanou] = useState(null);
  const [panouri, setPanouri] = useState([]);
  const [selectedInvertor, setSelectedInvertor] = useState(null);
  const [invertoare, setInvertoare] = useState([]);
  const [baterii, setBaterii] = useState([]);
  const [nrPanouri, setNrPanouri] = useState(1);
  const [auxiliare, setAuxiliare] = useState([]);
  const [tipClient, setTipClient] = useState("afm");
  const [tipBransament, setTipBransament] = useState("monofazat");
  const [cabluDC, setCabluDC] = useState(50);
  const [cabluAC, setCabluAC] = useState(10);
  const [adaugaBaterii, setAdaugaBaterii] = useState(false);
  const [adaugaBackup, setAdaugaBackup] = useState(false);
  const [nrModuleBaterii, setNrModuleBaterii] = useState(1);
  const [selectedBaterie, setSelectedBaterie] = useState(null);
  const [contoare, setContoare] = useState([]);
  const [selectedContor, setSelectedContor] = useState(null);
  const [selectedOptionale, setSelectedOptionale] = useState({
    prb: false,
    impamantare: false,
    structura_zincata: false,
    regim_inaltime: false,
    spor_putere: false,
    trecere_trifazat: false,
    tigla: false,
  });

  useEffect(() => {
    getAllPanouri().then((res) => {
      setPanouri(res);
    });
    getAllInvertoare().then((res) => {
      setInvertoare(res);
    });
    getAllAuxiliare().then((res) => {
      setAuxiliare(res);
    });
    getAllBaterii().then((res) => {
      setBaterii(res);
    });
    getAllContoare().then((res) => {
      setContoare(res);
    });
  }, []);
  return (
    <>
      <Header />
      <div className="flex h-full">
        <div className="w-80 h-full">
          <CalculatorSidebar
            selectedPanou={selectedPanou}
            panouri={panouri}
            invertoare={invertoare}
            selectedInvertor={selectedInvertor}
            numarPanouri={nrPanouri}
            auxiliare={auxiliare}
            tipClient={tipClient}
            tipBransament={tipBransament}
            cabluAC={cabluAC}
            cabluDC={cabluDC}
            baterii={baterii}
            nrModuleBaterii={nrModuleBaterii}
            selectedBaterie={selectedBaterie}
            selectedOptionale={selectedOptionale}
            adaugaBaterii={adaugaBaterii}
            adaugaBackup={adaugaBackup}
            selectedContor={selectedContor}
            contoare={contoare}
          />
        </div>
        <div className="col-span-10 bg-gray-100 flex-1">
          <CalculatorMain
            setSelectedPanou={setSelectedPanou}
            selectedPanou={selectedPanou}
            panouri={panouri}
            invertoare={invertoare}
            selectedInvertor={selectedInvertor}
            setSelectedInvertor={setSelectedInvertor}
            nrPanouri={nrPanouri}
            setNrPanouri={setNrPanouri}
            auxiliare={auxiliare}
            tipClient={tipClient}
            setTipClient={setTipClient}
            tipBransament={tipBransament}
            setTipBransament={setTipBransament}
            cabluAC={cabluAC}
            setCabluAC={setCabluAC}
            cabluDC={cabluDC}
            setCabluDC={setCabluDC}
            adaugaBaterii={adaugaBaterii}
            setAdaugaBaterii={setAdaugaBaterii}
            baterii={baterii}
            nrModuleBaterii={nrModuleBaterii}
            setNrModuleBaterii={setNrModuleBaterii}
            selectedBaterie={selectedBaterie}
            setSelectedBaterie={setSelectedBaterie}
            setSelectedOptionale={setSelectedOptionale}
            selectedOptionale={selectedOptionale}
            adaugaBackup={adaugaBackup}
            setAdaugaBackup={setAdaugaBackup}
            contoare={contoare}
            setContoare={setContoare}
            selectedContor={selectedContor}
            setSelectedContor={setSelectedContor}
          />
        </div>
      </div>
    </>
  );
};

export default CalculatorSistem;
