import axios from "axios";

export const getAllInvertoare = async () => {
  const res = await axios.get(`https://py.iluminatsolar.com/invertoare`, {
    params: {},
  });
  return res.data;
};

export const addNewInvertor = async (newInvertor) => {
  const res = await axios.post(
    `https://py.iluminatsolar.com/adaugare_invertor`,
    {
      nume: newInvertor.nume,
      putere: newInvertor.putere,
      tip: newInvertor.tip,
      stoc_ramas: newInvertor.stoc_ramas,
      pret: newInvertor.pret,
    }
  );
  return res.data;
};

export const updateInvertor = async (id, newInvertor) => {
  const res = await axios.put(`https://py.iluminatsolar.com/update_invertor`, {
    id: id,
    nume: newInvertor.nume,
    putere: newInvertor.putere,
    tip: newInvertor.tip,
    stoc_ramas: newInvertor.stoc_ramas,
    pret: newInvertor.pret,
  });
  return res.data;
};

export const deleteInvertor = async (id) => {
  const res = await axios.delete(`https://py.iluminatsolar.com/delete_invertor`, {
    data: {
      id: id,
    },
  });
  return res.data;
};

// export const updatePretCalc = async (label, value) => {
//   const res = await axios.post(
//     `https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/fotovoltaice-frontend/updatePreturiCalculator.php`,
//     {
//       label: label,
//       value: value,
//     },
//     {
//       headers: {
//         "Content-Type": "application/json", // Specifică tipul de conținut
//       },
//     }
//   );
//   return res.data;
// };

// export const deleteCabinet = async (id) => {
//   const res = await axios.delete(
//     `${process.env.REACT_APP_DEV_HOST}/cabinetes`,
//     {
//       data: {
//         id: id,
//       },
//     }
//   );
//   return res.data;
// };
