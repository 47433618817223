import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditClient = () => {
  const clientObj = {
    ID: "",
    NUME: "",
    PRENUME: "",
    NLC: "",
    COD_CLIENT: "",
    ADRESA_CONSUM: "",
    ADRESA_DOMICILIU: "",
    STARE: "",
  };
  const [client, setClient] = useState(clientObj);
  const { id } = useParams();
  const [seriiPanouri, setSeriiPanouri] = useState([]);
  const [toAddSeriePanou, setToAddSeriePanou] = useState();
  const [listHide, setListHide] = useState(true);

  const notificationMethods = [
    { id: "monofazat", title: "Monofazat", value: "monofazat" },
    { id: "trifazat", title: "Trifazat", value: "trifazat" },
  ];

  useEffect(() => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/getClientFotovoltaice.php?id=" +
          id
      )
      .then((response) => {
        setClient(response.data.client);
        console.log(response);
        console.log(typeof response.data.client.SERII_PANOURI);
        if (response.data.client.SERII_PANOURI) {
          var myArray = response.data.client.SERII_PANOURI.split(",");
          console.log(myArray);
          const guild = JSON.parse(response.data.client.SERII_PANOURI);
          console.log(guild);
          setSeriiPanouri(guild);
        }
      });
  }, []);

  const addSeriePanou = (e) => {
    e.preventDefault();
    var auxArray = seriiPanouri;
    auxArray.push(toAddSeriePanou);
    setSeriiPanouri(auxArray);
    setClient((prevState) => ({
      ...prevState, // shallow copy all previous state
      ["SERII_PANOURI"]: JSON.stringify(auxArray), // update specific key/value
    }));
    console.log(toAddSeriePanou);
    console.log(client);
    console.log(JSON.stringify(auxArray));
    setToAddSeriePanou("");
  };

  const onChangeToAddSeriePanou = (e) => {
    setToAddSeriePanou(e.target.value);
    console.log(e.target.value);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target);
    setClient((prevState) => ({
      ...prevState, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };

  const onChangeStare = (e) => {
    e.preventDefault();
    setClient((prevState) => ({
      ...prevState, // shallow copy all previous state
      ["STARE"]: e.target.value, // update specific key/value
    }));
  };

  const onChangeStareLucrare = (e) => {
    e.preventDefault();
    setClient((prevState) => ({
      ...prevState, // shallow copy all previous state
      ["STARE_LUCRARE"]: e.target.value, // update specific key/value
    }));
  };
  const deleteSeriePanou = (e, index) => {
    var auxArray = seriiPanouri;
    auxArray.splice(index, 1);
    setSeriiPanouri(auxArray);
    setClient((prevState) => ({
      ...prevState, // shallow copy all previous state
      ["SERII_PANOURI"]: JSON.stringify(auxArray), // update specific key/value
    }));
    console.log(toAddSeriePanou);
    console.log(client);
    console.log(JSON.stringify(auxArray));
  };

  const generateDocument = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=document"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/edited.docx?v=" +
          new Date();
        console.log(response);
      });
  };

  const generateDocumentEnel = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=enel"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/documente_enel.docx?v=" +
          new Date();
        console.log(response);
      });
  };

  const generateDocumentAfm = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=documente_afm"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/documente_electrica_afm.docx?v=" +
          new Date();
        console.log(response);
      });
  };
  const generateSchemaCDMPT = () => {
    var schema = "schema_" + client.TIP_SCHEMA;
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=" +
          schema
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/schema_electrica.docx?v=" +
          new Date();
        console.log(response);
      });
  };
  const generateContract = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=contract"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/contract_servicii.docx?v=" +
          new Date();
        console.log(response);
      });
  };

  const generateGarantie = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=garantie"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/garantie_certificat.docx?v=" +
          new Date();
        console.log(response);
      });
  };
  const generateProcesTerminare = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=proces_terminare"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/proces_terminare_lucrare.docx?v=" +
          new Date();
        console.log(response);
      });
  };
  const generateContractStagex = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=stagex"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/stagex_contract_servicii.docx?v=" +
          new Date();
        console.log(response);
      });
  };
  const generateProcesInstalare = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=proces_instalare"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/proces_instalare_lucrare.docx?v=" +
          new Date();
        console.log(response);
      });
  };

  const generateSeriiPanouri = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=serii_panouri"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/borderou_serii_panouri.docx?v=" +
          new Date();
        console.log(response);
      });
  };

  const generateSerieInvertor = () => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/generateDocument.php?id=" +
          id +
          "&document=serie_invertor"
      )
      .then((response) => {
        // setClient(response.data.client);
        window.location.href =
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/borderou_serie_invertor.docx?v=" +
          new Date();
        console.log(response);
      });
  };

  // const onChangeSpecialistInfoCheckbox = (event) => {
  //   var { name, value } = event.target;
  //   setClient((prevState) => ({
  //     ...prevState,
  //     specialistInfo: {
  //       ...prevState.specialistInfo, // shallow copy all previous state
  //       [name]: value, // update specific key/value
  //     },
  //   }));
  //   console.log(client);
  // };

  const onSubmitForm = async () => {
    try {
      const result = await axios
        .post(
          "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/updateClientFotovoltaice.php",
          client
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("da");
            console.log(client);
            toast.success("Setari salvate cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          // setClient(response.data.client);
          console.log(response.status);
        });
    } catch (e) {
      if (e.response.status === 403) {
        toast.error(e.response.data, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
        });
      }
      // console.log(client);
    }
  };
  const showList = (e) => {
    e.preventDefault();
    setListHide(!listHide);
  };

  const sendMailConsum = (e) => {
    e.preventDefault();

    if (client.STARE_LUCRARE != "finalizat_montaj") {
      toast.error("Stare lucrarii trebuie sa fie finalizata!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/updateClientFotovoltaiceLucrare.php",
        client
      )
      .then((response) => {
        if (response.status == 200) {
          toast.success("Mail-ul de consum a fost trimis cu succes!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Eroare la trimiterea mail-ului!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <>
      <Header />
      <div className="container mx-auto mt-10 px-5">
        <div className="grid lg:grid-cols-5 grid-cols-1 gap-5">
          <div class="relative inline-block text-left">
            <div>
              <button
                type="button"
                class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                id="menu-button"
                aria-expanded="true"
                onClick={showList}
                aria-haspopup="true"
              >
                Genereaza document
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div
              class={
                (listHide == true ? "hidden" : "") +
                " absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              }
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateDocument}
                >
                  Document Electrica
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateDocumentAfm}
                >
                  Document Electrica AFM
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateDocumentEnel}
                >
                  Document ENEL
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateContract}
                >
                  Contract servicii TECH
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateContractStagex}
                >
                  Contract servicii STAGEX
                </button>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateSchemaCDMPT}
                >
                  Schema Electrica
                </button>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateSerieInvertor}
                >
                  Serie invertor
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateSeriiPanouri}
                >
                  Serii panouri
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateGarantie}
                >
                  Certificat garantie
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateProcesTerminare}
                >
                  Proces terminare
                </button>
                <button
                  type="button"
                  className=" inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={generateProcesInstalare}
                >
                  Proces instalare
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="text-2xl mt-2">Nr. contract {id}</p>
        <div className="grid lg:grid-cols-5 gap-5 grid-cols-2">
          <div className="w-40 mt-5">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Stare dosar
            </label>
            <select
              id="stare"
              name="STARE"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue="nedepus"
              onChange={onChangeStare}
              value={client.STARE}
            >
              <option value="nedepus">Nedepus</option>
              <option value="depus">Depus</option>
              <option value="finalizat">Finalizat</option>
            </select>
          </div>
          <div className="w-40 mt-5">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Stare lucrare
            </label>
            <select
              id="stare"
              name="STARE_LUCRARE"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              // defaultValue="nedepus"
              onChange={onChangeStareLucrare}
              value={client.STARE_LUCRARE}
            >
              <option value="avans">Avans</option>
              <option value="finalizat_montaj">Finalizat montaj</option>
              <option value="factura_finala">Factura finala</option>
            </select>
          </div>
          <div></div>
          {/* <div></div> */}
          <div>
            <button
              type="button"
              onClick={sendMailConsum}
              className="hidden md:block w-full lg:w-40 float-right ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Mail consum
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={onSubmitForm}
              className=" w-full lg:w-40 float-right ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="container mx-auto my-10 pb-20">
        {client ? (
          <form className="space-y-6">
            {/* <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Profile
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    This information will be displayed publicly so be careful
                    what you share.
                  </p>
                </div>
                <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Website
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          http://
                        </span>
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                          placeholder="www.example.com"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      About
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder="you@example.com"
                        defaultValue={""}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description for your profile. URLs are hyperlinked.
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Photo
                    </label>
                    <div className="mt-1 flex items-center space-x-5">
                      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                      <button
                        type="button"
                        className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Change
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Cover photo
                    </label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Date personale
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Completati datele pentru a putea genera documentele.
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                      <div key={"fizica"} className="flex items-center">
                        <input
                          id={"fizica"}
                          onChange={onChange}
                          name="TIP_CONTRACT"
                          type="radio"
                          value={"fizica"}
                          checked={
                            client.TIP_CONTRACT == "fizica" ? true : false
                          }
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          htmlFor={"fizica"}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {"Persoana Fizica"}
                        </label>
                      </div>
                    </div>
                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                      <div key={"juridica"} className="flex items-center">
                        <input
                          id={"juridica"}
                          onChange={onChange}
                          name="TIP_CONTRACT"
                          type="radio"
                          value={"juridica"}
                          checked={
                            client.TIP_CONTRACT == "juridica" ? true : false
                          }
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          htmlFor={"juridica"}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {"Persoana Juridica"}
                        </label>
                      </div>
                    </div>
                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "juridica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nume Firma
                      </label>
                      <input
                        type="text"
                        name="NUME_FIRMA"
                        id="nume_firma"
                        autoComplete="given-name"
                        value={client.NUME_FIRMA}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "fizica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prenume
                      </label>
                      <input
                        type="text"
                        name="PRENUME"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.PRENUME}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "fizica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nume
                      </label>
                      <input
                        type="text"
                        name="NUME"
                        id="nume"
                        autoComplete="family-name"
                        value={client.NUME}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Contact</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Telefon
                      </label>
                      <input
                        type="text"
                        name="TELEFON"
                        id="email-address"
                        value={client.TELEFON}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Adresa Email
                      </label>
                      <input
                        type="text"
                        name="EMAIL"
                        id="email-address"
                        value={client.EMAIL}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Furnizor</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        NLC
                      </label>
                      <input
                        type="text"
                        name="NLC"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NLC}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        COD CLIENT
                      </label>
                      <input
                        type="text"
                        name="COD_CLIENT"
                        id="nume"
                        autoComplete="family-name"
                        value={client.COD_CLIENT}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        POD
                      </label>
                      <input
                        type="text"
                        name="POD"
                        id="pod"
                        autoComplete="family-name"
                        value={client.POD}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Carte funciara</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Carte funciara
                      </label>
                      <input
                        type="text"
                        name="NR_FUNCIAR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_FUNCIAR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Cadastral
                      </label>
                      <input
                        type="text"
                        name="NR_CADASTRAL"
                        id="nume"
                        autoComplete="family-name"
                        value={client.NR_CADASTRAL}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>
                        {client.TIP_CONTRACT == "fizica"
                          ? "Carte de identitate"
                          : "Identitate firma"}
                      </p>
                    </div>
                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "fizica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        CNP
                      </label>
                      <input
                        type="text"
                        name="CNP"
                        id="email-address"
                        value={client.CNP}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "juridica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        C.U.I.
                      </label>
                      <input
                        type="text"
                        name="CUI"
                        id="email-address"
                        value={client.CUI}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "juridica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Registrul Com.
                      </label>
                      <input
                        type="text"
                        name="NR_REG_COM"
                        id="NR_REG_COM"
                        value={client.NR_REG_COM}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "juridica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Reprezentant
                      </label>
                      <input
                        type="text"
                        name="REPREZENTANT"
                        id="REPREZENTANT"
                        value={client.REPREZENTANT}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div
                      className={
                        "col-span-6 sm:col-span-3 " +
                        (client.TIP_CONTRACT == "juridica" ? "" : "hidden")
                      }
                    >
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Calitate Reprezentant
                      </label>
                      <input
                        type="text"
                        name="CALITATE_REP"
                        id="CALITATE_REP"
                        value={client.CALITATE_REP}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Serie Buletin
                      </label>
                      <input
                        type="text"
                        name="SERIE_BULETIN"
                        id="email-address"
                        value={client.SERIE_BULETIN}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Buletin
                      </label>
                      <input
                        type="text"
                        name="NR_BULETIN"
                        id="email-address"
                        value={client.NR_BULETIN}
                        onChange={onChange}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Adresa domiciliu
                      </label>
                      <input
                        type="text"
                        name="ADRESA_DOMICILIU"
                        id="email-address"
                        onChange={onChange}
                        value={client.ADRESA_DOMICILIU}
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    {/* <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Adresa loc de consum
                      </label>
                      <input
                        type="text"
                        name="ADRESA_CONSUM"
                        id="email-address"
                        autoComplete="email"
                        value={client.ADRESA_CONSUM}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Echipa</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ELECTRICIAN
                      </label>
                      <select
                        id="country"
                        name="ELECTRICIAN1"
                        autoComplete="country-name"
                        // onChange={onChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Patulea Doru</option>
                        {/* <option>Canada</option> */}
                        {/* <option>Mexico</option> */}
                      </select>
                    </div>

                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Adresa domiciliu</p>
                    </div>
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Localitate
                      </label>
                      <input
                        type="text"
                        name="LOCALITATE"
                        id="city"
                        value={client.LOCALITATE}
                        onChange={onChange}
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="region"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Judet
                      </label>
                      <input
                        type="text"
                        name="JUDET"
                        id="region"
                        value={client.JUDET}
                        onChange={onChange}
                        autoComplete="address-level1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cod postal
                      </label>
                      <input
                        type="text"
                        name="COD_POSTAL"
                        id="postal-code"
                        value={client.COD_POSTAL}
                        onChange={onChange}
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="street-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Strada
                      </label>
                      <input
                        type="text"
                        name="STRADA"
                        id="street-address"
                        value={client.STRADA}
                        onChange={onChange}
                        autoComplete="street-address"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr.
                      </label>
                      <input
                        type="text"
                        name="NR_STRADA"
                        id="city"
                        value={client.NR_STRADA}
                        onChange={onChange}
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="region"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Bloc
                      </label>
                      <input
                        type="text"
                        name="BLOC"
                        id="region"
                        value={client.BLOC}
                        onChange={onChange}
                        autoComplete="address-level1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Scara
                      </label>
                      <input
                        type="text"
                        name="SCARA"
                        value={client.SCARA}
                        onChange={onChange}
                        id="postal-code"
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Etaj
                      </label>
                      <input
                        type="text"
                        name="ETAJ"
                        id="postal-code"
                        value={client.ETAJ}
                        onChange={onChange}
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Apartament
                      </label>
                      <input
                        type="text"
                        name="APARTAMENT"
                        id="postal-code"
                        value={client.APARTAMENT}
                        onChange={onChange}
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Adresa loc de consum</p>
                    </div>
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Localitate
                      </label>
                      <input
                        type="text"
                        name="LOCALITATE_CONSUM"
                        id="city"
                        value={client.LOCALITATE_CONSUM}
                        onChange={onChange}
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="region"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Judet
                      </label>
                      <input
                        type="text"
                        name="JUDET_CONSUM"
                        id="region"
                        value={client.JUDET_CONSUM}
                        onChange={onChange}
                        autoComplete="address-level1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cod postal
                      </label>
                      <input
                        type="text"
                        name="COD_POSTAL_CONSUM"
                        id="postal-code"
                        value={client.COD_POSTAL_CONSUM}
                        onChange={onChange}
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="street-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Strada
                      </label>
                      <input
                        type="text"
                        name="STRADA_CONSUM"
                        id="street-address"
                        value={client.STRADA_CONSUM}
                        onChange={onChange}
                        autoComplete="street-address"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr.
                      </label>
                      <input
                        type="text"
                        name="NR_STRADA_CONSUM"
                        id="city"
                        value={client.NR_STRADA_CONSUM}
                        onChange={onChange}
                        autoComplete="address-level2"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="region"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Bloc
                      </label>
                      <input
                        type="text"
                        name="BLOC_CONSUM"
                        id="region"
                        value={client.BLOC_CONSUM}
                        onChange={onChange}
                        autoComplete="address-level1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Scara
                      </label>
                      <input
                        type="text"
                        name="SCARA_CONSUM"
                        value={client.SCARA_CONSUM}
                        onChange={onChange}
                        id="postal-code"
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Etaj
                      </label>
                      <input
                        type="text"
                        name="ETAJ_CONSUM"
                        id="postal-code"
                        value={client.ETAJ_CONSUM}
                        onChange={onChange}
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Apartament
                      </label>
                      <input
                        type="text"
                        name="APARTAMENT_CONSUM"
                        id="postal-code"
                        value={client.APARTAMENT_CONSUM}
                        onChange={onChange}
                        autoComplete="postal-code"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Date tehnice
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Completati datele tehnice pentru a putea genera documentele
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numar panouri fotovoltaice
                      </label>
                      <input
                        type="text"
                        name="NR_PANOURI"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_PANOURI}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Puterea unui panou fotovoltaic
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="PUTERE_PANOU"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          placeholder="0.00"
                          value={client.PUTERE_PANOU}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            Wp
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nume invertor
                      </label>
                      <input
                        type="text"
                        name="NUME_INVERTOR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NUME_INVERTOR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Putere invertor
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="PUTERE_INVERTOR"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          placeholder="0.00"
                          value={client.PUTERE_INVERTOR}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            W
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nume invertor
                      </label>
                      <input
                        type="text"
                        name="NUME_INVERTOR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NUME_INVERTOR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numar invertoare
                      </label>
                      <input
                        type="text"
                        name="NR_INVERTOARE"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_INVERTOARE}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                      {notificationMethods.map((notificationMethod) => (
                        <div
                          key={notificationMethod.id}
                          className="flex items-center"
                        >
                          <input
                            id={notificationMethod.id}
                            onChange={onChange}
                            name="TIP_INVERTOR"
                            type="radio"
                            value={notificationMethod.value}
                            defaultChecked={
                              notificationMethod.id === "monofazat"
                            }
                            checked={
                              client.TIP_INVERTOR == notificationMethod.value
                                ? true
                                : false
                            }
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={notificationMethod.id}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {notificationMethod.title}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Valoare contract
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="VALOARE_CONTRACT"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          placeholder="0.00"
                          value={client.VALOARE_CONTRACT}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            lei TVA inclus
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Priza de pamant</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Valoare masurata
                      </label>
                      <input
                        type="text"
                        name="VALOARE_MASURATA"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.VALOARE_MASURATA}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Valoare calculata
                      </label>
                      <input
                        type="text"
                        name="VALOARE_CALCULATA"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.VALOARE_CALCULATA}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Carte funciara
                      </label>
                      <input
                        type="text"
                        name="NR_FUNCIAR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_FUNCIAR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Cadastral
                      </label>
                      <input
                        type="text"
                        name="NR_CADASTRAL"
                        id="nume"
                        autoComplete="family-name"
                        value={client.NR_CADASTRAL}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    {/* <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        name="EMAIL"
                        id="email-address"
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    AFM
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Completati datele tehnice pentru a putea genera documentele
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Invertor</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Producator Invertor
                      </label>
                      <input
                        type="text"
                        name="PRODUCATOR_INVERTOR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.PRODUCATOR_INVERTOR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Serie Invertor
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="SERIE_INVERTOR"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          placeholder="0.00"
                          value={client.SERIE_INVERTOR}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Model invertor
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="MODEL_INVERTOR"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          // placeholder="0.00"
                          value={client.MODEL_INVERTOR}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Smartmeter</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nume Smartmeter
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="NUME_SMARTMETER"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          // placeholder="0.00"
                          value={client.NUME_SMARTMETER}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Model Smartmeter
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="MODEL_SMARTMETER"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          // placeholder="0.00"
                          value={client.MODEL_SMARTMETER}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Serie Smartmeter
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="SERIE_SMARTMETER"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          // placeholder="0.00"
                          value={client.SERIE_SMARTMETER}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Dosar AFM</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numar Dosar AFM
                      </label>
                      <input
                        type="text"
                        name="NR_AFM"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_AFM}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Data contract AFM
                      </label>
                      <input
                        type="text"
                        name="DATA_AFM"
                        id="prenume"
                        autoComplete="DATA_AFM"
                        value={client.DATA_AFM}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Contract de participare</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numar contract de participare
                      </label>
                      <input
                        type="text"
                        name="NR_CP"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_CP}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Data contract de participare
                      </label>
                      <input
                        type="text"
                        name="DATA_CP"
                        id="prenume"
                        autoComplete="DATA_CP"
                        value={client.DATA_CP}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Factura finala</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Serie factura finala
                      </label>
                      <input
                        type="text"
                        name="SERIE_FACTURA_FINALA"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.SERIE_FACTURA_FINALA}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Data factura finala
                      </label>
                      <input
                        type="text"
                        name="DATA_FACTURA_FINALA"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.DATA_FACTURA_FINALA}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Aviz tehnic de racordare</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ATR
                      </label>
                      <input
                        type="text"
                        name="ATR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.ATR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Data ATR
                      </label>
                      <input
                        type="text"
                        name="DATA_ATR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.DATA_ATR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 border-b mt-4 font-bold">
                      <p>Altele</p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Mod alimentare actual
                      </label>
                      <input
                        type="text"
                        name="MOD_ALIMENTARE"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.MOD_ALIMENTARE}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Data estimata finala
                      </label>
                      <input
                        type="text"
                        name="DATA_ESTIMARE"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.DATA_ESTIMARE}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Numar invertoare
                      </label>
                      <input
                        type="text"
                        name="NR_INVERTOARE"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_INVERTOARE}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Carte funciara
                      </label>
                      <input
                        type="text"
                        name="NR_FUNCIAR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_FUNCIAR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Cadastral
                      </label>
                      <input
                        type="text"
                        name="NR_CADASTRAL"
                        id="nume"
                        autoComplete="family-name"
                        value={client.NR_CADASTRAL}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    {/* <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        name="EMAIL"
                        id="email-address"
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Serii panouri fotovoltaice
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Completati seriile pentru panourile fotovoltaice
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Producator panouri
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="PRODUCATOR_PANOU"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          // placeholder="0.00"
                          value={client.PRODUCATOR_PANOU}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Model panouri
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="MODEL_PANOU"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          // placeholder="0.00"
                          value={client.MODEL_PANOU}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tip panouri
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="TIP_PANOU"
                          id="price"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          // placeholder="0.00"
                          value={client.TIP_PANOU}
                          onChange={onChange}
                          aria-describedby="price-currency"
                        />
                      </div>
                    </div>
                    <div className="gap-5 col-span-4 font-raleway">
                      {!seriiPanouri
                        ? ""
                        : seriiPanouri.map((spec, index) => (
                            <div className="text-black text-xs border-b flex justify-between mt-2">
                              <div>
                                <p className="text-lg font-bold text-black">
                                  {spec}
                                </p>
                                {/* <p className="text-base">{spec.name}</p> */}
                              </div>
                              <p
                                value={index}
                                onClick={(e) => deleteSeriePanou(e, index)}
                                className="text-lg cursor-pointer"
                              >
                                x
                              </p>
                            </div>
                          ))}
                      <div className={"mt-5"}>
                        <input
                          onChange={onChangeToAddSeriePanou}
                          placeholder={"Serie panou"}
                          value={toAddSeriePanou}
                          // ref={trSchool}
                          type="text"
                          name="addTrainingSchool"
                          id="addSpec"
                          className={
                            "mt-2 border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          }
                        />
                      </div>
                      <button
                        type="button"
                        className="mr-8 mt-5 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={addSeriePanou}
                      >
                        Adauga serie
                      </button>
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Carte funciara
                      </label>
                      <input
                        type="text"
                        name="NR_FUNCIAR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_FUNCIAR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Cadastral
                      </label>
                      <input
                        type="text"
                        name="NR_CADASTRAL"
                        id="nume"
                        autoComplete="family-name"
                        value={client.NR_CADASTRAL}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    {/* <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        name="EMAIL"
                        id="email-address"
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}
                  </div>
                  <div className="wrap text-wrap break-words mt-4">
                    <p className="text-wrap break-words">
                      {seriiPanouri.join("|")}
                    </p>
                  </div>
                  <button
                    type="button"
                    className="mr-8 mt-5 mb-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      navigator.clipboard.writeText(seriiPanouri.join("|"));
                      toast.success("Serii panouri copiate in clipboard");
                    }}
                  >
                    Copiaza serii
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Schema Electrica
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Completati datele pentru generarea schemei electrice
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Valoare amperi
                      </label>
                      <input
                        type="text"
                        name="VT"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.VT}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                        <div key={"cdmpt"} className="flex items-center">
                          <input
                            id={"cdmpt"}
                            onChange={onChange}
                            name="TIP_SCHEMA"
                            type="radio"
                            value={"cdmpt"}
                            checked={
                              client.TIP_SCHEMA == "cdmpt" ? true : false
                            }
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={"cdmpt"}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {"CDMPT"}
                          </label>
                        </div>
                      </div>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                        <div key={"bmpt"} className="flex items-center">
                          <input
                            id={"bmpt"}
                            onChange={onChange}
                            name="TIP_SCHEMA"
                            type="radio"
                            value={"bmpt"}
                            checked={client.TIP_SCHEMA == "bmpt" ? true : false}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={"bmpt"}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {"BMPT"}
                          </label>
                        </div>
                      </div>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                        <div key={"bmpt_fs"} className="flex items-center">
                          <input
                            id={"bmpt_fs"}
                            onChange={onChange}
                            name="TIP_SCHEMA"
                            type="radio"
                            value={"bmpt_fs"}
                            checked={
                              client.TIP_SCHEMA == "bmpt_fs" ? true : false
                            }
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={"bmpt_fs"}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {"BMPT fara separator"}
                          </label>
                        </div>
                      </div>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                        <div key={"bmpm"} className="flex items-center">
                          <input
                            id={"bmpm"}
                            onChange={onChange}
                            name="TIP_SCHEMA"
                            type="radio"
                            value={"bmpm"}
                            checked={client.TIP_SCHEMA == "bmpm" ? true : false}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={"bmpm"}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {"BMPM"}
                          </label>
                        </div>
                      </div>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 col-span-6 sm:col-span-3">
                        <div key={"bmpm_fs"} className="flex items-center">
                          <input
                            id={"bmpm"}
                            onChange={onChange}
                            name="TIP_SCHEMA"
                            type="radio"
                            value={"bmpm_fs"}
                            checked={
                              client.TIP_SCHEMA == "bmpm_fs" ? true : false
                            }
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor={"bmpm_fs"}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {"BMPM fara separator"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Carte funciara
                      </label>
                      <input
                        type="text"
                        name="NR_FUNCIAR"
                        id="prenume"
                        autoComplete="given-name"
                        value={client.NR_FUNCIAR}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nr. Cadastral
                      </label>
                      <input
                        type="text"
                        name="NR_CADASTRAL"
                        id="nume"
                        autoComplete="family-name"
                        value={client.NR_CADASTRAL}
                        onChange={onChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}

                    {/* <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        name="EMAIL"
                        id="email-address"
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Notifications
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Decide which communications you'd like to receive and how.
                  </p>
                </div>
                <div className="mt-5 space-y-6 md:mt-0 md:col-span-2">
                  <fieldset>
                    <legend className="sr-only">By Email</legend>
                    <div
                      className="text-base font-medium text-gray-900"
                      aria-hidden="true"
                    >
                      By Email
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-start">
                        <div className="h-5 flex items-center">
                          <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Comments
                          </label>
                          <p className="text-gray-500">
                            Get notified when someones posts a comment on a
                            posting.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="candidates"
                            name="candidates"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="font-medium text-gray-700"
                          >
                            Candidates
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate applies for a job.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="offers"
                            name="offers"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="offers"
                            className="font-medium text-gray-700"
                          >
                            Offers
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate accepts or rejects an
                            offer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="contents text-base font-medium text-gray-900">
                      Push Notifications
                    </legend>
                    <p className="text-sm text-gray-500">
                      These are delivered via SMS to your mobile phone.
                    </p>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="push-everything"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          htmlFor="push-everything"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Everything
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-email"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          htmlFor="push-email"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Same as email
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-nothing"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label
                          htmlFor="push-nothing"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          No push notifications
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div> */}

            <div className="flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={onSubmitForm}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default EditClient;
