import { useEffect, useState } from "react";
import moment from "moment";
import Loading from "./general/Loading";
import { getAllPanouri } from "../api/panouri";
import AddNewPanou from "./administrarecalculator/AddNewPanou";
import PanouSidebar from "./administrarecalculator/PanouSidebar";
import DeletePanouModal from "./administrarecalculator/DeletePanouModal";
import { getAllInvertoare } from "../api/invertoare";
import AddNewInvertor from "./administrarecalculator/AddNewInvertor";
import InvertorSidebar from "./administrarecalculator/InvertorSidebar";

const AdministrareInvertoare = () => {
  const [loading, setLoading] = useState(true);
  const [nrSlice, setNrSlice] = useState(5);
  const [invertoare, setInvertoare] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedInvertor, setSelectedInvertor] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const fetchData = () => {
    setLoading(true);
    getAllInvertoare().then((res) => {
      setInvertoare(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <AddNewInvertor
        open={openAdd}
        setOpen={setOpenAdd}
        fetchData={fetchData}
      />
      <InvertorSidebar
        open={openEdit}
        setOpen={setOpenEdit}
        fetchData={fetchData}
        selectedInvertor={selectedInvertor}
      />
      <DeletePanouModal
        open={openDelete}
        setOpen={setOpenDelete}
        selectedPanou={selectedInvertor}
        fetchData={fetchData}
      />
      <div className="mt-10">
        <div className="overflow-hidden rounded-lg bg-gray-50 shadow-md">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Invertoare
              </h2>
              <div>
                <button
                  type="button"
                  onClick={() => setOpenAdd(true)}
                  className="block items-center flex rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-officialPrimary"
                >
                  Adaugă invertor
                </button>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div className="mt-6 flow-root border-t border-gray-200">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            Id
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Producator
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Model
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Putere
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Tip
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Stoc
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Pret
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                          >
                            <span className="sr-only">Actiuni</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {invertoare?.slice(0, nrSlice).map((invertor) => (
                          <tr
                            key={"invertor-" + invertor.id}
                            className={"even:bg-gray-50"}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                              {invertor.id}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {invertor.producator}
                            </td>
                            <td className="whitespace-nowrap font-semibold px-3 py-4 text-sm text-gray-500">
                              {invertor.model}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {invertor.putere} W
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {invertor.tip}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 max-w-sm whitespace-normal">
                              {invertor.stoc_ramas}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 max-w-sm whitespace-normal">
                              {invertor.pret}
                            </td>

                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                              <div className="flex gap-5 justify-end">
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setSelectedInvertor(invertor);
                                    setOpenEdit(true);
                                  }}
                                  className="block rounded-md bg-officialPrimary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-officialSecondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-officialPrimary"
                                >
                                  Modifica
                                </button>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setSelectedInvertor(invertor);
                                    setOpenDelete(true);
                                  }}
                                  className="block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-officialPrimary"
                                >
                                  Sterge
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {invertoare.length < 6 ? (
                  ""
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setNrSlice(-1);
                      }}
                      className={
                        (nrSlice == -1 ? "hidden" : "") +
                        " mt-4 mx-auto block rounded-md bg-officialPrimary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-officialSecondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-officialPrimary"
                      }
                    >
                      Vezi mai multe panouri
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setNrSlice(5);
                      }}
                      className={
                        (nrSlice != -1 ? "hidden" : "") +
                        " mt-4 mx-auto block rounded-md bg-officialPrimary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-officialSecondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-officialPrimary"
                      }
                    >
                      Vezi mai putine panouri
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdministrareInvertoare;
