import axios from "axios";

// export const getAllCabinetes = async () => {
//   const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/cabinetes`, {
//     params: {},
//   });
//   return res.data;
// };

// export const addNewCabinet = async (newCabinet) => {
//   const res = await axios.post(`${process.env.REACT_APP_DEV_HOST}/cabinetes`, {
//     name: newCabinet.name,
//     sectie_id: newCabinet.sectie_id,
//   });
//   return res.data;
// };

export const updatePretCalc = async (label, value) => {
  const res = await axios.post(
    `https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/fotovoltaice-frontend/updatePreturiCalculator.php`,
    {
      label: label,
      value: value,
    },
    {
      headers: {
        "Content-Type": "application/json", // Specifică tipul de conținut
      },
    }
  );
  return res.data;
};

// export const deleteCabinet = async (id) => {
//   const res = await axios.delete(
//     `${process.env.REACT_APP_DEV_HOST}/cabinetes`,
//     {
//       data: {
//         id: id,
//       },
//     }
//   );
//   return res.data;
// };
