/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
// import { createUser, getUser } from "../api/user.js";
import { LoadingContext } from "./LoadingProvider";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState({
    email: "test1@test.com",
  });

  const [loading, setLoading] = useContext(LoadingContext);
  useEffect(() => {
    auth.auth().onAuthStateChanged((userData) => {
      setUser(userData);

      setLoading(false);
      // if (userData) {
      //   auth
      //     .auth()
      //     .currentUser.getIdToken(/* forceRefresh */ true)
      //     .then(async function (idToken) {
      //       console.log("dada");
      //       await getUser(idToken, userData).then((response) => {
      //         if (response.status == 200) {
      //           setUser({ ...userData, user: response.data.user });
      //           console.log(response.data.user);
      //           setLoading(false);
      //         }
      //         setLoading(false);
      //       });
      //     })
      //     .catch(function (error) {
      //       console.log(error);
      //     });
      // } else {
      //   setLoading(false);
      // }
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
