import { useEffect, useState } from "react";
import { getAllPanouri } from "../../api/panouri";

const CalculatorMain = ({
  panouri,
  selectedPanou,
  setSelectedPanou,
  setSelectedInvertor,
  selectedInvertor,
  invertoare,
  nrPanouri,
  setNrPanouri,
  auxiliare,
  tipClient,
  setTipClient,
  tipBransament,
  setTipBransament,
  cabluDC,
  setCabluDC,
  cabluAC,
  setCabluAC,
  adaugaBaterii,
  setAdaugaBaterii,
  baterii,
  nrModuleBaterii,
  setNrModuleBaterii,
  selectedBaterie,
  setSelectedBaterie,
  setSelectedOptionale,
  selectedOptionale,
  adaugaBackup,
  setAdaugaBackup,
  contoare,
  setContoare,
  selectedContor,
  setSelectedContor,
}) => {
  const bife = [
    "prb",
    "impamantare",
    "structura_zincata",
    "regim_inaltime",
    "spor_putere",
    "trecere_trifazat",
    "tigla",
  ];

  return (
    <div className="grid grid-cols-2 px-10">
      <div>
        <section className="m-7 p-7 bg-white grid grid-cols-2 gap-5 rounded-lg  shadow-lg ring-1 ring-black ring-opacity-5">
          <div>
            <label
              htmlFor="tipClient"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Tip client
            </label>
            <div className="mt-2">
              <select
                onChange={(e) => setTipClient(e.target.value)}
                type="text"
                value={tipClient}
                name="tipClient"
                id="tipClient"
                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
              >
                <option hidden>Alege tipul clientului</option>
                <option value="afm">AFM</option>
                <option value="normal">Normal</option>
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="tipBransament"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Tip bransament
            </label>
            <div className="mt-2">
              <select
                onChange={(e) => {
                  setTipBransament(e.target.value);
                  setSelectedInvertor(null);
                  setSelectedBaterie(null);
                }}
                type="text"
                value={tipBransament}
                name="tipBransament"
                id="nutipBransamentme"
                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
              >
                <option hidden>Alege tipul bransamentului</option>
                <option value="monofazat">Monofazat</option>
                <option value="trifazat">Trifazat</option>
              </select>
            </div>
          </div>
        </section>
        <section className="m-7 p-7 bg-white grid grid-cols-2 gap-5 rounded-lg  shadow-lg ring-1 ring-black ring-opacity-5">
          {/* <div className="text-sm">
          <h3 className="my-2.5 leading-7 text-base text-black opacity-85 font-medium ">
            Tipul clientului
          </h3>
          <div className="flex items-center justify-between border border-gray-200 rounded-md w-fit">
            <button
              type="button"
              className={
                false === false
                  ? "bg-officialPrimary text-white px-2 py-1.5 rounded-l-md"
                  : "bg-white px-2 py-1.5 text-gray-600 rounded-l-md"
              }
              // onClick={() => {
              //   setShowDeleted(false);
              //   // fetchData();
              // }}
            >
              <span>Activ</span>
            </button>
            <button
              type="button"
              className={
                false === true
                  ? "bg-officialPrimary text-white px-2 py-1.5 rounded-r-md"
                  : "bg-white px-2 py-1.5 text-gray-600 rounded-r-md"
              }
              // onClick={() => {
              //   setShowDeleted(true);
              //   // fetchData();
              // }}
            >
              <span>Arhivat</span>
            </button>
          </div>
        </div> */}
          <div>
            <label
              htmlFor="panouriFoto"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Panouri fotovoltaice
            </label>
            <div className="">
              <select
                value={selectedPanou}
                id={"panouriFoto"}
                onChange={(e) => setSelectedPanou(e.target.value)}
                className="mt-2 block w-fit rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-officialPrimary sm:text-sm sm:leading-6"
              >
                <option hidden key={"panou-" + 0}>
                  Alege panoul
                </option>
                {panouri.map((panou) => {
                  return (
                    <option value={panou.id} key={"panou-" + panou.id}>
                      {panou.nume}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="nrPanouri"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Numar panouri
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setNrPanouri(e.target.value)}
                type="text"
                value={nrPanouri}
                name="nrPanouri"
                id="nrPanouri"
                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div>
              <label
                htmlFor="invertorSelect"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Invertor
              </label>
              <div className="">
                <select
                  value={selectedInvertor}
                  id={"invertorSelect"}
                  onChange={(e) => {
                    setSelectedBaterie(null);
                    setSelectedInvertor(e.target.value);
                  }}
                  className="mt-2 block w-fit rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-officialPrimary sm:text-sm sm:leading-6"
                >
                  <option hidden key={"invertor-0"}>
                    Alege invertor
                  </option>
                  {invertoare
                    .filter((el) => {
                      return el.tip === tipBransament;
                    })
                    .map((invertor) => {
                      return (
                        <option
                          value={invertor.id}
                          key={"invertor-" + invertor.id}
                        >
                          {invertor.producator} - {invertor.model} -{" "}
                          {invertor.putere / 1000}kW
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <div>
              <label
                htmlFor="smartMeterSelect"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Smartmeter
              </label>
              <div className="">
                <select
                  value={selectedContor}
                  id={"smartMeterSelect"}
                  onChange={(e) => {
                    setSelectedContor(e.target.value);
                  }}
                  className="mt-2 block w-fit rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-officialPrimary sm:text-sm sm:leading-6"
                >
                  <option hidden key={"smartmeter-0"}>
                    Alege smartmeter
                  </option>
                  {contoare.map((invertor) => {
                    return (
                      <option
                        value={invertor.id}
                        key={"smartmeter-" + invertor.id}
                      >
                        {invertor.nume}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div>
            <div>
              <label
                htmlFor="stocareCheck"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Stocare
              </label>
              <div className="relative flex items-start border py-2 px-4 w-fit rounded-md">
                <div className="flex items-center h-5">
                  <input
                    id={"stocareCheck"}
                    name="stocareCheck"
                    type="checkbox"
                    onChange={(e) => {
                      setAdaugaBaterii(e.target.checked);
                    }}
                    checked={adaugaBaterii === true}
                    className="focus:ring-officialPrimary h-4 w-4 text-officialPrimary border-gray-300 rounded cursor-pointer"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="stocareCheck"
                    className="font-medium text-gray-900 cursor-pointer"
                  >
                    Adaugă baterii
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
        {adaugaBaterii === true ? (
          <section className="m-7 p-7 bg-white grid grid-cols-2 gap-5 rounded-lg  shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="flex gap-5">
              <div>
                <label
                  htmlFor="selectBaterie"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Baterie
                </label>
                <div className="">
                  <select
                    value={selectedBaterie}
                    id={"selectBaterie"}
                    onChange={(e) => {
                      setSelectedBaterie(e.target.value);
                      if (e.target.value == 2) {
                        setNrModuleBaterii(4);
                      } else setNrModuleBaterii(1);
                    }}
                    className="mt-2 block w-fit rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-officialPrimary sm:text-sm sm:leading-6"
                  >
                    <option hidden value={null} key={"baterie-02"}>
                      Alege baterie
                    </option>
                    {baterii
                      .filter((el) => {
                        return el.tip === "baterie";
                      })
                      .filter((el) => {
                        if (el.id === 1 || el.id === 2) {
                          return (
                            invertoare.find((el) => el.id == selectedInvertor)
                              ?.producator === "FRONIUS"
                          );
                        } else if (el.id === 3) {
                          return (
                            invertoare.find((el) => el.id == selectedInvertor)
                              ?.producator === "HUAWEI"
                          );
                        }
                      })
                      .map((invertor) => {
                        return (
                          <option
                            value={invertor.id}
                            key={"baterie-" + invertor.id}
                          >
                            {invertor.model}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="nrModule"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Numar module
              </label>
              <div class="flex flex-row h-10 w-2/4 rounded-lg relative bg-transparent mt-1">
                <button
                  type="buton"
                  onClick={(e) => {
                    e.preventDefault();
                    if (nrModuleBaterii > 1) {
                      if (selectedBaterie == 2) {
                        if (nrModuleBaterii > 4) {
                          setNrModuleBaterii(parseInt(nrModuleBaterii) - 1);
                        }
                      } else setNrModuleBaterii(parseInt(nrModuleBaterii) - 1);
                    }
                  }}
                  class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                >
                  <span class="m-auto text-2xl font-thin">−</span>
                </button>
                <input
                  type="number"
                  class="text-center w-full bg-gray-50 font-semibold text-md hover:text-black focus:text-black  flex items-center text-gray-700"
                  name="custom-input-number"
                  disabled
                  // onChange={(e) => {
                  //   if (/^\d+$/.test(e.target.value) === true) {
                  //     setNrModuleBaterii(parseInt(e.target.value));
                  //   }
                  // }}
                  value={nrModuleBaterii}
                ></input>
                <button
                  type="buton"
                  onClick={(e) => {
                    e.preventDefault();
                    if (nrModuleBaterii != 0) {
                      console.log(
                        invertoare.find((el) => el.id == selectedInvertor)
                          ?.model
                      );
                      if (
                        invertoare.find((el) => el.id == selectedInvertor)
                          ?.model === "PRIMO GEN24 PLUS" &&
                        selectedBaterie == 1
                      ) {
                        if (nrModuleBaterii < 3) {
                          setNrModuleBaterii(parseInt(nrModuleBaterii) + 1);
                        }
                      }
                      if (
                        invertoare.find((el) => el.id == selectedInvertor)
                          ?.model === "SYMO GEN24 PLUS" &&
                        selectedBaterie == 1
                      ) {
                        if (nrModuleBaterii < 4) {
                          setNrModuleBaterii(parseInt(nrModuleBaterii) + 1);
                        }
                      }
                      if (
                        invertoare.find((el) => el.id == selectedInvertor)
                          ?.model === "PRIMO GEN24 PLUS" &&
                        selectedBaterie == 2
                      ) {
                        if (nrModuleBaterii < 7) {
                          setNrModuleBaterii(parseInt(nrModuleBaterii) + 1);
                        }
                      }
                      if (
                        invertoare.find((el) => el.id == selectedInvertor)
                          ?.model === "SYMO GEN24 PLUS" &&
                        selectedBaterie == 2
                      ) {
                        if (nrModuleBaterii < 8) {
                          setNrModuleBaterii(parseInt(nrModuleBaterii) + 1);
                        }
                      }
                      if (
                        invertoare.find((el) => el.id == selectedInvertor)
                          ?.producator === "HUAWEI" &&
                        selectedBaterie == 3
                      ) {
                        if (nrModuleBaterii < 3) {
                          setNrModuleBaterii(parseInt(nrModuleBaterii) + 1);
                        }
                      }
                    }
                  }}
                  class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                >
                  <span class="m-auto text-2xl font-thin">+</span>
                </button>
              </div>
            </div>
            <div>
              <div>
                <label
                  htmlFor="backup"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Backup
                </label>
                <div className="relative flex items-start border py-2 px-4 w-fit rounded-md">
                  <div className="flex items-center h-5">
                    <input
                      id={"backup"}
                      name="backup"
                      type="checkbox"
                      onChange={(e) => {
                        setAdaugaBackup(e.target.checked);
                      }}
                      checked={adaugaBackup === true}
                      className="focus:ring-officialPrimary h-4 w-4 text-officialPrimary border-gray-300 rounded cursor-pointer"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="backup"
                      className="font-medium text-gray-900 cursor-pointer"
                    >
                      Adaugă backup box
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <section className="m-7 p-7 bg-white grid grid-cols-2 gap-5 rounded-lg  shadow-lg ring-1 ring-black ring-opacity-5">
          <div>
            <label
              htmlFor="cabluAC"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Cablu AC
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setCabluAC(e.target.value)}
                type="text"
                value={cabluAC}
                name="cabluAC"
                id="cabluAC"
                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="cabluDC"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Cablu DC
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setCabluDC(e.target.value)}
                type="text"
                value={cabluDC}
                name="cabluDC"
                id="cabluDC"
                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </section>
      </div>
      <div>
        <section className="m-7 p-7 bg-white rounded-lg  shadow-lg ring-1 ring-black ring-opacity-5">
          <fieldset>
            <legend className="text-base font-semibold leading-6 text-gray-900">
              Optionale
            </legend>
            <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
              {auxiliare
                .filter((el) => {
                  return bife.includes(el.eticheta);
                })
                .map((aux, index) => (
                  <div key={index} className="relative flex items-start py-4">
                    <div className="min-w-0 flex-1 text-sm leading-6">
                      <label
                        htmlFor={`person-${aux.id}`}
                        className="select-none font-medium text-gray-900"
                      >
                        {aux.nume}
                      </label>
                    </div>
                    <div className="ml-3 flex h-6 items-center">
                      <input
                        id={`person-${aux.id}`}
                        name={`person-${aux.id}`}
                        type="checkbox"
                        checked={selectedOptionale[aux.eticheta] === true}
                        onChange={(e) => {
                          setSelectedOptionale({
                            ...selectedOptionale,
                            [aux.eticheta]: e.target.checked,
                          });
                        }}
                        className="h-6 w-6 rounded border-gray-600 text-officialPrimary focus:ring-officialPrimary"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </fieldset>
        </section>
      </div>
    </div>
  );
};

export default CalculatorMain;
