import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./Dashboard";
import { Routes, Route } from "react-router-dom";
import EditClient from "./EditClient";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRouteLogged, ProtectedRoute } from "./utils/ProtectedRoute";
import Login from "./Login";
import Afm from "./Afm";
import AfmList from "./AfmList";
import TestCalendar from "./TestCalendar";
import GenerateReport from "./GenerateReport";
import Harta from "./Harta";
import AdministrareCalculator from "./pages/AdministrareCalculator";
import CalculatorSistem from "./pages/CalculatorSistem";
function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path="/test" element={<TestCalendar />} />
      <Route
        path="/login"
        element={
          <ProtectedRouteLogged>
            <Login />
          </ProtectedRouteLogged>
        }
      />
      <Route path="/editclient/:id" element={<EditClient />} />
      <Route path="/afm" element={<AfmList />} />
      <Route path="/clientafm/:id" element={<Afm />} />
      <Route path="/afm/report/:id" element={<GenerateReport />} />
      <Route
        path="/administrarecalculator"
        element={<AdministrareCalculator />}
      />
      <Route path="/calculator" element={<CalculatorSistem />} />
      {/* <Route path="/login" element={<Login />} /> */}
      <Route path="/harta" element={<Harta />} />
    </Routes>
  );
}

export default App;
