/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import axios from "axios";
import Header from "./Header";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Example = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clients, setClients] = useState();
  const [stareDosarClienti, setStareDosarClienti] = useState("Toate");
  const [filterAdministrator, setFilterAdministrator] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/testcon2.php"
      )
      .then((response) => setClients(response.data));
  }, []);

  const addUser = (e) => {
    e.preventDefault();
    const client = { nume: "MIHAILA ADELIN" };
    axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/addClientFotovoltaice.php",
        client
      )
      .then((response) => {
        axios
          .get(
            "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/testcon2.php"
          )
          .then((response2) => {
            if (response2.status == 200) {
              console.log("da");
              toast.success("Client adaugat cu succes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            setClients(response2.data);
          });
        console.log(response);
      });
  };

  const onChangeStareDosar = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setStareDosarClienti(e.target.value);
  };
  const onChangeNumeAdministrator = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    console.log(filterAdministrator);
    setFilterAdministrator(e.target.value);
  };

  const onChangeStareLucrare = (e, person) => {
    e.preventDefault();
    var { name, value } = e.target;
    var stare_luc = "";
    console.log("test1");
    if (e.target.checked) {
      value = true;
      stare_luc = "finalizat_montaj";
    } else {
      value = false;
      stare_luc = "avans";
    }
    const client = {
      ID: person.ID,
      STARE_LUCRARE: stare_luc,
    };
    var client2 = person;
    client2.STARE_LUCRARE = stare_luc;
    console.log(client2);
    axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/updateClientFotovoltaiceLucrare.php",
        client2
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("da");
          toast.success("Starea lucrari a fost modificata!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          e.target.checked = !e.target.checked;
          console.log(clients);
        }
        // setClient(response.data.client);
        console.log(response.status);
      });
    // console.log(client);
    // setClient((prevState) => ({
    //   ...prevState, // shallow copy all previous state
    //   ["STARE_LUCRARE"]: e.target.value, // update specific key/value
    // }));
  };

  return (
    <>
      <Header />

      <div className="container mx-auto my-10 pb-10">
        {/* Inceput tabela */}
        <div className="px-8">
          <h1 className="text-xl font-semibold text-gray-900">Filtre</h1>
          <div className="flex mt-2">
            <div className="w-1/5">
              <input
                type="email"
                name="email"
                id="email"
                value={filterAdministrator}
                onChange={onChangeNumeAdministrator}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Nume administrator"
              />
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8 mt-10">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Clienti</h1>
              <p className="mt-2 text-sm text-gray-700">
                Mai jos sunt prezentati toti clientii inscrisi pe platforma
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={addUser}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Adauga client
              </button>
            </div>
          </div>
          <div className="grid grid-cols-5">
            <div>
              <label
                for="location"
                class="block text-sm font-medium text-gray-700"
              >
                Stare dosar
              </label>
              <select
                id="stareDosar"
                name="stareDosar"
                onChange={onChangeStareDosar}
                class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option>Toate</option>
                <option>Nedepus</option>
                <option>Depus</option>
                <option>Finalizat</option>
              </select>
            </div>
          </div>
          <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Nr. Contract
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Nume complet
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    CNP
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center"
                  >
                    COD CLIENT
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    STARE DOSAR
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    PUTERE INVERTOR
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    PUTERE PANOURI
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {clients
                  ? clients
                      .filter((cl) => {
                        if (filterAdministrator != "") {
                          if (
                            cl.REPREZENTANT.toLowerCase().includes(
                              filterAdministrator.toLowerCase()
                            )
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        if (stareDosarClienti == "Toate") {
                          if (cl.STARE != "finalizat") {
                            return true;
                          }
                        }
                        if (stareDosarClienti == "Depus") {
                          if (cl.STARE == "depus") {
                            return true;
                          }
                        }
                        if (stareDosarClienti == "Finalizat") {
                          if (cl.STARE == "finalizat") {
                            return true;
                          }
                        }
                        if (stareDosarClienti == "Nedepus") {
                          if (cl.STARE == "nedepus") {
                            return true;
                          }
                        }
                        return false;
                      })
                      .sort((a, b) => {
                        return b.ID - a.ID;
                      })
                      // .sort((a, b) => {
                      //   if (
                      //     a.STARE_LUCRARE == "avans" &&
                      //     b.STARE_LUCRARE != "avans"
                      //   ) {
                      //     return -1;
                      //   }
                      //   if (
                      //     a.STARE_LUCRARE != "avans" &&
                      //     b.STARE_LUCRARE == "avans"
                      //   ) {
                      //     return -1;
                      //   }

                      //   return 0;
                      // })
                      .map((person) => (
                        <tr key={person.ID}>
                          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                            <span>
                              {person.ID}

                              <svg
                                viewBox="0 0 10 10"
                                xmlns="http://www.w3.org/2000/svg"
                                className={
                                  "w-4 h-4 " +
                                  (person.STARE == "finalizat"
                                    ? "fill-green-500"
                                    : person.STARE == "nedepus"
                                    ? "fill-red-500"
                                    : person.STARE == "depus"
                                    ? "fill-yellow-500"
                                    : "")
                                }
                              >
                                <circle cx="5" cy="5" r="5" />
                              </svg>
                            </span>
                            <dl className="font-normal lg:hidden">
                              <dt className="sr-only">NUME</dt>
                              <dd className="mt-1 text-gray-700">
                                {person.NUME || person.PRENUME
                                  ? person.NUME + " " + person.PRENUME
                                  : person.NUME_FIRMA}
                              </dd>
                              <dt className="sr-only sm:hidden">CNP</dt>
                              <dd className="mt-1 text-gray-500 sm:hidden">
                                {person.CNP ? person.CNP : person.CUI}
                              </dd>

                              <div class="relative flex items-start mt-5">
                                <div class="flex h-5 items-center">
                                  <input
                                    id="comments"
                                    name="FINALIZAT_MONTAJ"
                                    onChange={(e) =>
                                      onChangeStareLucrare(e, person)
                                    }
                                    type="checkbox"
                                    defaultChecked={
                                      person.STARE_LUCRARE == "finalizat_montaj"
                                        ? true
                                        : false
                                    }
                                    class="h-8 w-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                </div>
                                <div class="ml-3 text-sm">
                                  <label
                                    for="comments"
                                    class="font-medium text-gray-700"
                                  >
                                    Finalizat lucrare
                                  </label>
                                </div>
                              </div>
                            </dl>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {person.NUME || person.PRENUME
                              ? person.NUME + " " + person.PRENUME
                              : person.NUME_FIRMA}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {person.CNP ? person.CNP : person.CUI}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500 text-center">
                            {person.COD_CLIENT}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500">
                            {person.STARE}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500 text-center">
                            {parseInt(person.PUTERE_INVERTOR) / 1000} kW
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500 text-center">
                            {(parseInt(person.NR_PANOURI) *
                              parseInt(person.PUTERE_PANOU)) /
                              1000}{" "}
                            kW
                          </td>
                          <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link to={"/editclient/" + person.ID}>
                              <button
                                className="text-indigo-600 hover:text-indigo-900"
                                // onClick={updateClient}
                              >
                                Vezi
                                <span className="sr-only">, {person.NUME}</span>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
        {/* Sfarsit tabela */}
      </div>
    </>
  );
};

export default Example;
