import axios from "axios";

export const getAllBaterii = async () => {
  const res = await axios.get(`https://py.iluminatsolar.com/baterii`, {
    params: {},
  });
  return res.data;
};

// export const addNewPanou = async (newPanou) => {
//   const res = await axios.post(`https://py.iluminatsolar.com/adaugare_panou`, {
//     nume: newPanou.nume,
//     putere: newPanou.putere,
//     stoc_ramas: newPanou.stoc_ramas,
//   });
//   return res.data;
// };

// export const updatePanou = async (id, newPanou) => {
//   const res = await axios.put(`https://py.iluminatsolar.com/update_panou`, {
//     id: id,
//     nume: newPanou.nume,
//     putere: newPanou.putere,
//     stoc_ramas: newPanou.stoc_ramas,
//   });
//   return res.data;
// };

// export const deletePanou = async (id) => {
//   const res = await axios.delete(`https://py.iluminatsolar.com/delete_panou`, {
//     data: {
//       id: id,
//     },
//   });
//   return res.data;
// };

// export const updatePretCalc = async (label, value) => {
//   const res = await axios.post(
//     `https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/fotovoltaice-frontend/updatePreturiCalculator.php`,
//     {
//       label: label,
//       value: value,
//     },
//     {
//       headers: {
//         "Content-Type": "application/json", // Specifică tipul de conținut
//       },
//     }
//   );
//   return res.data;
// };

// export const deleteCabinet = async (id) => {
//   const res = await axios.delete(
//     `${process.env.REACT_APP_DEV_HOST}/cabinetes`,
//     {
//       data: {
//         id: id,
//       },
//     }
//   );
//   return res.data;
// };
