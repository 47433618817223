import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import { CheckIcon } from "@heroicons/react/outline";
import Steps from "./Steps";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { UserContext } from "./providers/UserProvider";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const steps = [
  {
    id: "01",
    name: "Buletin",
    description: "Important CNP si adresa",
    href: "#",
    status: "current",
  },
  {
    id: "02",
    name: "Certificat ANAF",
    description: "Important CUI si CNP",
    href: "#",
    status: "upcoming",
  },
  {
    id: "03",
    name: "Certificat Primarie",
    description: "Important impozit",
    href: "#",
    status: "upcoming",
  },
  {
    id: "04",
    name: "Carte Funciara",
    description: "Important ipoteca",
    href: "#",
    status: "upcoming",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const people = [
  {
    id: 1,
    name: "acesta trebuie sa fie in termen de valabilitate la data inscrierii in aplicatie ",
  },
  {
    id: 2,
    name: "se va avea în vedere ca adresa de implementare înscrisa de solicitant în aplicatie sa coincida cu adresa din actul de identitate; nu se accepta adresa de resedinta sau viza de flotant; se accepta carte de identitate provizorie; ",
  },
  {
    id: 3,
    name: "se va verifica daca adresa de implementare apartine regiunii de dezvoltare în care s-a facut înscrierea în aplicatia informatica, în cazul în care adresa de domiciliu este în alta regiune, cererea va fi respinsa.",
  },
];

const anaf = [
  {
    id: 4,
    name: "trebuie sa fle emis pe numele solicitantului si se va identifica cu CNP (pentru persoane fizice) sau CIF (pentru parohii). Nu sunt acceptate certificatele emise pe numele/CNP-urile  coproprietarilor. Adresa din certificatul de atestare NU este obligatoriu sa fie identica cu cea a locului de implementare. Sunt eligibile si certificatele eliberate în SPV;   ",
  },
  {
    id: 5,
    name: "trebuie sa fie în termen de valabilitate la data înscrierii. Termenul de valabilitate pentru persoane fizice este de 90 de zile de la data emiterii si 30 de zile pentru unitati de cult;   ",
  },
  {
    id: 6,
    name: "se va avea în vedere sa nu fie înscrise sume la sectiunea A. OBLIGATII FISCALE SI ALTE OBLIGATII BUGETARE. În situatia în care sunt prevazute obligatii de plata restante la sectiunea A, iar valoarea acestora este mai mica sau egala cu totalul înscris la sectiunea B. SUME DE RAMBURSAT/RESTITUIT, se va considera ca solicitantul NU ARE OBLIGATII DE PLATA RESTANTE;   ",
  },
];

const primarie = [
  {
    id: 7,
    name: "trebuie sa fie emis pe numele solicitantului si se va identiflca cu CNP (pentru persoane fizice) sau CIF (pentru parohii). Nu sunt acceptate certificatele emise pe numele/CNP—urile coproprietarilor. Sunt eligibile si certificatele eliberate în SPV; se considera ca certificatul îndeplineste conditiile de conformitate inclusiv daca în continutul acestuia se regasesc atât CNP-ul solicitantului, cât si CNP—ul coproprietarului",
  },
  {
    id: 8,
    name: "trebuie sa fie în termen de valabilitate la data înscrierii. Termenul de valabilitate este de 30 de zile de la data emiterii; ",
  },
  {
    id: 9,
    name: "se va avea în vedere ca solicitantul sa nu aiba obligatii de plata restante. Sumele înscrise pe coloana „Curente” NU sunt considerate obligatii de plata restante;   ",
  },
  {
    id: 10,
    name: "se va avea în vedere ca la adresa unde se implementeaza proiectul, în cladirea deservita de sistemul de panouri fotovoltaice, sa nu se desfasoare activitati economice.   ",
  },
];

const cartefunciara = [
  {
    id: 11,
    name: "se va verifica valabilitatea de 60 de zile & extrasului, calculata de la momentul eliberarii (datamentionata pe ultima pagina & extrasului);   ",
  },
  {
    id: 12,
    name: "la Cap. A din extrasul de carte funciara: se verifica existenta constmctiei; se verifica daca amplasamentul (adresa sau nr. de carte funciara sau nr. cadastral) imobilului constructie coincide cu cel înscris de solicitant în aplicatie; se verifica la Observatii/qerinte, sau la Destinatie constructie din capitolul -Date referitoare la constructii— ca imobilul sa nu prevada ”activitate economica” sau sa nu prevada vreun tip de imobil destinat desfasurarii de activitati economice;   ",
  },
  {
    id: 13,
    name: "la Cap. B din extrasul de carte funciara: se verifica daca solicitantul este proprietar al imobilului constructie înscris la Cap. A; în cazul în care sistemul de panourifotovoltaice care deserveste, constructia se amplaseaza pe teren, se verifica, în plus fata de veriflcarea mentionata, daca solicitantul este proprietar sau detine un drept de folosinta asupra terenului înscris la Cap. A; nu conteaza daca pe imobilul respectiv sunt unul sau mai multi proprietari.   ",
  },
  {
    id: 14,
    name: "la Cap. C din extrasul de carte funciara: se verifica daca imobilul nu este afectat de sarcini în favoarea unei persoane juridice sau a unei entitati care desfasoara activitati economice, cu exceptia cazului în care este afectat de o ipoteca imobiliara.   ",
  },
  {
    id: 15,
    name: "În extrasul de care funciara nu trebuie sa existe înscrise mentiuni asupra imobilului constructie si teren în favoarea unei persoane juridice/entitate fara personalitate juridica, respectiv: existenta unui contract de comodat/locatiune/închiriere sau precontract de comodat/locatiune/închiriere/vânzare-cumparare, declararea ca sediu social/profesional al unui operator economic/întreprinderi individuale/PFA/orice alta entitate care desfasoara activitate economica sau ca patrimoniu de afectatiune (exemplele nu sunt limitative); în cazul în care din extrasul de carte funciara rezulta o constructie sau o parte a unei constructii în care îsi are sediul/punctul de lucru o întreprindere sau o entitate dintre cele mentionate anterior, fmantarea se poate acorda sub conditia ca implementarea proiectului (deservirea sistemului) sa se realizeze exclusiv pe imobilul/imobilele constructie/partea constructiei de tip locuinta (si anexe gOspodaresti) si doar cu prezentarea dovezii contorizarii separate & acesteia; cerinta contorizarii separate este valabila pentru toate persoanele juridice/PFA/entitate fara personalitate juridica, indiferent daca acestea au doar sediul social/profesional sau un punct de lucru în imobilul constructie respectiv;   ",
  },
  {
    id: 16,
    name: "In extrasul de carte funciara nu sunt înscrise mentiuni legate de: litigii în curs de solutionare la instantele judecatoresti, revendicari potrivit unei legi speciale sau dreptului comun sau proceduri de expr0priere pentru cauza de utilitate publica; ",
  },
  {
    id: 17,
    name: "De asemenea, daca adresa din cartea de identitate nu corespunde cu cea din extrasul de carte funciara si nu a fost încarcat în aplicatia informatica nomenclator stradal/ceniEcat/adeverinta/orice alt document emis de primarie, instalatorul va solicita persoanei fizice o declaratie pe propria raspundere din care sa rezulte ca adresa din cartea de identitate corespunde cu cea din extrasul CF prezentat. Ulterior, pâna la momentul decontarii, persoana fizica va depune la instalator documentul emis de Primarie care certifica identitatea dintre adresa de domiciliu si adresa imobilului înscris în CF. Aceste documente vor fi pastrate conform art. 4.1. litera q) din Contractul de participare în vederea decontarii. În cazul în care instalatia de panouri fotovoltaice se monteaza pe imobilul teren, iar acesta este înregistrat în alta carte funciara decât cea a constructiei, fiind prezentate doua extrase de carte funciara, în extrasul de carte funciara aferent terenului se vor urmari aceleasi elemente prevazute mai sus (cu exceptia dreptului de proprietate)   ",
  },
];

const Afm = () => {
  const [dosar, setDosar] = useState();
  const [obs, setObs] = useState();
  const { user } = useContext(UserContext);
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Probleme regasite in dosar:</Text>
        </View>
      </Page>
    </Document>
  );
  useEffect(() => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/getAfmClient.php?id=" +
          id
      )
      .then((response) => {
        setDosar(response.data.client);
        setObs(response.data.client.OBS);
      });
  }, []);
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState("01");
  // cons []
  const [steps, setSteps] = useState([
    {
      id: "01",
      name: "Buletin",
      description: "Important CNP si adresa",
      href: "#",
      vals: [1, 2, 3],
      status: "current",
    },
    {
      id: "02",
      name: "Certificat ANAF",
      description: "Important CUI si CNP",
      href: "#",
      vals: [4, 5, 6],
      status: "upcoming",
    },
    {
      id: "03",
      name: "Certificat Primarie",
      description: "Important impozit",
      href: "#",
      vals: [7, 8, 9, 10],
      status: "upcoming",
    },
    {
      id: "04",
      name: "Carte Funciara",
      description: "Important ipoteca",
      href: "#",
      vals: [11, 12, 13, 14, 15, 16, 17],
      status: "upcoming",
    },
  ]);

  const addClientAfm = (e) => {
    e.preventDefault();

    const client = {
      NUME: "MIHAILA",
      PRENUME: "COSMIN",
      CNP: "500725152506",
    };
    axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/addClientAfm.php",
        client
      )
      .then((response) => {
        console.log(response);
      });
  };

  const updateClient = (e, label, value) => {
    const client = {
      ID: id,
      LABEL: label,
      VALUE: value,
    };

    setDosar((prevState) => ({
      ...prevState,
      ["VAL_" + label]: value,
    }));

    console.log(dosar);

    const res = axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/updateClientAfm.php",
        client
      )
      .then((response) => {
        console.log(response);
      });

    toast.promise(
      res,
      {
        loading: "Se actualieaza...",
        success: "Actualizat cu succes",
        error: "Eroare la actualizare",
      },
      {
        style: {
          minWidth: "250px",
          position: "BOTTOM_CENTER",
        },
      }
    );
  };
  const updateClientObs = (e) => {
    const client = {
      ID: id,
      OBS: obs,
    };

    const res = axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/updateObsAfm.php",
        client
      )
      .then((response) => {
        console.log(response);
      });

    toast.promise(
      res,
      {
        loading: "Se actualieaza...",
        success: "Actualizat cu succes",
        error: "Eroare la actualizare",
      },
      {
        style: {
          minWidth: "250px",
          position: "BOTTOM_CENTER",
        },
      }
    );
  };
  const updateClientVerificat = (e, label) => {
    const client = {
      ID: id,
      LABEL: label,
      EMAIL: user.email,
    };

    console.log(dosar);

    const res = axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/updateVerificatAfm.php",
        client
      )
      .then((response) => {
        axios
          .get(
            "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/getAfmClient.php?id=" +
              id
          )
          .then((response) => {
            setDosar(response.data.client);
          });
        console.log(response);
      });

    toast.promise(
      res,
      {
        loading: "Se actualieaza...",
        success: "Actualizat cu succes",
        error: "Eroare la actualizare",
      },
      {
        style: {
          minWidth: "250px",
          position: "BOTTOM_CENTER",
        },
      }
    );
  };

  const onChangeDoc = (e, stepId) => {
    e.preventDefault();
    var auxStep = steps;
    console.log(stepId);
    setCurrentStep(stepId);
    auxStep.map((st) => {
      if (st.status == "current") {
        st.status = "upcoming";
      }
      if (st.id === stepId) {
        st.status = "current";
      }
    });
    setSteps(auxStep);
  };
  return (
    <>
      <Header />
      <div className="container mx-auto px-10">
        <Link to={"/afm/report/" + id}>
          <button
            className={
              "hover:bg-red-500 bg-red-600 mt-2 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            }
          >
            Vezi raport
          </button>
        </Link>
      </div>
      <div className="container mx-auto my-10 pb-10 flex justify-between px-10">
        <div>
          <p>
            Nume:{" "}
            <span className="font-bold">
              {dosar?.NUME + " " + dosar?.PRENUME}
            </span>
          </p>
          <p>
            CNP: <span className="font-bold">{dosar?.CNP}</span>
          </p>
          <p>
            TELEFON: <span className="font-bold">{dosar?.TELEFON}</span>
          </p>
          <p className="w-[400px]">
            Observatii:{" "}
            <span className="font-bold">
              <textarea
                type="text"
                name="obs"
                id="obs"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                // placeholder="you@example.com"
              />
            </span>
            <button
              onClick={(e) => {
                e.preventDefault();
                updateClientObs(e);
              }}
              className={
                "hover:bg-indigo-500 bg-indigo-600 mt-2 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
            >
              Salveaza observatii
            </button>
          </p>
        </div>
        <div>
          {dosar?.VERIFICAT == 0 ? (
            <button
              onClick={(e) => {
                updateClientVerificat(e, "VERIFICAT");
              }}
              className={
                "hover:bg-indigo-500 bg-indigo-600 ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
            >
              VERIFICAT
            </button>
          ) : (
            ""
          )}
          {dosar?.VERIFICAT == 1 &&
          dosar?.VERIFICAT_EMAIL == user.email &&
          dosar?.GOLDEN == 0 ? (
            <button
              // onClick={(e) => {
              //   updateClient(e, person.id, 2);
              // }}
              disabled
              className={
                "bg-indigo-300 ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
            >
              Ai verificat deja acest dosar
            </button>
          ) : (
            ""
          )}
          {dosar?.VERIFICAT == 1 &&
          dosar?.VERIFICAT_EMAIL != user.email &&
          dosar?.GOLDEN == 0 ? (
            <button
              onClick={(e) => {
                updateClientVerificat(e, "GOLDEN");
              }}
              className={
                "hover:bg-indigo-500 bg-indigo-600 ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
            >
              GOLDEN HAND
            </button>
          ) : (
            ""
          )}
          {dosar?.VERIFICAT == 1 && dosar?.GOLDEN == 1 ? (
            <button
              disabled
              className={
                "bg-green-600 ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
            >
              DOSAR VERIFICAT COMPLET
            </button>
          ) : (
            ""
          )}
          <p className="mt-4">
            Verificat de:{" "}
            <span className="font-bold">{dosar?.VERIFICAT_EMAIL}</span>
          </p>
          <p>
            Golden Hand de:{" "}
            <span className="font-bold">{dosar?.GOLDEN_EMAIL}</span>
          </p>
        </div>
      </div>
      {dosar ? (
        <Steps
          steps={steps}
          dosar={dosar}
          onChangeDoc={onChangeDoc}
          currentStep={currentStep}
        />
      ) : (
        ""
      )}
      {/* Buletin */}
      <div
        className={
          (currentStep == "01" ? "" : "hidden") + " w-[800px] mx-auto mt-10"
        }
      >
        <fieldset>
          <legend className="text-base font-semibold leading-6 text-gray-900">
            Buletin
          </legend>
          <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
            {people.map((person, personIdx) => (
              <div
                key={personIdx}
                className={
                  (dosar?.["VAL_" + person.id] == "1"
                    ? "bg-green-100"
                    : dosar?.["VAL_" + person.id] == "2"
                    ? "bg-red-100"
                    : "bg-gray-100") +
                  " relative flex items-start py-4 mb-4 px-4 rounded-md"
                }
              >
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`person-${person.id}`}
                    className="select-none font-medium text-gray-900"
                  >
                    {person.name}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 1);
                    }}
                    type="button"
                    disabled={
                      dosar?.["VAL_" + person.id] == "1" ||
                      (dosar?.GOLDEN == 1 && dosar?.GOLDEN_EMAIL != user.email)
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "1"
                        ? "hover:bg-green-200 bg-green-300"
                        : "hover:bg-green-500 bg-green-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    OK
                  </button>
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 2);
                    }}
                    disabled={
                      dosar?.["VAL_" + person.id] == "2" ||
                      (dosar?.GOLDEN == 1 && dosar?.GOLDEN_EMAIL != user.email)
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "2"
                        ? "hover:bg-red-200 bg-red-300"
                        : "hover:bg-red-500 bg-red-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    REFUZAT
                  </button>
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>

      {/* ANAF */}
      <div
        className={
          (currentStep == "02" ? "" : "hidden") + " w-[800px] mx-auto mt-10"
        }
      >
        <fieldset>
          <legend className="text-base font-semibold leading-6 text-gray-900">
            ANAF
          </legend>
          <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
            {anaf.map((person, personIdx) => (
              <div
                key={personIdx}
                className={
                  (dosar?.["VAL_" + person.id] == "1"
                    ? "bg-green-100"
                    : dosar?.["VAL_" + person.id] == "2"
                    ? "bg-red-100"
                    : "bg-gray-100") +
                  " relative flex items-start py-4 mb-4 px-4 rounded-md"
                }
              >
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`person-${person.id}`}
                    className="select-none font-medium text-gray-900"
                  >
                    {person.name}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 1);
                    }}
                    type="button"
                    disabled={
                      dosar?.["VAL_" + person.id] == "1" || dosar?.GOLDEN == 1
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "1"
                        ? "hover:bg-green-200 bg-green-300"
                        : "hover:bg-green-500 bg-green-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    OK
                  </button>
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 2);
                    }}
                    disabled={
                      dosar?.["VAL_" + person.id] == "2" || dosar?.GOLDEN == 1
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "2"
                        ? "hover:bg-red-200 bg-red-300"
                        : "hover:bg-red-500 bg-red-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    REFUZAT
                  </button>
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      {/* Primarie */}
      <div
        className={
          (currentStep == "03" ? "" : "hidden") + " w-[800px] mx-auto mt-10"
        }
      >
        <div>
          <legend className="text-base font-semibold leading-6 text-gray-900">
            Primarie
          </legend>
          <div className="mt-4 border-b border-t border-gray-200">
            {primarie.map((person, personIdx) => (
              <div
                key={personIdx}
                className={
                  (dosar?.["VAL_" + person.id] == "1"
                    ? "bg-green-100"
                    : dosar?.["VAL_" + person.id] == "2"
                    ? "bg-red-100"
                    : "bg-gray-100") +
                  " relative flex items-start py-4 mb-4 px-4 rounded-md"
                }
              >
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`person-${person.id}`}
                    className="select-none font-medium text-gray-900"
                  >
                    {person.name}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 1);
                    }}
                    type="button"
                    disabled={
                      dosar?.["VAL_" + person.id] == "1" || dosar?.GOLDEN == 1
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "1"
                        ? "hover:bg-green-200 bg-green-300"
                        : "hover:bg-green-500 bg-green-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    OK
                  </button>
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 2);
                    }}
                    disabled={
                      dosar?.["VAL_" + person.id] == "2" || dosar?.GOLDEN == 1
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "2"
                        ? "hover:bg-red-200 bg-red-300"
                        : "hover:bg-red-500 bg-red-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    REFUZAT
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Carte funciara */}
      <div
        className={
          (currentStep == "04" ? "" : "hidden") + " w-[800px] mx-auto mt-10"
        }
      >
        <div>
          <legend className="text-base font-semibold leading-6 text-gray-900">
            Carte funciara
          </legend>
          <div className="mt-4 border-b border-t border-gray-200">
            {cartefunciara.map((person, personIdx) => (
              <div
                key={personIdx}
                className={
                  (dosar?.["VAL_" + person.id] == "1"
                    ? "bg-green-100"
                    : dosar?.["VAL_" + person.id] == "2"
                    ? "bg-red-100"
                    : "bg-gray-100") +
                  " relative flex items-start py-4 mb-4 px-4 rounded-md"
                }
              >
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`person-${person.id}`}
                    className="select-none font-medium text-gray-900"
                  >
                    {person.name}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 1);
                    }}
                    type="button"
                    disabled={
                      dosar?.["VAL_" + person.id] == "1" || dosar?.GOLDEN == 1
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "1"
                        ? "hover:bg-green-200 bg-green-300"
                        : "hover:bg-green-500 bg-green-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    OK
                  </button>
                  <button
                    onClick={(e) => {
                      updateClient(e, person.id, 2);
                    }}
                    disabled={
                      dosar?.["VAL_" + person.id] == "2" || dosar?.GOLDEN == 1
                        ? true
                        : false
                    }
                    className={
                      (dosar?.["VAL_" + person.id] == "2"
                        ? "hover:bg-red-200 bg-red-300"
                        : "hover:bg-red-500 bg-red-600") +
                      " ml-8 flex items-center gap-x-1 rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    }
                  >
                    REFUZAT
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Afm;
