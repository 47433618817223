import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Dashboard from "./Dashboard";
import { ToastContainer } from "react-toastify";
import { LoadingProvider } from "./providers/LoadingProvider";
import { UserProvider } from "./providers/UserProvider";
import "react-big-calendar/lib/css/react-big-calendar.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <LoadingProvider>
      <UserProvider>
        <App />
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          stacked
        />
      </UserProvider>
    </LoadingProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
