import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const firebaseConfig = {
  apiKey: "AIzaSyA6hnMmx6zp4kETpyWqq2lN7HcYTEmrFpk",
  authDomain: "baterie-ardeleanu.firebaseapp.com",
  projectId: "baterie-ardeleanu",
  storageBucket: "baterie-ardeleanu.appspot.com",
  messagingSenderId: "472468010948",
  appId: "1:472468010948:web:a26dd3bdbed1f462ba9691",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const logInWithEmailAndPassword = async ({ email, password }) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    toast.error(err.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const singOutUser = async () => {
  try {
    await auth.signOut();
  } catch (err) {
    toast.error(err.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};
export const registerWithEmailAndPassword = async ({ email, password }) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    return res;
  } catch (err) {
    toast.error(err.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const forgotPasswordWithEmail = async (email) => {
  try {
    const res = await auth.sendPasswordResetEmail(email).then(function () {
      toast.success("Va rugam sa va verificati email-ul!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
  } catch (err) {
    toast.error(err.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export default firebase;
