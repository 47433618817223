import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updatePretCalc } from "../api/calculator";
import AdministrarePanouri from "./AdministrarePanouri";
import AdministrareInvertoare from "./AdministrareInvertoare";
import { getAllAuxiliare } from "../api/auxiliare";
import AdministrareAuxiliare from "./AdministrareAuxiliare";

const AdministrareCalculatorDashboard = () => {
  const [showEdit, setShowEdit] = useState({
    prb: false,
    tigla: false,
  });

  const [preturi, setPreturi] = useState(null);

  useEffect(() => {
    axios
      .get(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/fotovoltaice-frontend/getPreturiCalculator.php"
      )
      .then((response) => {
        setPreturi({
          prb: response.data.prb.pret,
          tigla: response.data.tigla.pret,
        });
      });
    getAllAuxiliare();
  }, []);

  return (
    <div className="px-10 py-20 grid grid-cols-10 gap-10">
      <div className="col-span-6">
        <AdministrarePanouri />
        <AdministrareInvertoare />
      </div>
      <div className="col-span-4">
        <AdministrareAuxiliare />
      </div>
    </div>
  );
};

export default AdministrareCalculatorDashboard;
