import { useEffect, useState } from "react";
import { updatePretCalc } from "../api/calculator";
import { getAllAuxiliare } from "../api/auxiliare";
import { toast } from "react-toastify";

const AdministrareAuxiliare = () => {
  const [auxiliare, setAuxiliare] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEdit, setShowEdit] = useState(null);
  const fetchData = () => {
    setLoading(true);
    getAllAuxiliare().then((res) => {
      setAuxiliare(res);
      // foreach res
      let auxObj = {};
      res.forEach((element) => {
        auxObj[element.eticheta] = false;
      });
      console.log(auxObj);
      setShowEdit(auxObj);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangePret = (event) => {
    const { name, value } = event.target;
    setShowEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSavePret = (e) => {
    const { name, value } = e.target;
    const res = updatePretCalc(value, parseInt(2)).then(() => {
      setShowEdit(false);
    });
    toast.promise(
      res,
      {
        pending: "Se actualizeaza...",
        success: "Actualizat cu succes!",
        error: "Eroare la actualizare!",
      },
      {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-gray-50 shadow-md">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Preturi auxiliare
          </h2>
          {auxiliare.map((aux) => {
            return (
              <>
                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-4">
                  <div className="pt-2 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-40 sm:flex-none sm:pr-6">
                      {aux.nume}
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div
                        className={
                          (showEdit[aux.eticheta] === false ? "" : "hidden ") +
                          "text-gray-900"
                        }
                      >
                        {aux.pret} lei/{aux.um}
                      </div>
                      <div
                        className={
                          showEdit[aux.eticheta] === true ? "" : "hidden "
                        }
                      >
                        <input
                          id="prb"
                          name="prb"
                          type="text"
                          onChange={onChangePret}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-officialPrimary sm:text-sm sm:leading-6"
                          // defaultValue={patient?.extra_information["ocupatie"]}
                          value={aux?.pret}
                        ></input>
                      </div>
                      <button
                        type="button"
                        value={"ocupatie"}
                        onClick={() => {
                          setShowEdit({
                            ...showEdit,
                            [aux.eticheta]: !showEdit[aux.eticheta],
                          });
                        }}
                        className={
                          (showEdit[aux.eticheta] ? "hidden " : "") +
                          "font-semibold text-officialPrimary hover:text-officialSecondary"
                        }
                      >
                        Modifică
                      </button>
                      <button
                        type="button"
                        name="prb"
                        value={"prb"}
                        onClick={(e) => onSavePret(e)}
                        className={
                          (!showEdit[aux.eticheta] ? "hidden " : "") +
                          "font-semibold text-officialPrimary hover:text-officialSecondary"
                        }
                      >
                        Salvează
                      </button>
                      <button
                        type="button"
                        value={"ocupatie"}
                        //   onClick={(e) => onCancelExtraInfo(e)}
                        className={
                          (!showEdit[aux.eticheta] ? "hidden " : "") +
                          "font-semibold text-red-600 hover:text-red-500"
                        }
                      >
                        Anulează
                      </button>
                    </dd>
                  </div>
                </dl>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AdministrareAuxiliare;
