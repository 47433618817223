const CalculatorSidebar = ({
  selectedPanou,
  panouri,
  numarPanouri = 1,
  selectedInvertor,
  invertoare,
  auxiliare,
  tipClient,
  tipBransament,
  cabluAC,
  cabluDC,
  nrModuleBaterii,
  selectedBaterie,
  baterii,
  selectedOptionale,
  adaugaBaterii,
  adaugaBackup,
  selectedContor,
  contoare,
}) => {
  const cursEuro = 5;

  const pretkW =
    selectedPanou !== null
      ? numarPanouri *
        panouri.find((el) => el.id == selectedPanou)?.putere *
        0.3 *
        cursEuro
      : 0;

  const pretInvertor =
    selectedInvertor !== null
      ? invertoare.find((el) => el.id == selectedInvertor)?.pret
      : 0;

  const pretCabluAC =
    cabluAC * auxiliare.find((el) => el.eticheta === "cablu_ac")?.pret;
  const pretCabluDC =
    cabluDC * auxiliare.find((el) => el.eticheta === "cablu_dc")?.pret;

  const pretMinirail =
    Math.ceil(parseInt(numarPanouri) * 2.3) *
    auxiliare.find((el) => el.eticheta === "minirail")?.pret;

  const pretCleme =
    Math.ceil(parseInt(numarPanouri) * 2.3) *
    auxiliare.find((el) => el.eticheta === "clema")?.pret;

  const pretCopex =
    Math.ceil(cabluDC / 2) *
    auxiliare.find((el) => el.eticheta === "copex")?.pret;

  const pretCabluFTP =
    5 * auxiliare.find((el) => el.eticheta === "cablu_ftp")?.pret;
  const pretSigurantaAc =
    1 * auxiliare.find((el) => el.eticheta === "siguranta_ac")?.pret;
  const pretTablouSigurante =
    1 * auxiliare.find((el) => el.eticheta === "tablou_sigurante")?.pret;
  const pretBMPMonofazat =
    1 * auxiliare.find((el) => el.eticheta === "bmp_monofazat")?.pret;
  const pretConectorMC4 =
    (numarPanouri > 10 ? 4 : 2) *
    auxiliare.find((el) => el.eticheta === "conector_mc4")?.pret;
  const pretPortSiguranta =
    (numarPanouri > 10 ? 2 : 1) *
    auxiliare.find((el) => el.eticheta === "port_siguranta")?.pret;
  const pretDescPlusSig =
    (numarPanouri > 10 ? 2 : 1) *
    auxiliare.find((el) => el.eticheta === "descarcator_plus_siguranta")?.pret;

  const pretBaterii =
    adaugaBaterii === true && selectedBaterie !== null
      ? nrModuleBaterii * baterii.find((el) => el.id == selectedBaterie)?.pret
      : 0;

  const pretBMS =
    adaugaBaterii === true && selectedBaterie !== null
      ? selectedBaterie == 3
        ? baterii.find((el) => el.id == 4)?.pret
        : baterii.find((el) => el.id == 7)?.pret
      : 0;

  // optionale
  const pretPrb =
    selectedOptionale.prb === true
      ? auxiliare.find((el) => el.eticheta === "prb")?.pret
      : 0;
  const pretImpamantare =
    selectedOptionale.impamantare === true
      ? auxiliare.find((el) => el.eticheta === "impamantare")?.pret
      : 0;
  const pretStructuraZincata =
    selectedOptionale.structura_zincata === true
      ? auxiliare.find((el) => el.eticheta === "structura_zincata")?.pret *
        numarPanouri
      : 0;
  const pretRegimInaltime =
    selectedOptionale.regim_inaltime === true
      ? auxiliare.find((el) => el.eticheta === "regim_inaltime")?.pret
      : 0;
  const pretSporPutere =
    selectedOptionale.spor_putere === true
      ? auxiliare.find((el) => el.eticheta === "spor_putere")?.pret
      : 0;
  const pretTrecereTrifazat =
    selectedOptionale.trecere_trifazat === true
      ? auxiliare.find((el) => el.eticheta === "trecere_trifazat")?.pret
      : 0;
  const pretTigla =
    selectedOptionale.tigla === true
      ? auxiliare.find((el) => el.eticheta === "tigla")?.pret
      : 0;

  const pretBackup =
    adaugaBackup === true
      ? invertoare.find((el) => el.id == selectedInvertor)?.producator ===
        "HUAWEI"
        ? invertoare.find((el) => el.id == selectedInvertor)?.tip == "monofazat"
          ? baterii.find((el) => el.id == 5)?.pret
          : baterii.find((el) => el.id == 6)?.pret
        : invertoare.find((el) => el.id == selectedInvertor)?.model ===
          "PRIMO GEN24 PLUS"
        ? baterii.find((el) => el.id == 8)?.pret
        : baterii.find((el) => el.id == 9)?.pret
      : 0;

  const pretContor =
    selectedContor === null
      ? 0
      : contoare.find((el) => el.id == selectedContor)?.pret;

  const pretOptionale =
    pretPrb +
    pretImpamantare +
    pretStructuraZincata +
    pretRegimInaltime +
    pretSporPutere +
    pretTrecereTrifazat +
    pretTigla;

  const pretManopera =
    5490 +
    ((numarPanouri * panouri.find((el) => el.id == selectedPanou)?.putere -
      3000) *
      750) /
      1000;

  console.log(pretManopera);

  const pretTotal =
    (pretkW +
      pretInvertor +
      pretSigurantaAc +
      pretCabluAC +
      pretCabluDC +
      pretMinirail +
      pretCleme +
      pretCopex +
      pretCabluFTP +
      pretSigurantaAc +
      pretTablouSigurante +
      pretBMPMonofazat +
      pretConectorMC4 +
      pretPortSiguranta +
      pretDescPlusSig +
      pretBaterii +
      pretOptionale +
      pretBMS +
      pretBackup +
      pretManopera +
      pretContor) *
    (tipClient === "afm" ? 1.0 : 0.8);
  return (
    <>
      <div className="p-6 border-r border-gray-200 h-full">
        <div className="font-semibold">Rezumat sistem</div>
        <div>
          <span className="text-xl font-semibold">
            Total: {Math.round(pretTotal)} (
            {Math.floor((pretTotal * 1.09).toFixed(2) / 10) * 10} cu TVA) lei
          </span>
        </div>
        <div className="flex flex-col gap-y-5">
          <div className="text-sm">
            <h4 className="font-semibold mb-2">Panouri</h4>
            <div className="flex justify-between">
              <span>Nume</span>
              <span>{panouri.find((el) => el.id == selectedPanou)?.nume}</span>
            </div>
            <div className="flex justify-between">
              <span>Putere</span>
              <span>
                {panouri.find((el) => el.id == selectedPanou)?.putere} W
              </span>
            </div>
            <div className="flex justify-between">
              <span>Numar</span>
              <span>{numarPanouri}</span>
            </div>
            <div className="flex justify-between">
              <span>Putere totala</span>
              <span>
                {(numarPanouri *
                  panouri.find((el) => el.id == selectedPanou)?.putere) /
                  1000}{" "}
                kW
              </span>
            </div>
            {numarPanouri *
              panouri.find((el) => el.id == selectedPanou)?.putere >
            invertoare.find((el) => el.id == selectedInvertor)?.putere * 1.5 ? (
              <>
                <span className="text-red-500">
                  Puterea totala a panourilor este mai mare decat 1.5 * puterea
                  invertorului
                </span>
              </>
            ) : null}
          </div>
          {selectedInvertor !== null ? (
            <div className="text-sm">
              <h4 className="font-semibold mb-2">Invertor</h4>
              <div className="flex justify-between">
                <span>Nume</span>
                <span>
                  {invertoare.find((el) => el.id == selectedInvertor)?.nume}
                </span>
              </div>
              <div className="flex justify-between">
                <span>Putere</span>
                <span>
                  {invertoare.find((el) => el.id == selectedInvertor)?.putere} W
                </span>
              </div>
              <div className="flex justify-between">
                <span>Tip</span>
                <span>
                  {invertoare.find((el) => el.id == selectedInvertor)?.tip}
                </span>
              </div>
            </div>
          ) : null}
          {selectedBaterie !== null ? (
            <div className="text-sm">
              <h4 className="font-semibold mb-2">Stocare</h4>
              <div className="flex justify-between">
                <span>Nume</span>
                <span>
                  {baterii.find((el) => el.id == selectedBaterie)?.model}
                </span>
              </div>
              <div className="flex justify-between">
                <span>Module</span>
                <span>{nrModuleBaterii}</span>
              </div>
              <div className="flex justify-between">
                <span>Putere</span>
                <span>
                  {(nrModuleBaterii *
                    baterii.find((el) => el.id == selectedBaterie)?.putere) /
                    1000}{" "}
                  kW
                </span>
              </div>
            </div>
          ) : null}
          <div className="text-sm">
            <h4 className="font-semibold mb-2">Alte detalii</h4>
            <div className="flex justify-between">
              <span>Tip client</span>
              <span>{tipClient.toUpperCase()}</span>
            </div>
            <div className="flex justify-between">
              <span>Bransament</span>
              <span>{tipBransament}</span>
            </div>
          </div>
          <div className="text-sm">
            <h4 className="font-semibold mb-2">Auxiliare</h4>
            <div className="flex justify-between">
              <span>Cablu AC</span>
              <span>{cabluAC}</span>
            </div>
            <div className="flex justify-between">
              <span>Cablu DC</span>
              <span>{cabluDC}</span>
            </div>
            <div className="flex justify-between">
              <span>Minirail</span>
              <span>{Math.ceil(parseInt(numarPanouri) * 2.3)}</span>
            </div>
            <div className="flex justify-between">
              <span>Cleme</span>
              <span>{Math.ceil(parseInt(numarPanouri) * 2.3)}</span>
            </div>
            <div className="flex justify-between">
              <span>Copex</span>
              <span>{Math.ceil(cabluDC / 2)}</span>
            </div>
            <div className="flex justify-between">
              <span>Cablu FTP</span>
              <span>{5}</span>
            </div>
            <div className="flex justify-between">
              <span>Siguranta AC</span>
              <span>{1}</span>
            </div>
            <div className="flex justify-between">
              <span>Tablou sigurante</span>
              <span>{1}</span>
            </div>
            <div className="flex justify-between">
              <span>BMP Monofazat</span>
              <span>{1}</span>
            </div>
            <div className="flex justify-between">
              <span>Conector MC4</span>
              <span>{numarPanouri > 10 ? 4 : 2}</span>
            </div>
            <div className="flex justify-between">
              <span>Port siguranta</span>
              <span>{numarPanouri > 10 ? 2 : 1}</span>
            </div>
            <div className="flex justify-between">
              <span>Desc. + sig.</span>
              <span>{numarPanouri > 10 ? 2 : 1}</span>
            </div>
          </div>
          <div className="text-sm">
            <h4 className="font-semibold mb-2">Optional</h4>
            {Object.keys(selectedOptionale)
              .filter((key) => selectedOptionale[key] === true)
              .map((key) => (
                <div className="flex justify-between">
                  <span>{key}</span>
                  <span>
                    {auxiliare.find((el) => el.eticheta === key)?.pret}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculatorSidebar;
