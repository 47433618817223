import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { updatePanou } from "../../api/panouri";

const PanouSidebar = ({ open, setOpen, selectedPanou, fetchData }) => {
  const [tempPanou, setTempPanou] = useState(null);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setTempPanou(selectedPanou);

    return () => {
      setTempPanou(null);
      setEditMode(false);
    };
  }, [selectedPanou]);

  const onChange = (e) => {
    const { value, name } = e.target;
    setTempPanou({ ...tempPanou, [name]: value });
  };

  const onSave = (e) => {
    e.preventDefault();
    const res = updatePanou(selectedPanou.id, tempPanou).then(() => {
      setOpen(false);
      fetchData();
    });
    toast.promise(res, {
      pending: "Salvare...",
      success: "Salvat cu succes!",
      error: "Eroare la salvare",
    });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Editează panoul
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-officialSecondary focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <form
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white"
                        onSubmit={onSave}
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pb-5 pt-6">
                                {editMode ? (
                                  <div>
                                    <label
                                      htmlFor="nume"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Nume
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="nume"
                                        id="nume"
                                        onChange={onChange}
                                        value={tempPanou?.nume}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="border-b border-gray-200 pb-2">
                                    {/* just the value */}
                                    <div className="flex justify-between">
                                      <span className="block text-sm font-medium leading-6 text-gray-900">
                                        Nume
                                      </span>
                                      <span className="block text-sm font-medium leading-6 text-gray-500">
                                        {tempPanou?.nume}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {editMode ? (
                                  <div>
                                    <label
                                      htmlFor="putere"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Putere
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="putere"
                                        id="putere"
                                        onChange={onChange}
                                        value={tempPanou?.putere}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="border-b border-gray-200 pb-2">
                                    {/* just the value */}
                                    <div className="flex justify-between">
                                      <span className="block text-sm font-medium leading-6 text-gray-900">
                                        Putere
                                      </span>
                                      <span className="block text-sm font-medium leading-6 text-gray-500">
                                        {tempPanou?.putere}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {editMode ? (
                                  <div>
                                    <label
                                      htmlFor="stoc_ramas"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Stoc ramas
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="stoc_ramas"
                                        id="stoc_ramas"
                                        onChange={onChange}
                                        value={tempPanou?.stoc_ramas}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-officialPrimary sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="border-b border-gray-200 pb-2">
                                    {/* just the value */}
                                    <div className="flex justify-between">
                                      <span className="block text-sm font-medium leading-6 text-gray-900">
                                        Stoc ramas
                                      </span>
                                      <span className="block text-sm font-medium leading-6 text-gray-500">
                                        {tempPanou?.stoc_ramas}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          {editMode ? (
                            <>
                              <button
                                type="button"
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={() => {
                                  setTempPanou(selectedPanou);
                                  setEditMode(false);
                                }}
                              >
                                Anulează
                              </button>
                              <button
                                type="submit"
                                className="ml-4 inline-flex justify-center rounded-md bg-officialPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-officialSecondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-officialPrimary"
                              >
                                Salvează
                              </button>
                            </>
                          ) : (
                            <button
                              type="button"
                              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => setEditMode(true)}
                            >
                              Modifică
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PanouSidebar;
