import React, { useState } from "react";
import { logInWithEmailAndPassword } from "./firebase/firebaseConfig";
import { Combobox } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
const OMS_CODES = [
  { value: "0", label: "0 - Clinic sanatos" },
  { value: "1", label: "1 - Holera" },
  { value: "2", label: "2 - Febra tifoida" },
  { value: "3", label: "3 - Paratifos" },
  { value: "4", label: "4 - Salmoneloze" },
  { value: "5", label: "5 - Shigelloze" },
  { value: "6", label: "6 - Alte infectii intestinale bacteriene" },
  { value: "7", label: "7 - Botulismul" },
  {
    value: "8",
    label: "8 - Alte intoxicatii alimentare de origine bacteriana",
  },
  { value: "9", label: "9 - Amibiaza" },
  { value: "10", label: "10 - Giardiaza (lambliaza)" },
  { value: "11", label: "11 - Alte boli intestinale prin protozoare" },
  { value: "12", label: "12 - Alte infectii virale si intestinale, precizate" },
  {
    value: "13",
    label: "13 - Diareea si gastroenterita (probabil infectioasa)",
  },
  { value: "14", label: "14 - Tuberculoza aparatului respirator" },
  { value: "15", label: "15 - Tuberculoza sistemului nervos" },
  { value: "16", label: "16 - Tuberculoza altor organe" },
  { value: "17", label: "17 - Tuberculoza miliara" },
  { value: "18", label: "18 - Antraxul" },
  { value: "19", label: "19 - Brucelloza" },
  { value: "20", label: "20 - Leptospiroza" },
  { value: "21", label: "21 - Alte antropo-zoonoze bacteriene" },
  { value: "22", label: "22 - Lepra" },
  { value: "23", label: "23 - Tetanosul neonatorum" },
  { value: "24", label: "24 - Tetanosul obstetrical" },
  { value: "25", label: "25 - Alte forme de tetanos" },
  { value: "26", label: "26 - Difteria" },
  { value: "27", label: "27 - Tusea convulsiva" },
  { value: "28", label: "28 - Scarlatina" },
  { value: "29", label: "29 - infectia cu meningococi" },
  { value: "30", label: "30 - Erizipelul" },
  { value: "31", label: "31 - Alte boli neclasate la alte locuri" },
  { value: "32", label: "32 - Sifilisul congenital" },
  { value: "33", label: "33 - Sifilisul recent (primar, secundar)" },
  { value: "34", label: "34 - Sifilisul tardiv" },
  { value: "35", label: "35 - Alte forme de sifilis, fara precizare" },
  { value: "36", label: "36 - Infectia gonococica" },
  { value: "37", label: "37 - Sancrul moale" },
  { value: "38", label: "38 - Trichomoniaza" },
  {
    value: "39",
    label:
      "39 - Alte boli precizate al caror mod de transmitere este in special sexual",
  },
  { value: "40", label: "40 - Trahomul" },
  { value: "41", label: "41 - Alte boli prin Chlamidia" },
  { value: "42", label: "42 - Tifosul exantematic" },
  { value: "43", label: "43 - Tifosul recrudescent (boala Brill)" },
  { value: "44", label: "44 - Alte rickettsioze" },
  { value: "45", label: "45 - Poliomielita acuta" },
  { value: "46", label: "46 - Rabia" },
  { value: "47", label: "47 - Encefalita si meningita virala" },
  { value: "48", label: "48 - Alte infectii virale ale sistemului nervos" },
  { value: "49", label: "49 - Varicela" },
  { value: "50", label: "50 - Zona (herpes zoster)" },
  { value: "51", label: "51 - Rujeola" },
  { value: "52", label: "52 - Rubeola" },
  {
    value: "53",
    label: "53 - Alte infectii virale cu leziuni cutaneo-mucoase",
  },
  { value: "54", label: "54 - Hepatita virala A" },
  { value: "55", label: "55 - Hepatita acuta B" },
  { value: "56", label: "56 - Alte hepatite virale" },
  {
    value: "57",
    label:
      "57 - Imunodeficienta umana virala (VIH) cu unele boli infectioase si parazitare",
  },
  {
    value: "58",
    label: "58 - Imunodeficienta umana virala (VIH) cu tumori maligne",
  },
  {
    value: "59",
    label: "59 - Imunodeficienta virala umana (VIH) cu alte boli specificate",
  },
  { value: "60", label: "60 - Imunodeficienta umana (VIH) in alte afectiuni" },
  { value: "61", label: "61 - Imunodeficienta umana (VIH) fara precizare" },
  { value: "62", label: "62 - Oreionul" },
  { value: "63", label: "63 - Mononucleoza infectioasa" },
  { value: "64", label: "64 - Alte boli cu virus, neclasate la alte locuri" },
  { value: "65", label: "65 - Dermatofitoze" },
  { value: "66", label: "66 - Candidiaza" },
  { value: "67", label: "67 - Alte micoze" },
  { value: "68", label: "68 - Malaria" },
  { value: "69", label: "69 - Toxoplasmoza" },
  { value: "70", label: "70 - Alte boli prin protozoare" },
  { value: "71", label: "71 - Echinococoza" },
  { value: "72", label: "72 - Teniaza" },
  { value: "73", label: "73 - Trichineloza" },
  { value: "74", label: "74 - Ascaridiaza" },
  { value: "75", label: "75 - Oxiuraza" },
  { value: "76", label: "76 - Alte helmintiaze" },
  { value: "77", label: "77 - Scabia" },
  { value: "78", label: "78 - Alte infestatii (inclusiv pediculoza)" },
  { value: "79", label: "79 - Boli infectioase, alte si neprecizate" },
  { value: "80", label: "80 - Tumora maligna a buzei" },
  { value: "81", label: "81 - Tumora maligna a bazei limbii" },
  { value: "82", label: "82 - Tumora maligna a limbii, alte si neprecizate" },
  { value: "83", label: "83 - Tumora maligna a gingiei" },
  { value: "84", label: "84 - Tumora maligna a planseului gurii" },
  { value: "85", label: "85 - Tumora maligna a palatului" },
  { value: "86", label: "86 - Tumora maligna a gurii, alte si fara precizare" },
  { value: "87", label: "87 - Tumora maligna a glandei parotide" },
  {
    value: "88",
    label:
      '88 - Tumora maligna a glandelor salivare principal - " altele si fara precizare',
  },
  { value: "89", label: "89 - Tumora maligna a amigdalei" },
  { value: "90", label: "90 - Tumora maligna a orofaringelui" },
  { value: "91", label: "91 - Tumora maligna a rinofaringelui" },
  { value: "92", label: "92 - Tumora maligna a sinusului piriform" },
  { value: "93", label: "93 - Tumora maligna a hipofaringelui" },
  {
    value: "94",
    label:
      "94 - Tumora maligna a buzei, cavitatii bucale si a faringelui cu alte localizari si rau definite",
  },
  { value: "95", label: "95 - Tumori maligne ale esofagului" },
  { value: "96", label: "96 - Tumori maligne ale stomacului" },
  { value: "97", label: "97 - Tumora maligna a intestinului subtire" },
  { value: "98", label: "98 - Tumora maligna a colonului" },
  { value: "99", label: "99 - Tumora maligna a jonctiunii rectosigmoidiene" },
  { value: "100", label: "100 - Tumora maligna a rectului" },
  { value: "101", label: "101 - Tumora maligna a anusului si canalului anal" },
  {
    value: "102",
    label:
      "102 - Tumora maligna a ficatului si canalelor biliare intrahepatice",
  },
  { value: "103", label: "103 - Tumora maligna a veziculei biliare" },
  {
    value: "104",
    label: "104 - Tumori maligne ale cailor biliare, alte si neprecizate",
  },
  { value: "105", label: "105 - Tumori maligne ale pancreasului" },
  {
    value: "106",
    label:
      "106 - Tumora maligna a organelor digestive cu alta localizare si rau definita",
  },
  {
    value: "107",
    label: "107 - Tumora maligna a foselor nazale si a urechii medii",
  },
  { value: "108", label: "108 - Tumora maligna a sinusurilor fetei" },
  { value: "109", label: "109 - Tumori maligne ale laringelui" },
  { value: "110", label: "110 - Tumora maligna a traheei" },
  { value: "111", label: "111 - Tumora maligna a bronhiilor si pulmonului" },
  { value: "112", label: "112 - Tumora maligna a timusului" },
  {
    value: "113",
    label: "113 - Tumora maligna a inimii, mediastinului si pleurei",
  },
  {
    value: "114",
    label:
      "114 - Tumora maligna a aparatului respirator si a organelor intratoracice cu alte localizari si rau definite",
  },
  {
    value: "115",
    label:
      "115 - Tumora maligna a oaselor si cartilagiilor articulare ale membrelor",
  },
  {
    value: "116",
    label:
      "116 - Tumori maligne ale oaselor si cartilagiilor articulare, cu alte localizari si neprecizate",
  },
  { value: "117", label: "117 - Melanomul malign al pielii" },
  { value: "118", label: "118 - Alte tumori maligne ale pielii" },
  { value: "119", label: "119 - Mesotheliomul" },
  { value: "120", label: "120 - Sarcomul Kaposi" },
  {
    value: "121",
    label:
      "121 - Tumora maligna a nervilor periferici si a sistemului nervos autonom",
  },
  {
    value: "122",
    label: "122 - Tumora maligna a retroperitoneului si a peritonelui",
  },
  {
    value: "123",
    label:
      "123 - Tumora maligna a tesutului conjunctiv si a altor tesuturi moi",
  },
  { value: "124", label: "124 - Tumori maligne ale sinului" },
  { value: "125", label: "125 - Tumora maligna a vulvei" },
  { value: "126", label: "126 - Tumora maligna a vaginului" },
  { value: "127", label: "127 - Tumora maligna a colului uterin" },
  { value: "128", label: "128 - Tumora maligna a corpului uterin" },
  {
    value: "129",
    label: "129 - Tumora maligna a uterului, localizare neprecizata",
  },
  { value: "130", label: "130 - Tumora maligna a ovarului" },
  {
    value: "131",
    label:
      "131 - Tumora maligna a organelor genitale la femeie, alte si neprecizate",
  },
  { value: "132", label: "132 - Tumora maligna a placentei" },
  { value: "133", label: "133 - Tumora maligna a penisului" },
  { value: "134", label: "134 - Tumora maligna a prostatei" },
  { value: "135", label: "135 - Tumora maligna a testicolului" },
  {
    value: "136",
    label:
      "136 - Tumori maligne ale organelor genitale la barbat, alte si neprecizate",
  },
  {
    value: "137",
    label: "137 - Tumora maligna a rinichiului, cu exceptia bazinetului renal",
  },
  { value: "138", label: "138 - Tumora maligna a bazinetului renal" },
  { value: "139", label: "139 - Tumora maligna a ureterului" },
  { value: "140", label: "140 - Tumora maligna a vezicii urinare" },
  {
    value: "141",
    label: "141 - Tumora maligna a organelor urinare, alte si neprecizate",
  },
  { value: "142", label: "142 - Tumora maligna a ochiului si anexelor sale" },
  { value: "143", label: "143 - Tumora maligna a meningelui" },
  { value: "144", label: "144 - Tumora maligna a creierului" },
  {
    value: "145",
    label:
      "145 - Tumora maligna a maduvei spinarii, a nervilor cranieni si alte parti ale sistemului nervos central",
  },
  { value: "146", label: "146 - Tumora maligna a tiroidei" },
  { value: "147", label: "147 - Tumora maligna asuprarenalei" },
  {
    value: "148",
    label:
      "148 - Tumora maligna a altor glande endocrine si structuri inrudite",
  },
  {
    value: "149",
    label: "149 - Tumora maligna cu alte localizari si nespecificate",
  },
  {
    value: "150",
    label:
      "150 - Tumora maligna a ganglionilor limfatici, secundara si neprecizata",
  },
  {
    value: "151",
    label:
      "151 - Tumora maligna secundara a organelor respiratorii si digestive",
  },
  { value: "152", label: "152 - Tumora maligna secundara cu alte localizari" },
  { value: "153", label: "153 - Tumori maligne cu sediu neprecizat" },
  { value: "154", label: "154 - Boala Hodgkin" },
  { value: "155", label: "155 - Limfom folicular (nodular) non-Hodgkin" },
  { value: "156", label: "156 - Limfom difuz non-Hodgkin" },
  { value: "157", label: "157 - Limfoame periferice si cutanate cu celule T" },
  {
    value: "158",
    label: "158 - Limfom non-Hodgkin, de alte tipuri si neprecizate",
  },
  { value: "159", label: "159 - Boli imunoproliferative maligne" },
  {
    value: "160",
    label: "160 - Mielom multiplu si tumori maligne cu plasmocite",
  },
  { value: "161", label: "161 - Leucemia limfoida" },
  { value: "162", label: "162 - Leucemia mieloida" },
  { value: "163", label: "163 - Leucemia monocitara" },
  { value: "164", label: "164 - Alte leucemii cu celule precizate" },
  { value: "165", label: "165 - Leucemia cu celule neprecizate" },
  {
    value: "166",
    label:
      "166 - Tumori maligne ale tesutului limfoid, hematopoietic si inrudite, alte si neprecizate",
  },
  {
    value: "167",
    label: "167 - Tumori maligne cu localizari multiple independente (primare)",
  },
  {
    value: "168",
    label:
      "168 - Carcinom in situ al cavitatii bucale, al esofagului si stomacului",
  },
  {
    value: "169",
    label: "169 - Carcinom in situ al organelor digestive, alte si neprecizate",
  },
  {
    value: "170",
    label:
      "170 - Carcinom in situ al urechii mijlocii si al aparatului respirator",
  },
  { value: "171", label: "171 - Melanom in situ" },
  { value: "172", label: "172 - Carcinom in situ al pielii" },
  { value: "173", label: "173 - Carcinom in situ al sinului" },
  { value: "174", label: "174 - Carcinom in situ al colului uterin" },
  {
    value: "175",
    label: "175 - Carcinom in situ al organelor genitale, alte si neprecizate",
  },
  {
    value: "176",
    label: "176 - Carcinom in situ cu alte localizari si neprecizate",
  },
  {
    value: "177",
    label:
      "177 - Tumori benigne cu alte localizari si rau definite ale aparatului digestiv",
  },
  {
    value: "178",
    label: "178 - Tumora benigna a urechii mijlocii si a aparatului respirator",
  },
  {
    value: "179",
    label: "179 - Tumora benigna a organelor intratoracice alte si neprecizate",
  },
  {
    value: "180",
    label: "180 - Tumora benigna a osului si a cartilajului articular",
  },
  { value: "181", label: "181 - Tumora lipomatoasa benigna" },
  { value: "182", label: "182 - Hemangiom si limfangiom, toate localizarile" },
  { value: "183", label: "183 - Tumori benigne ale pielii" },
  { value: "184", label: "184 - Tumora benigna a sinului" },
  { value: "185", label: "185 - Leiomiomul uterului" },
  { value: "186", label: "186 - Alte tumori benigne ale uterului" },
  { value: "187", label: "187 - Tumora benigna a ovarului" },
  {
    value: "188",
    label:
      "188 - Tumora benigna a organelor genitale la femeie, alte si neprecizate",
  },
  { value: "189", label: "189 - Tumora benigna a organelor urinare" },
  {
    value: "190",
    label:
      "190 - Tumora benigna a creierului si altor portiuni ale sistemului nervos central",
  },
  {
    value: "191",
    label: "191 - Tumora benigna a glandelor endocrine, alte si neprecizate",
  },
  {
    value: "192",
    label: "192 - Tumora benigna cu alte localizari si fara precizare",
  },
  {
    value: "193",
    label:
      "193 - Tumora cavitatii bucale si a organelor digestive, cu evolutie imprevizibila si necunoscuta",
  },
  {
    value: "194",
    label:
      "194 - Tumora urechii mijlocii, a organelor respiratorii si intratoracice, cu evolutie imprevizibila si necunoscuta",
  },
  {
    value: "195",
    label:
      "195 - Tumora organelor genitale la femeie, cu evolutie imprevizibila si necunoscuta",
  },
  {
    value: "196",
    label:
      "196 - Tumora organelor genitale la barbat, cu evolutie imprevizibila si necunoscuta",
  },
  {
    value: "197",
    label:
      "197 - Tumora organelor urinare, cu evolutie imprevizibila si necunoscuta",
  },
  {
    value: "198",
    label:
      "198 - Tumora creierului si sistemului nervos central, cu evolutie imprevizibila si necunoscuta",
  },
  {
    value: "199",
    label:
      "199 - Tumora glandelor endocrine, cu evolutie imprevizibila si necunoscuta",
  },
  { value: "200", label: "200 - Poliglobulia esentiala" },
  {
    value: "201",
    label:
      "201 - Alte tumori de tesut limfoid, hematopoietic si inrudite, cu evolutie imprevizibila si necunoscuta",
  },
  {
    value: "202",
    label:
      "202 - Tumora cu alte localizari si neprecizata, cu evolutie imprevizibila si necunoscuta",
  },
  { value: "203", label: "203 - Anemia prin carenta de fier" },
  { value: "204", label: "204 - Anemia prin carenta de vitamina B12" },
  { value: "205", label: "205 - Anemia prin carenta de acid folic" },
  { value: "206", label: "206 - Alte anemii de nutritie" },
  { value: "207", label: "207 - Anemia datorita tulburarilor enzimatice" },
  { value: "208", label: "208 - Thalassemia" },
  {
    value: "209",
    label: "209 - Afectiuni cu hematii falciforme (drepanocitoze)",
  },
  { value: "210", label: "210 - Alte anemii hemolitice ereditare" },
  { value: "211", label: "211 - Anemia hemolitica dobandita" },
  {
    value: "212",
    label: "212 - Aplazia medulara dobandita pura (eritroblastopenia)",
  },
  { value: "213", label: "213 - Alte aplazii medulare" },
  { value: "214", label: "214 - Anemia posthemoragica acuta" },
  { value: "215", label: "215 - Alte anemii" },
  {
    value: "216",
    label:
      "216 - Coagularea intravasculara diseminata (sindromul de defibrinare)",
  },
  { value: "217", label: "217 - Carenta ereditara in factorul VIII" },
  { value: "218", label: "218 - Carenta ereditara in factorul IX" },
  { value: "219", label: "219 - Alte anomalii de coagulare" },
  { value: "220", label: "220 - Purpura si alte afectiuni hemoragice" },
  { value: "221", label: "221 - Agranulocitoza" },
  {
    value: "222",
    label: "222 - Tulburari functionale ale polinuclearelor neutrofile",
  },
  { value: "223", label: "223 - Alte anomalii ale leucocitelor" },
  { value: "224", label: "224 - Bolile splinei" },
  {
    value: "225",
    label: "225 - Alte boli ale sangelui si ale organelor hematopoietice",
  },
  {
    value: "226",
    label:
      "226 - Unele boli ale tesutului limforeticular si ale sistemului reticulo-histocitar",
  },
  {
    value: "227",
    label: "227 - Imunodeficienta cu predominarea anomaliilor de anticorpi",
  },
  { value: "228", label: "228 - Imunodeficiente combinate" },
  {
    value: "229",
    label: "229 - Deficit imunitar asociat altor anomalii majore",
  },
  { value: "230", label: "230 - Imunodeficienta comuna variabila" },
  { value: "231", label: "231 - Alte imunodeficiente" },
  { value: "232", label: "232 - Sarcoidoza" },
  {
    value: "233",
    label:
      "233 - Alte tulburari implicand sistemul imunitar, neclasificate la alte locuri",
  },
  { value: "234", label: "234 - Sindromul insuficientei tiroide congenitale" },
  {
    value: "235",
    label: "235 - Afectiuni tiroidiene si inrudite, legate de carenta in iod",
  },
  { value: "236", label: "236 - Hipotiroidia prin carenta subclinica de iod" },
  { value: "237", label: "237 - Alte guse netoxice" },
  { value: "238", label: "238 - Tireotoxicoza (hipertiroidia)" },
  { value: "239", label: "239 - Tiroidita" },
  { value: "240", label: "240 - Alte afectiuni ale tiroidei" },
  { value: "241", label: "241 - Diabetul zaharat insulino-dependent" },
  { value: "242", label: "242 - Diabetul zaharat neinsulino-dependent" },
  { value: "243", label: "243 - Diabetul zaharat de malnutritie" },
  { value: "244", label: "244 - Alte forme de diabet zaharat" },
  { value: "245", label: "245 - Diabet zaharat fara precizare" },
  { value: "246", label: "246 - Coma hipoglicemica non-diabetica" },
  {
    value: "247",
    label: "247 - Alte tulburari ale secretiei pancreatice interne",
  },
  { value: "248", label: "248 - Hipoparatiroidia" },
  {
    value: "249",
    label: "249 - Hipoparatiroidia si alte boli ale glandei paratiroide",
  },
  { value: "250", label: "250 - Hipersecretia glandei hipofizare (pituitara)" },
  {
    value: "251",
    label: "251 - Hiposecretia si alte tulburari ale glandei hipofizare",
  },
  { value: "252", label: "252 - Sindromul Cushing" },
  { value: "253", label: "253 - Tulburari adreno-genitale" },
  { value: "254", label: "254 - Hiperaldosteronismul" },
  { value: "255", label: "255 - Alte tulburari ale glandei suprarenale" },
  { value: "256", label: "256 - Disfunctia ovariana" },
  { value: "257", label: "257 - Disfunctia testiculara" },
  {
    value: "258",
    label: "258 - Tulburarile pubertatii neclasate la alte locuri",
  },
  { value: "259", label: "259 - Disfunctiuni pluriglandurale" },
  { value: "260", label: "260 - Bolile timusului" },
  { value: "261", label: "261 - Alte tulburari endocrine" },
  {
    value: "262",
    label: "262 - Malnutritia proteino-energetica grava fara precizare",
  },
  {
    value: "263",
    label: "263 - Malnutritia proteino-energetica usoara sau moderata",
  },
  {
    value: "264",
    label:
      "264 - Intarzierea de dezvoltare datorita malnutritiei proteino-energetice",
  },
  {
    value: "265",
    label: "265 - Malnutritia proteino-energetica, fara precizare",
  },
  { value: "266", label: "266 - Avitaminoza A" },
  { value: "267", label: "267 - Carenta de tiamina" },
  { value: "268", label: "268 - Carenta in acid nicotinic (pelagra)" },
  { value: "269", label: "269 - Alte avitaminoze din grupa B" },
  { value: "270", label: "270 - Carenta de acid ascorbic" },
  { value: "271", label: "271 - Rahitismul evolutiv" },
  { value: "272", label: "272 - Carenta in vitamina D fara precizare" },
  { value: "273", label: "273 - Alte avitaminoze" },
  { value: "274", label: "274 - Carenta alimentara in calciu" },
  { value: "275", label: "275 - Carenta alimentara in seleniu" },
  { value: "276", label: "276 - Carenta in zinc" },
  { value: "277", label: "277 - Carenta in alte elemente nutritionale" },
  { value: "278", label: "278 - Alte carente nutritionale" },
  { value: "279", label: "279 - Obezitatea" },
  { value: "280", label: "280 - Alte excese de aport" },
  { value: "281", label: "281 - Fenilcetonuria" },
  {
    value: "282",
    label: "282 - Tulburari de metabolism ale acizilor aminati aromatici",
  },
  {
    value: "283",
    label: "283 - Alte tulburari de metabolism ale acizilor aminati",
  },
  { value: "284", label: "284 - Intoleranta la lactoza" },
  {
    value: "285",
    label: "285 - Alte tulburari de metabolism al hidratilor de carbon",
  },
  {
    value: "286",
    label:
      "286 - Tulburari de metabolism al sfingolipidelor si al altor lipide de rezerva",
  },
  {
    value: "287",
    label: "287 - Tulburari de metabolism al glicosiaminoglicani",
  },
  { value: "288", label: "288 - Tulburari de metabolism al glicoproteinelor" },
  {
    value: "289",
    label: "289 - Tulburari de metabolism al lipoproteinelor si alte lipidemii",
  },
  {
    value: "290",
    label: "290 - Tulburari de metabolism al purinelor si al pirimidinei",
  },
  {
    value: "291",
    label: "291 - Tulburari de metabolism al porfirinei si bilirubinei",
  },
  { value: "292", label: "292 - Tulburari de metabolism al mineralelor" },
  { value: "293", label: "293 - Fibroza chistica" },
  { value: "294", label: "294 - Amiloidoza" },
  { value: "295", label: "295 - Hipovolemia deshidratara" },
  {
    value: "296",
    label:
      "296 - Alte tulburari de echilibru acido-bazic hidric si electrolitic",
  },
  { value: "297", label: "297 - Alte tulburari de metabolism" },
  {
    value: "298",
    label:
      "298 - Tulburari metabolice si endocrine dupa o prestatie medicala in vederea diagnosticului si tratamentului, neclasate in alte locuri",
  },
  { value: "299", label: "299 - Dementa vasculara" },
  { value: "300", label: "300 - Dementa fara precizare (presenila, senila)" },
  {
    value: "301",
    label:
      "301 - S+A2060indrom amnezic organic, neindus de alcool si alte substante psiho-afective",
  },
  {
    value: "302",
    label: "302 - Delir neindus de alcool si alte substante psihoactive",
  },
  {
    value: "303",
    label:
      "303 - Alte tulburari mentale datorate unei leziuni cerebrale, unei disfunctii cerebrale si unei afectiuni somatice",
  },
  {
    value: "304",
    label:
      "304 - Tulburari ale personalitatii si comportamentului datorate unei afectiuni, leziuni si disfunctii cerebrale",
  },
  {
    value: "305",
    label: "305 - Tulburare mentala organica sau simptomatica, fara precizare",
  },
  {
    value: "306",
    label:
      "306 - Tulburari mentale si de comportament legate de consumul de alcool",
  },
  {
    value: "307",
    label:
      "307 - Tulburari mentale si de comportament legate de consumul opiaceelor si derivatelor de cannabis, cocaina si halucinogene",
  },
  {
    value: "308",
    label:
      "308 - Tulburari mentale si de comportament legate de consumul sedativelor sau hipnoticelor",
  },
  {
    value: "309",
    label:
      "309 - Tulburari mentale si de comportament legate de consumul altor stimulente, inclusiv cofeina",
  },
  {
    value: "310",
    label:
      "310 - Tulburari mentale si de comportament legate de consumul tutunului",
  },
  {
    value: "311",
    label:
      "311 - Tulburari mentale si de comportament legate de consumul solventilor volatili",
  },
  { value: "312", label: "312 - Schizofrenia" },
  { value: "313", label: "313 - Tulburare schizotipala" },
  { value: "314", label: "314 - Tulburari delirante persistente" },
  { value: "315", label: "315 - Tulburari psihotice acute si tranzitorii" },
  { value: "316", label: "316 - Tulburare deliranta indusa" },
  { value: "317", label: "317 - Tulburari schizoafective" },
  { value: "318", label: "318 - Alte tulburari psihotice neorganice" },
  { value: "319", label: "319 - Episod maniacal" },
  { value: "320", label: "320 - Tulburare afectiva bipolara" },
  { value: "321", label: "321 - Episod depresiv" },
  { value: "322", label: "322 - Tulburari ale dispozitiei afectivitatii" },
  { value: "323", label: "323 - Alte tulburari ale dispozitiei afectivitatii" },
  {
    value: "324",
    label: "324 - Tulburari ale dispoziitei afectivitatii fara precizare",
  },
  { value: "325", label: "325 - Tulburari anxioase" },
  { value: "326", label: "326 - Tulburare obsesionala-compulsiva" },
  {
    value: "327",
    label:
      "327 - Reactia la un factor de stress important si tulburari de adaptare",
  },
  { value: "328", label: "328 - Tulburari disociative (de conversie)" },
  { value: "329", label: "329 - Tulburari somatice" },
  { value: "330", label: "330 - Alte tulburari nevrotice" },
  { value: "331", label: "331 - Tulburari de alimentatie" },
  { value: "332", label: "332 - Tulburari de somn neorganice" },
  {
    value: "333",
    label:
      "333 - Disfunctie sexuala, nedatorata unei tulburari sau boli organice",
  },
  {
    value: "334",
    label:
      "334 - Tulburari mentale si de comportament asociate cu puerperiu, necalsate la alte locuri",
  },
  {
    value: "335",
    label:
      "335 - Factori psihologici si comportamentali asociati bolilor sau tulburari clasate la alte locuri",
  },
  { value: "336", label: "336 - Abuz de substanta nedeterminand dependenta" },
  {
    value: "337",
    label:
      "337 - sindroame comportamentale neprecizate asociate perturbarilor fiziologice si factorilor fizici",
  },
  { value: "338", label: "338 - Tulburari specifice ale personalitatii" },
  {
    value: "339",
    label: "339 - Tulburari de identitate sexuala si de preferinta sexuala",
  },
  {
    value: "340",
    label:
      "340 - Probleme psihologice si comportamentale asociate dezvoltarii sexuale si orientarii",
  },
  {
    value: "341",
    label:
      "341 - Alte tulburari ale personalitati si a comportamentului la adulti",
  },
  { value: "342", label: "342 - intarziere mentala uSoara" },
  { value: "343", label: "343 - intarziere mentala medie" },
  { value: "344", label: "344 - intarziere mentala grava" },
  { value: "345", label: "345 - intarziere mentala profunda" },
  {
    value: "346",
    label: "346 - Alte forme de intarziere mentala si fara precizare",
  },
  {
    value: "347",
    label:
      "347 - Tulburari specifice de dezvoltarea vorbirii si limbajului, achizitiilor scolare",
  },
  { value: "348", label: "348 - Tulburari specifice de dezvoltare motorie" },
  {
    value: "349",
    label: "349 - Alte tulburari ale dezvoltarii psihologice si fara precizare",
  },
  { value: "350", label: "350 - Tulburari hiperchinetice" },
  { value: "351", label: "351 - Tulburari de conduita" },
  {
    value: "352",
    label: "352 - Tulburari emotionale cu aparitia in special in copilarie",
  },
  {
    value: "353",
    label:
      "353 - Tulburari ale functiei sociale, cu aparitia specifica in perioada copilariei si adolescentei",
  },
  {
    value: "354",
    label:
      "354 - Alte tulburari de comportament si tulburari emotionale aparand de obicei in perioada copilariei si adolescentei",
  },
  { value: "355", label: "355 - Tulburare mentala fara alte indicatii" },
  {
    value: "356",
    label: "356 - Meningita bacteriana, neclasata in alte locuri",
  },
  {
    value: "357",
    label: "357 - Meningita datorita altor cauze si neprecizate",
  },
  { value: "358", label: "358 - Encefalita, mielita, encefalomielita" },
  {
    value: "359",
    label: "359 - Abces si granulom intracranian si intrarahidian",
  },
  {
    value: "360",
    label: "360 - Flebita si tromboflebita intracraniana si intrarahidiana",
  },
  { value: "361", label: "361 - Coreea Huntington" },
  { value: "362", label: "362 - Ataxia ereditara" },
  { value: "363", label: "363 - Amiotrofia spinala si sindroame inrudite" },
  { value: "364", label: "364 - Boala Parkinson" },
  { value: "365", label: "365 - Sindrom parkinsonian secundar" },
  {
    value: "366",
    label: "366 - Alte boli degenerative ale nucleilor centrali cenusii",
  },
  {
    value: "367",
    label: "367 - Alte sindroame extrapiramidale si tulburari de motricitate",
  },
  { value: "368", label: "368 - Boala Alzheimer" },
  {
    value: "369",
    label:
      "369 - Alte afectiuni degenerative ale sistemului nervos, neclasate la alte locuri",
  },
  { value: "370", label: "370 - Scleroza multipla" },
  {
    value: "371",
    label: "371 - Alte afectiuni demielinizante acute diseminate",
  },
  {
    value: "372",
    label: "372 - Alte afectiuni demielinizante ale sistemului nervos central",
  },
  { value: "373", label: "373 - Epilepsia" },
  { value: "374", label: "374 - Stare de rau epileptic" },
  { value: "375", label: "375 - sindroame de algii cefalice" },
  {
    value: "376",
    label:
      "376 - Accidente ischemice cerebrale tranzitorii si sindroame inrudite",
  },
  { value: "377", label: "377 - Tulburari de somn" },
  { value: "378", label: "378 - Afectiunile nervilor cranieni" },
  {
    value: "379",
    label: "379 - Afectiunile radacinilor si plexurilor nervoase",
  },
  { value: "380", label: "380 - Mononevrite" },
  { value: "381", label: "381 - Neuropatia ereditara si idiopatica" },
  { value: "382", label: "382 - Polinevrite inflamatorii" },
  { value: "383", label: "383 - Alte polinevrite" },
  {
    value: "384",
    label: "384 - Alte afectiuni ale sistemului nervos periferic",
  },
  { value: "385", label: "385 - Miastenia si alte afectiuni neuromusculare" },
  {
    value: "386",
    label: "386 - Afectiuni musculare primitive (Distrofia musculara Duchenne)",
  },
  { value: "387", label: "387 - Alte afectiuni musculare" },
  { value: "388", label: "388 - Paralizia cerebrala infantila" },
  { value: "389", label: "389 - Hemiplegia" },
  { value: "390", label: "390 - Paraplegia, tetraplegia" },
  { value: "391", label: "391 - Alte sindroame paralitice" },
  { value: "392", label: "392 - Afectiuni ale sistemului nervos autonom" },
  { value: "393", label: "393 - Hidrocefalia" },
  {
    value: "394",
    label: "394 - Encefalopatia toxica si alte afectiuni ale creierului",
  },
  { value: "395", label: "395 - Alte afectiuni ale maduvei spinarii" },
  { value: "396", label: "396 - Alte afectiuni ale sistemului nervos central" },
  {
    value: "397",
    label:
      "397 - Afectiunile sistemului nervos dupa un act medical in vederea diagnosticului si tratamentului neclasate la alte locuri",
  },
  { value: "398", label: "398 - Orgelet si chalazion" },
  { value: "399", label: "399 - Alte afectiuni ale pleoapei" },
  { value: "400", label: "400 - Afectiuni ale aparatului lacrimal" },
  { value: "401", label: "401 - Afectiuni ale orbitei" },
  { value: "402", label: "402 - Conjunctivita" },
  { value: "403", label: "403 - Alte afectiuni ale conjunctivei" },
  { value: "404", label: "404 - Afectiuni ale scleroticei" },
  { value: "405", label: "405 - Cheratita" },
  {
    value: "406",
    label: "406 - Cicatrice, opacitati si alte afectiuni ale corneei",
  },
  { value: "407", label: "407 - Iridociclita" },
  {
    value: "408",
    label: "408 - Alte afectiuni ale irisului si corpilor ciliari",
  },
  { value: "409", label: "409 - Cataracta senila" },
  { value: "410", label: "410 - Alte cataracte" },
  { value: "411", label: "411 - Alte afectiuni ale cristalinului" },
  {
    value: "412",
    label: "412 - Chorioretinita si alte afectiuni ale choroidei",
  },
  { value: "413", label: "413 - Dezlipirea si ruptura retinei" },
  { value: "414", label: "414 - Alte afectiuni retiniene" },
  { value: "415", label: "415 - Glaucomul" },
  { value: "416", label: "416 - Afectiuni ale corpului vitros" },
  { value: "417", label: "417 - Afectiuni ale globului ocular" },
  { value: "418", label: "418 - Nevrita optica" },
  { value: "419", label: "419 - Alte afectiuni ale nervului si cailor optice" },
  { value: "420", label: "420 - Strabism paralitic" },
  { value: "421", label: "421 - Alte anomalii de miscare binoculara" },
  { value: "422", label: "422 - Vicii de refractie si tulburari de acomodare" },
  { value: "423", label: "423 - Tulburari de vedere" },
  { value: "424", label: "424 - Cecitatea si scaderea vazului" },
  { value: "425", label: "425 - Nistagmus si alte miscari anormale" },
  { value: "426", label: "426 - Alte afectiuni ale ochiului si anexelor sale" },
  {
    value: "427",
    label:
      "427 - Afectiuni ale ochiului si anexelor sale in vederea dg si trat. neclasate la alte locuri",
  },
  { value: "428", label: "428 - Otita externa" },
  { value: "429", label: "429 - Alte afectiuni ale urechii externe" },
  { value: "430", label: "430 - Otita medie nesupurata" },
  { value: "431", label: "431 - Otita medie supurativa fara precizare" },
  {
    value: "432",
    label:
      "432 - Inflamatia, obstructia si alte afectiuni ale trompei Eustachio",
  },
  { value: "433", label: "433 - Mastoidita si afectiuni asociate" },
  { value: "434", label: "434 - Colesteatomul urechii medii" },
  { value: "435", label: "435 - Perforatia si alte afectiuni ale timpanului" },
  {
    value: "436",
    label: "436 - Alte afectiuni ale urechii medii si apofizei mastoide",
  },
  { value: "437", label: "437 - Otoscleroza" },
  {
    value: "438",
    label: "438 - Afectiunile functiei vestibulare si sindroame de vertij",
  },
  { value: "439", label: "439 - Alte boli ale urechii interne" },
  { value: "440", label: "440 - Surditate de transmisie si neurosenzoriala" },
  { value: "441", label: "441 - Alte pierderi ale auzului si surdomutitatea" },
  { value: "442", label: "442 - Otalgia si otoreea" },
  {
    value: "443",
    label: "443 - Alte afectiuni ale urechii, neclasate la alte locuri",
  },
  {
    value: "444",
    label:
      "444 - Afectiuni dupa un act medical de dg. si trat. la nivelul urechii si apof. Mastoide, neclasate la alte locuri",
  },
  {
    value: "445",
    label:
      "445 - Reumatismul articular acut fara mentionarea complicatiilor cardiace",
  },
  {
    value: "446",
    label: "446 - Reumatismul articular acut cu complicatii cardiace",
  },
  { value: "447", label: "447 - Coreeea reumatismala" },
  { value: "448", label: "448 - Boli ale valuevulei mitrale, reumatismale" },
  { value: "449", label: "449 - Boli ale valuevulei aortice, reumatismale" },
  { value: "450", label: "450 - Boli ale valuevulei tricuspide, reumatismale" },
  { value: "451", label: "451 - Boli multiple valuevulare" },
  { value: "452", label: "452 - Alte boli reumatice ale inimii" },
  { value: "453", label: "453 - Hipertensiunea esentiala (primara)" },
  { value: "454", label: "454 - Cardiopatia hipertensiva" },
  { value: "455", label: "455 - Nefropatia hipertensiva" },
  { value: "456", label: "456 - Cario-nefropatia hipertensiva" },
  { value: "457", label: "457 - Hipertensiunea secundar_" },
  { value: "458", label: "458 - Angina pectorala" },
  { value: "459", label: "459 - Infarct miocardic acut" },
  { value: "460", label: "460 - Infarct miocardic ulterior" },
  { value: "461", label: "461 - Alte boli ischemice acute cardiace" },
  { value: "462", label: "462 - Cardiopatie ischemica cronica" },
  { value: "463", label: "463 - Embolia vaselor pulmonare" },
  { value: "464", label: "464 - Alte cardiopatii pulmonare" },
  { value: "465", label: "465 - Cord pulmonar cronic" },
  { value: "466", label: "466 - Alte boli ale vaselor pulmonare" },
  { value: "467", label: "467 - Pericardita acuta" },
  { value: "468", label: "468 - Alte boli ale pericardului" },
  { value: "469", label: "469 - Endocardita acuta si subacuta" },
  { value: "470", label: "470 - Afectiuni nereumatice ale valuevei mitrale" },
  { value: "471", label: "471 - Afectiuni nereumatice ale valuevei aortice" },
  {
    value: "472",
    label: "472 - Afectiuni nereumatice ale valuevei tricuspide",
  },
  { value: "473", label: "473 - Afectiunile valuevei pulmonare" },
  { value: "474", label: "474 - Endocardita, valuevula nespecificata" },
  { value: "475", label: "475 - Miocardita acuta" },
  { value: "476", label: "476 - Cardiomiopatia" },
  { value: "477", label: "477 - Hemoragia subarahnoida" },
  {
    value: "478",
    label:
      "478 - Hemoragia intracerebrala si alte hemoragii intracraniene netraumatice",
  },
  { value: "479", label: "479 - Infarct cerebral" },
  {
    value: "480",
    label: "480 - Accident cerebral nespecificat ca hemoragie sau infarct",
  },
  { value: "481", label: "481 - Alte boli cerebrovasculare" },
  { value: "482", label: "482 - Ateroscleroza" },
  { value: "483", label: "483 - Anevrismul si disectia de aorta" },
  { value: "484", label: "484 - Alte anevrisme" },
  { value: "485", label: "485 - Alte boli vasculare periferice" },
  { value: "486", label: "486 - Embolia si tromboza arteriala" },
  { value: "487", label: "487 - Alte afectiuni ale arterelor si arteriolelor" },
  { value: "488", label: "488 - Bolile capilarelor" },
  { value: "489", label: "489 - Flebita si tromboflebita" },
  { value: "490", label: "490 - Embolia si tromboza venoasa" },
  { value: "491", label: "491 - Vene varicoase ale membrelor inferioare" },
  { value: "492", label: "492 - Hemoroizi" },
  {
    value: "493",
    label: "493 - Varice esofagiene si alte afectiuni ale venelor",
  },
  { value: "494", label: "494 - Limfadenita nespecifica, exceptind cea acuta" },
  { value: "495", label: "495 - Hipotensiunea" },
  {
    value: "496",
    label:
      "496 - Afectiuni postchirurgicale sau proceduri pe aparatul circulator FAI",
  },
  {
    value: "497",
    label: "497 - Alte afectiuni nespecificate ale aparatului circulator",
  },
  { value: "498", label: "498 - Rino-faringita acuta" },
  { value: "499", label: "499 - sinuzita acuta" },
  { value: "500", label: "500 - faringita si amigdalita acuta" },
  { value: "501", label: "501 - laringita si traheita acuta" },
  {
    value: "502",
    label: "502 - laringita obstructiva acuta si epiglotita acuta",
  },
  {
    value: "503",
    label:
      "503 - infectii acute ale cailor respiratorii superioare cu localizari multiple si neprecizate",
  },
  { value: "504", label: "504 - gripa, cu virus identificat" },
  { value: "505", label: "505 - gripa, cu virus neidentificat" },
  { value: "506", label: "506 - pneumonia virala, neclasata la alte locuri" },
  { value: "507", label: "507 - pneumonia prin streptococus pneumoniae" },
  { value: "508", label: "508 - pneumonia prin haemophilus influenzae" },
  {
    value: "509",
    label: "509 - pneumonii bacteriene, neclasate la alte locuri",
  },
  {
    value: "510",
    label:
      "510 - pneumonia datorita altor microorganisme infectioase, neclasate la alte locuri",
  },
  { value: "511", label: "511 - Pneumonii cu microorganisme neprecizate" },
  { value: "512", label: "512 - Bronsita si bronsiolita acuta" },
  {
    value: "513",
    label:
      "513 - I+A3359nfectii acute ale cailor respiratorii inferioare fara precizare",
  },
  { value: "514", label: "514 - Rinita alergica si vazomotorie" },
  { value: "515", label: "515 - Rinita, rinofaringita si faringita cronica" },
  { value: "516", label: "516 - Sinuzita cronica" },
  {
    value: "517",
    label: "517 - Bolile cronice ale amigdalelor si vegetatiilor adenoide",
  },
  { value: "518", label: "518 - Angina flegmonoasa" },
  { value: "519", label: "519 - Laringita si laringo-traheita cronica" },
  {
    value: "520",
    label:
      "520 - Bolile corzilor voale si ale laringelui, neclasate la alte locuri",
  },
  { value: "521", label: "521 - Alteboli ale cailor respiratorii superioare" },
  { value: "522", label: "522 - Bronsita neprecizata ca acuta sau cronica" },
  { value: "523", label: "523 - Bronsita cronica simpla si mucopurulenta" },
  { value: "524", label: "524 - Bronsita cronica FAI" },
  { value: "525", label: "525 - Emfizemul" },
  { value: "526", label: "526 - Alte boli pulmonare obstructive cronice" },
  { value: "527", label: "527 - Astmul" },
  { value: "528", label: "528 - Bronsiectazia" },
  { value: "529", label: "529 - Pneumoconioza de carbune a minerilor" },
  {
    value: "530",
    label: "530 - Pneumodonioza datorata azbestului si altor fibre minerale",
  },
  { value: "531", label: "531 - Pneumoconioza datorata pulberii de siliciu" },
  {
    value: "532",
    label:
      "532 - Pneumoconioza datorata altor pulberi neorganice si fara precizare",
  },
  { value: "533", label: "533 - Pneumoconioza asociata cu tuberculoza" },
  {
    value: "534",
    label:
      "534 - Afectiuni ale cailor aeriene datorate pulberilor organice precizate",
  },
  {
    value: "535",
    label: "535 - Afectiuni respiratorii datorate altor agenti externi",
  },
  { value: "536", label: "536 - Edem pulmonar" },
  { value: "537", label: "537 - Alte afectiuni pulmonare interstitiale" },
  { value: "538", label: "538 - Abcesul pulmonar si al mediastinului" },
  { value: "539", label: "539 - Pneumothorax" },
  { value: "540", label: "540 - Afectiuni pleurale" },
  {
    value: "541",
    label:
      "541 - Tulburari respiratorii dupa un act medical privind diagnosticul si tratamentul, neclasate la alte locuri",
  },
  { value: "542", label: "542 - Alte tulburari respiratorii" },
  { value: "543", label: "543 - Tulburari de ontogeneza si de eruptie" },
  { value: "544", label: "544 - Carii dentare" },
  { value: "545", label: "545 - Alte boli ale tesutului dentar dur" },
  {
    value: "546",
    label: "546 - Afectiuni ale gingiei si crestei alveolare edentate",
  },
  { value: "547", label: "547 - Anomalii dento-faciale (inclusiv malocluzia)" },
  { value: "548", label: "548 - Alte boli ale maxilarelor" },
  { value: "549", label: "549 - Bolile glandelor salivare" },
  { value: "550", label: "550 - Stomatitele si afectiunile inrudite" },
  { value: "551", label: "551 - Alte boli ale buzelor si mucoasei bucale" },
  { value: "552", label: "552 - Bolile limbii" },
  { value: "553", label: "553 - Esofagita" },
  { value: "554", label: "554 - Alte boli ale esofagului" },
  { value: "555", label: "555 - Ulcerul gastric" },
  { value: "556", label: "556 - Ulcerul duodenal" },
  { value: "557", label: "557 - Ulcerul peptic cu localizare neprecizata" },
  { value: "558", label: "558 - Ulcerul gastro-jejunal" },
  { value: "559", label: "559 - Gastrita si duodenita" },
  { value: "560", label: "560 - Dispepsia" },
  { value: "561", label: "561 - Alte boli ale stomacului si duodenului" },
  { value: "562", label: "562 - Apendicita acuta" },
  { value: "563", label: "563 - Alte boli ale apendicelui" },
  { value: "564", label: "564 - Hernia inghinala" },
  { value: "565", label: "565 - Hernia diafragmatica" },
  { value: "566", label: "566 - Alte hernii abdominale" },
  { value: "567", label: "567 - Boala Crohn (enterita regionala)" },
  { value: "568", label: "568 - Rectocolita hemoragica (colita ulceroasa)" },
  { value: "569", label: "569 - Alte gastroenterite si colite neinfectioase" },
  { value: "570", label: "570 - Tulburari vasculare ale intestinului" },
  {
    value: "571",
    label:
      "571 - Ileus paralitic si ocluzia intestinala fara mentionarea herniei",
  },
  { value: "572", label: "572 - Diverticuloza intestinului" },
  {
    value: "573",
    label:
      "573 - Sindromul intestinului iritabil si alte tulburari functionale ale intestinului",
  },
  {
    value: "574",
    label: "574 - Fisura, fistula si abcesul regiunilor anale si rectale",
  },
  { value: "575", label: "575 - Alte boli ale intestinului" },
  { value: "576", label: "576 - Peritonita" },
  { value: "577", label: "577 - Alte boli ale peritoneului" },
  { value: "578", label: "578 - Boala alcoolica a ficatului" },
  { value: "579", label: "579 - Boala toxica a ficatului" },
  { value: "580", label: "580 - Hepatita cronica, neclasata la alte locuri" },
  { value: "581", label: "581 - Fibroza si ciroza ficatului" },
  { value: "582", label: "582 - Alte boli inflamatorii ale ficatului" },
  { value: "583", label: "583 - Alte boli ale ficatului" },
  { value: "584", label: "584 - Litiaza biliara" },
  { value: "585", label: "585 - Colecistita" },
  { value: "586", label: "586 - Alte boli ale vezicii si ale cailor biliare" },
  { value: "587", label: "587 - Pancreatita acuta" },
  { value: "588", label: "588 - Alte boli ale pancreasului" },
  { value: "589", label: "589 - Malabsorbtia intestinala" },
  {
    value: "590",
    label:
      "590 - Afectiunile aparatului digestiv dupa un act medical de diagnostic si tratament, neclasate la alte locuri",
  },
  { value: "591", label: "591 - Alte boli ale aparatului digestiv" },
  { value: "592", label: "592 - Impetigo" },
  {
    value: "593",
    label: "593 - Abces cutanat, furuncul si furuncul antracoid, flegmon",
  },
  { value: "594", label: "594 - Limfadenita acuta" },
  { value: "595", label: "595 - Chist dermoid pararectal" },
  {
    value: "596",
    label:
      "596 - Alte infectii localizate ale pielii si tesutului celular subcutanat",
  },
  { value: "597", label: "597 - Pemphigus" },
  { value: "598", label: "598 - Alte dermatoze buloase" },
  { value: "599", label: "599 - Dermita seboreica" },
  { value: "600", label: "600 - Dermita alergica de contact" },
  { value: "601", label: "601 - Lichen simplex cronic si prurigo" },
  { value: "602", label: "602 - Alte dermite" },
  { value: "603", label: "603 - Psoriazis" },
  { value: "604", label: "604 - Lichen plan" },
  { value: "605", label: "605 - Alte leziuni papulo-scuamoase" },
  { value: "606", label: "606 - Urticaria" },
  { value: "607", label: "607 - Eritem polimorf" },
  { value: "608", label: "608 - Eritem nodos" },
  { value: "609", label: "609 - Alte forme de eritem" },
  { value: "610", label: "610 - insolatia" },
  { value: "611", label: "611 - Radiodermite" },
  {
    value: "612",
    label:
      "612 - Alte afectiuni ale pielii si tesutului celular subcutanat legate de o iradiere",
  },
  { value: "613", label: "613 - Bolile unghiei" },
  { value: "614", label: "614 - Alopecia areata" },
  { value: "615", label: "615 - Alte forme de rarefiere a sistemului pilos" },
  { value: "616", label: "616 - Hipertrichoza" },
  { value: "617", label: "617 - Acneea" },
  { value: "618", label: "618 - Anceea rozacee" },
  { value: "619", label: "619 - Afectiuni foliculare" },
  { value: "620", label: "620 - Afectiunile glandelor sudoripare" },
  { value: "621", label: "621 - Vitiligo" },
  { value: "622", label: "622 - Ulceratia de decubitus" },
  { value: "623", label: "623 - Lupus eritematos" },
  {
    value: "624",
    label: "624 - Alte afectiuni localizate ale tesutului conjunctiv",
  },
  {
    value: "625",
    label:
      "625 - Alte afectiuni ale pielii si tesutului celular subcutanat, neclasate la alte locuri",
  },
  { value: "626", label: "626 - Artrita cu bacterii piogene" },
  { value: "627", label: "627 - Artropatia de reactie" },
  { value: "628", label: "628 - Artrita reumatoida" },
  { value: "629", label: "629 - Artrita juvenila" },
  { value: "630", label: "630 - Guta" },
  { value: "631", label: "631 - Alte artrite" },
  { value: "632", label: "632 - Poliartroza" },
  { value: "633", label: "633 - Coxartroza (artroza coapsei)" },
  { value: "634", label: "634 - Gonartroza (artroza genunchiului)" },
  { value: "635", label: "635 - Alte artroze" },
  {
    value: "636",
    label: "636 - Deformatii dobandite ale degetelor mainilor si picioarelor",
  },
  { value: "637", label: "637 - Alte deformatii dobandite ale membrelor" },
  { value: "638", label: "638 - Alte afectiuni articulare precizate" },
  {
    value: "639",
    label: "639 - Alte afectiuni articulare neclasate la alte locuri",
  },
  { value: "640", label: "640 - Poliarterita nodoasa si afectiunile inrudite" },
  { value: "641", label: "641 - Lupus eritematos diseminat" },
  {
    value: "642",
    label: "642 - Alte atingeri sistemice ale tesutului conjunctiv",
  },
  { value: "643", label: "643 - Cifoza si lordoza" },
  { value: "644", label: "644 - Scolioza" },
  { value: "645", label: "645 - Alte dorsopatii deformante" },
  { value: "646", label: "646 - Spondilartrita anchilozanta" },
  { value: "647", label: "647 - Alte spondilopatii inflamatorii" },
  { value: "648", label: "648 - Spondiloza" },
  { value: "649", label: "649 - Alte spondilopatii" },
  { value: "650", label: "650 - Atingeri ale discurilor cervicale" },
  { value: "651", label: "651 - Alte atingeri ale discurilor intervertebrale" },
  { value: "652", label: "652 - Alte dorsopatii, neclasate la alte locuri" },
  { value: "653", label: "653 - Dorsalgii" },
  { value: "654", label: "654 - Miozita" },
  { value: "655", label: "655 - Alte atingeri musculare" },
  { value: "656", label: "656 - Sinovite si tenosinovite" },
  { value: "657", label: "657 - Alte atingeri ale sinovialei si tendonului" },
  {
    value: "658",
    label:
      "658 - Afectiuni ale tesuturilor moi legate de o solicitare excesiva a articulatiei",
  },
  { value: "659", label: "659 - Leziunile umarului" },
  {
    value: "660",
    label: "660 - Alte afectiuni ale tesuturilor moi, neclasate la alte locuri",
  },
  { value: "661", label: "661 - Osteoporoza" },
  { value: "662", label: "662 - Osteomalacia adultului" },
  {
    value: "663",
    label: "663 - Alte atingeri ale densitatii si structurii osoase",
  },
  { value: "664", label: "664 - Osteomielita" },
  { value: "665", label: "665 - Boala osoasa Paget (osteita deformanta)" },
  { value: "666", label: "666 - Alte boli osoase" },
  {
    value: "667",
    label: "667 - Osteochondrita juvenila a soldului si a bazinului",
  },
  { value: "668", label: "668 - Alte afectiuni ale cartilajului" },
  {
    value: "669",
    label:
      "669 - Alte deformatii dobandite ale sistemului osteo-articular si ale muschilor",
  },
  { value: "670", label: "670 - Sindromul nefritic acut" },
  {
    value: "671",
    label: "671 - Sindromul nefritic cu evolutie rapida si progresiva",
  },
  { value: "672", label: "672 - Hematuria recidivanta si persistenta" },
  { value: "673", label: "673 - Sindromul nefritic cronic" },
  { value: "674", label: "674 - Sindromul nefrotic" },
  { value: "675", label: "675 - Sindromul nefritic fara precizare" },
  {
    value: "676",
    label: "676 - Proteinuria izolata cu leziuni morfologice precizate",
  },
  {
    value: "677",
    label: "677 - Nefropatia ereditara, neclasata la alte locuri",
  },
  { value: "678", label: "678 - Nefrita tubulo-interstitiala acuta" },
  { value: "679", label: "679 - Nefrita tubulo-interstitiala cronica" },
  {
    value: "680",
    label:
      "680 - Nefrita tubulo-interstitiala, neprecizata ca acuta sau cronica",
  },
  { value: "681", label: "681 - Uropatia obstructiva si prin reflux" },
  {
    value: "682",
    label:
      "682 - Atingerile tubulo-interstitiale si tubulare datorate medicamentelor si metalelor grele",
  },
  { value: "683", label: "683 - Alte boli renale tubulo-interstitiale" },
  { value: "684", label: "684 - Insuficienta renala acuta" },
  { value: "685", label: "685 - Insuficienta renala cronica" },
  { value: "686", label: "686 - I+A6137nsuficienta renala fara precizare" },
  { value: "687", label: "687 - Litiaza rinichiului si ureterului" },
  { value: "688", label: "688 - Litiaza cailor urinare inferioare" },
  { value: "689", label: "689 - Colica nefritica fara precizare" },
  {
    value: "690",
    label: "690 - Afectiuni datorate unei rele functionari tubulare",
  },
  { value: "691", label: "691 - Rinichi scleros fara precizare" },
  { value: "692", label: "692 - Rinichi mic de cauza necunoscuta" },
  {
    value: "693",
    label:
      "693 - Alte afectiuni ale rinichiului si uterului neclasate la alte locuri",
  },
  { value: "694", label: "694 - Cistita" },
  { value: "695", label: "695 - Disfunctiunea neuro-musculara a vezicii" },
  { value: "696", label: "696 - Alte afectiuni ale vezicii" },
  { value: "697", label: "697 - Uretrita si sindromul ureteral" },
  { value: "698", label: "698 - Strictura uretrala" },
  { value: "699", label: "699 - Alte afectiuni ale uretrei" },
  { value: "700", label: "700 - Alte afectiuni ale aparatului urinar" },
  { value: "701", label: "701 - Hiperplazia prostatei" },
  { value: "702", label: "702 - Hidrocelul si spermatocelul" },
  { value: "703", label: "703 - Orhita si epididimita" },
  { value: "704", label: "704 - Hipertrofia preputului, fimoza, parafimoza" },
  {
    value: "705",
    label: "705 - Alte afectiuni ale organelor genitale la barbat",
  },
  { value: "706", label: "706 - Displaziile mamare benigne" },
  { value: "707", label: "707 - Afectiunile inflamatorii ale sanului" },
  { value: "708", label: "708 - Alte afectiuni ale sanului" },
  { value: "709", label: "709 - Salpingita si ooforita" },
  {
    value: "710",
    label: "710 - Afectiuni inflamatorii ale uterului cu exceptia colului",
  },
  { value: "711", label: "711 - Afectiuni inflamatorii ale colului uterin" },
  {
    value: "712",
    label: "712 - Alte afectiuni inflamatorii pelviene la femeie",
  },
  { value: "713", label: "713 - Alte inflamatii ale vaginului si vulvei" },
  { value: "714", label: "714 - Endometrioza" },
  { value: "715", label: "715 - Prolapsul genital la femeie" },
  { value: "716", label: "716 - Fistulele tractului genital la femeie" },
  {
    value: "717",
    label:
      "717 - Afectiuni neinflamatorii ale ovarului trompei Fallope si ligamentului larg",
  },
  { value: "718", label: "718 - Polipul tractului genital la femeie" },
  {
    value: "719",
    label: "719 - Alte afectiuni neinflamatorii ale uterului, exceptand colul",
  },
  { value: "720", label: "720 - Eroziunea cu ectropion a colului uterin" },
  { value: "721", label: "721 - Displazia colului uterin" },
  {
    value: "722",
    label: "722 - Alte afectiuni neinflamatorii ale colului uterin",
  },
  { value: "723", label: "723 - Alte afectiuni neinflamatorii ale vaginului" },
  {
    value: "724",
    label: "724 - Alte afectiuni neinflamatorii ale vulvei si perineului",
  },
  { value: "725", label: "725 - Amenoreea, oligomenoreea, hipomenoreea" },
  { value: "726", label: "726 - Menoragia, polimenoreea si metroragia" },
  { value: "727", label: "727 - Sangerari anormale ale uterului si vaginului" },
  {
    value: "728",
    label:
      "728 - Dureri si alte afectiuni ale organelor genitale la femeie si tulburari ale ciclului menstrual",
  },
  {
    value: "729",
    label: "729 - Tulburarile menopauzei si alte tulburari ale premenopauzei",
  },
  { value: "730", label: "730 - Avort repetat" },
  { value: "731", label: "731 - Sterilitatea la femeie" },
  {
    value: "732",
    label:
      "732 - Afectiunile aparatului genito-urinar dupa un act medical de diagnostic si tratament, neclasate la alte locuri",
  },
  { value: "733", label: "733 - Sarcina extrauterina" },
  { value: "734", label: "734 - Mola hidatiforma si alte produse anormale" },
  { value: "735", label: "735 - Avortul spontan" },
  { value: "736", label: "736 - Alte forme de avort" },
  {
    value: "737",
    label:
      "737 - Complicatiile consecutive unui avort, unei sarcini extrauterine si molare",
  },
  {
    value: "738",
    label:
      "738 - Hipertensiunea preexistenta, complicand sarcina, nasterea si lauzia",
  },
  {
    value: "739",
    label:
      "739 - Sindrom hipertensiv preexistent, cu proteinurie supraadaugata",
  },
  {
    value: "740",
    label:
      "740 - Edem si proteinurie gestationala (legate de sarcina) fara hipertensiune",
  },
  {
    value: "741",
    label:
      "741 - Hipertensiune gestationala (legate de sarcina) fara proteinurie importanta",
  },
  {
    value: "742",
    label:
      "742 - Hipertensiune gestationala (legate de sarcina) cu proteinurie importanta",
  },
  { value: "743", label: "743 - Eclampsia" },
  { value: "744", label: "744 - Hipertensiunea mamei, fara precizare" },
  { value: "745", label: "745 - Hemoragia de la debutul sarcinii" },
  { value: "746", label: "746 - Voma incoercibila in cursul sarcinii" },
  { value: "747", label: "747 - Complicatii venoase ale sarcinii" },
  {
    value: "748",
    label: "748 - Infectiile aparatului genito-urinar in cursul sarcinii",
  },
  { value: "749", label: "749 - Diabetul zaharat in cursul sarcinii" },
  { value: "750", label: "750 - Malnutritia in cursul sarcinii" },
  {
    value: "751",
    label:
      "751 - Ingrijiri acordate mamei pentru alte afectiuni legate in principal de sarcina",
  },
  {
    value: "752",
    label:
      "752 - Rezultate anormale constatate in cursul examenelor prenatale sistematice ale mamei",
  },
  {
    value: "753",
    label: "753 - Complicatiile unei anestezii in cursul sarcinii",
  },
  { value: "754", label: "754 - Complicatiile specifice unei sarcini" },
  { value: "755", label: "755 - Hidramnios" },
  { value: "756", label: "756 - Ruptura prematura a membranelor" },
  { value: "757", label: '757 - Anomaliile placente - " placenta praevia' },
  {
    value: "758",
    label: "758 - Hemoragie precedand nasterea, neclasata la alte locuri",
  },
  {
    value: "759",
    label:
      '759 - Travalueiu inainte de termen sau prelungi - " inertie uterina',
  },
  {
    value: "760",
    label:
      "760 - Distocia de obstacol datorita unei pozitii si prezentari anormale a fatului",
  },
  {
    value: "761",
    label:
      "761 - Distocia de obstacol datorita unei anomalii pelviene a mamei si alte distocii",
  },
  {
    value: "762",
    label:
      "762 - Travalueiul si nasterea complicate cu hemoragie intrapartum, neclasate la alte locuri",
  },
  {
    value: "763",
    label:
      "763 - Travalueiul si nasterea complicate cu suferinta fetala si anomalii de cordon ombilical",
  },
  { value: "764", label: "764 - Ruptura perineala in cursul nasterii" },
  { value: "765", label: "765 - Alte traumatisme obstetricale" },
  { value: "766", label: "766 - Hemoragie post-partum" },
  {
    value: "767",
    label: "767 - Retentie de placenta si de membrane, fara hemoragie",
  },
  {
    value: "768",
    label:
      "768 - Complicatiile unei anestezii in cursul travalueiului si nasterii",
  },
  {
    value: "769",
    label:
      "769 - Alte complicatii ale travalueiului si nasterii, neclasate la alte locuri",
  },
  { value: "770", label: "770 - Infectia puerperala" },
  { value: "771", label: "771 - Alte infectii puerperale" },
  { value: "772", label: "772 - Complicatii venoase in timpul lauziei" },
  { value: "773", label: "773 - Embolia de origine obstetricala" },
  {
    value: "774",
    label: "774 - Complicatiile unei anestezii in cursul lauziei",
  },
  {
    value: "775",
    label: "775 - Complicatii puerperale, neclasate la alte locuri",
  },
  { value: "776", label: "776 - Infectia sanului asociata nasterii" },
  {
    value: "777",
    label:
      "777 - Alte afectiuni ale sanului asociate nasterii si tulburari de lactatie",
  },
  {
    value: "778",
    label:
      "778 - Fat si nou-nascut afectati de tulburarile materne cu si fara legatura cu sarcina actuala",
  },
  {
    value: "779",
    label: "779 - Fat si nou-nascut afectati de complicatiile sarcinii la mama",
  },
  {
    value: "780",
    label:
      "780 - Fat si nou-nascut afectati de complicatii privind placenta, cordonul ombilical si membrane",
  },
  {
    value: "781",
    label:
      "781 - Fat si nou-nascut afectati de alte complicatii ale travalueiului si nasterii",
  },
  {
    value: "782",
    label:
      "782 - Fat si nou-nascut afectati de efectele nocive transmise pe cale transplacentara sau prin laptele matern",
  },
  { value: "783", label: "783 - Intarzierea cresterii si malnutritia fatului" },
  {
    value: "784",
    label:
      "784 - Tulburari relative la scurtarea gestatiei si o greutate insuficienta la nastere, neclasate la alte locuri",
  },
  {
    value: "785",
    label:
      "785 - Tulburari privind prelungirea gestatiei si o dificultate crescuta la nastere",
  },
  {
    value: "786",
    label:
      "786 - Ruptura si hemoragie intracraniana datorita traumatismului obstetrical",
  },
  {
    value: "787",
    label:
      "787 - Alte leziuni ale sistemului nervos central datorita traumatismului",
  },
  { value: "788", label: "788 - Alte traumatisme obstetricale" },
  { value: "789", label: "789 - Hipoxie intrauterina" },
  { value: "790", label: "790 - Asfixia obstetricala" },
  { value: "791", label: "791 - Pneumopatia congenitala" },
  { value: "792", label: "792 - Sindroame de aspiratie in perioada neonatala" },
  {
    value: "793",
    label:
      "793 - Emfizem interstitial si afectiuni inrudite survenind in perioada perinatala",
  },
  {
    value: "794",
    label: "794 - Hemoragia pulmonara survenind in perioada perinatala",
  },
  {
    value: "795",
    label: "795 - Alte tulburari respiratorii survenind in perioada perinatala",
  },
  {
    value: "796",
    label: "796 - Tulburari cardio-vasculare survenind in perioada perinatala",
  },
  { value: "797", label: "797 - Rubeola congenitala" },
  { value: "798", label: "798 - Boli congenitale virotice" },
  { value: "799", label: "799 - Infectia bacteriana a nou-nascutului" },
  {
    value: "800",
    label: "800 - Alte boli infectioase si parazitare congenitale",
  },
  {
    value: "801",
    label: "801 - Omfalita nou-nascutului, cu sau fara hemoragie usoara",
  },
  { value: "802", label: "802 - Alte infectii specifice perioadei perinatale" },
  { value: "803", label: "803 - Hemoragii fetale si neonatale" },
  { value: "804", label: "804 - Boala hemolitica a fatului si nou-nascutului" },
  {
    value: "805",
    label: "805 - Anasarca feto-placentara datorita bolii hemolitice",
  },
  { value: "806", label: "806 - Icter nuclear (kernicter)" },
  {
    value: "807",
    label:
      "807 - Icter neonatal datorat altor hemolize excesive si altor cauze",
  },
  {
    value: "808",
    label: "808 - Alte tulburari hematologice ale perioadei perinatale",
  },
  {
    value: "809",
    label: "809 - Tulburari endocrine tranzitorii ale nou-nascutului",
  },
  { value: "810", label: "810 - Ocluzia intestinala la nou-nascut" },
  {
    value: "811",
    label: "811 - Enterocolita necrozanta a fatului si nou-nascutului",
  },
  {
    value: "812",
    label: "812 - Alte tulburari perinatale ale aparatului digestiv",
  },
  {
    value: "813",
    label:
      "813 - Afectiuni ale tegumentelor si reglarea termica a fatului si nou-nascutului",
  },
  { value: "814", label: "814 - Convulsiile nou-nascutului" },
  { value: "815", label: "815 - Alte tulburari cerebrale ale nou-nascutului" },
  { value: "816", label: "816 - Tulburari de alimentatie a nou-nascutului" },
  {
    value: "817",
    label: "817 - Reactii si intoxicatii medicamentoase la fat si nou-nascut",
  },
  { value: "818", label: "818 - Tulburari de tonus muscular la nou-nascut" },
  { value: "819", label: "819 - Moarte fetala de cauza neprecizata" },
  {
    value: "820",
    label:
      "820 - Alte afectiuni a caror origine se situeaza in perioada perinatala",
  },
  { value: "821", label: "821 - Microcefalia" },
  { value: "822", label: "822 - Hidrocefalia congenitala" },
  { value: "823", label: "823 - Alte malformatii congenitale ale creierului" },
  { value: "824", label: "824 - Spina bifida" },
  { value: "825", label: "825 - Alte malformatii ale maduvei spinarii" },
  {
    value: "826",
    label: "826 - Alte malformatii congenitale ale sistemului nervos",
  },
  { value: "827", label: "827 - Anoftalmia, microftalmia si macroftalmia" },
  {
    value: "828",
    label: "828 - Alte malformatii congenitale ale fetei si gatului",
  },
  {
    value: "829",
    label:
      "829 - Malformatii congenitale ale cavitatilor, orificiilor si ale septului cardiac",
  },
  { value: "830", label: "830 - Alte malformatii congenitale ale inimii" },
  { value: "831", label: "831 - Malformatii congenitale ale arterelor mari" },
  { value: "832", label: "832 - Malformatii congenitale ale venelor mari" },
  {
    value: "833",
    label: "833 - Alte malformatii congenitale ale aparatului circulator",
  },
  {
    value: "834",
    label:
      "834 - Malformatii congenitale si alte anomalii ale laringelui, traheii si bronhiilor",
  },
  { value: "835", label: "835 - Malformatia congenitala a pulmonului" },
  {
    value: "836",
    label: "836 - Alte malformatii congenitale ale aparatului respirator",
  },
  { value: "837", label: "837 - Fisura palatina" },
  { value: "838", label: "838 - Fisura labiala" },
  { value: "839", label: "839 - Buza de iepure cu palatoschisis" },
  {
    value: "840",
    label: "840 - Malformatii congenitale ale cailor digestive superioare",
  },
  {
    value: "841",
    label:
      "841 - Absenta, atrezia si stenoza congenitala a intestinului subtire",
  },
  {
    value: "842",
    label: "842 - Alte malformatii congenitale ale intestinului",
  },
  {
    value: "843",
    label:
      "843 - Malformatii congenitale ale vezicii biliare, cailor biliare si ficatului",
  },
  {
    value: "844",
    label: "844 - Alte malformatii congenitale ale aparatului digestiv",
  },
  {
    value: "845",
    label: "845 - Malformatiile ovarului, uterului si cervixului",
  },
  {
    value: "846",
    label: "846 - Alte malformatii congenitale ale organelor genitale feminine",
  },
  {
    value: "847",
    label: "847 - Malformatii congenitale ale organelor genitale masculine",
  },
  {
    value: "848",
    label: "848 - Agenezie renala si alte extinderi ale reducerii",
  },
  { value: "849", label: "849 - Boala chistica a rinichiului" },
  {
    value: "850",
    label: "850 - Alte malformatii congenitale ale aparatului urinar",
  },
  { value: "851", label: "851 - Deformatii congenitale ale soldului" },
  { value: "852", label: "852 - Deformatii congenitale ale piciorului" },
  {
    value: "853",
    label:
      "853 - Deformatii osoase ale capului, fetei, coloanei vertebrale, pieptului si alte deformatii",
  },
  { value: "854", label: "854 - Polidactilia si sindactilia" },
  { value: "855", label: "855 - Alte anomalii congenitale ale membrelor" },
  {
    value: "856",
    label:
      "856 - Malformatii si anomalii congenitale ale coloanei vertebrale, coaste si stern, capului si fetei",
  },
  {
    value: "857",
    label:
      "857 - Malformatii congenitale ale sistemuluui osteo-articular, neclasate la alte locuri",
  },
  {
    value: "858",
    label:
      "858 - Alte malformatii congenitale specificate, afectand sisteme multiple",
  },
  {
    value: "859",
    label: "859 - Alte malformatii congenitale, neclasate la alte locuri",
  },
  { value: "860", label: "860 - Sindromul Down" },
  { value: "861", label: "861 - Sindromul Edwards si sindromul Patau" },
  {
    value: "862",
    label:
      "862 - Alte trisomii si trisomii partiale (dublare) a autosomilor, neclasate la alte locuri",
  },
  {
    value: "863",
    label: "863 - Monosomia si lipsa de autosomi, neclasate la alte locuri",
  },
  {
    value: "864",
    label:
      "864 - Rearanjarea echilibrata si markerii structurali, neclasate la alte locuri",
  },
  { value: "865", label: "865 - Sindromul Turner" },
  {
    value: "866",
    label:
      "866 - Alte anomalii ale cromozomilor sexuali, fenotip feminin, neclasate la alte locuri",
  },
  {
    value: "867",
    label:
      "867 - Alte anomalii ale cromozomilor sexuali, fenotip masculin, neclasate la alte locuri",
  },
  {
    value: "868",
    label:
      "868 - Alte anomalii ale cromozomilor sexuali, neclasate la alte locuri",
  },
  {
    value: "869",
    label:
      "869 - Simptome si semne referitoare la aparatul circulator si respirator",
  },
  {
    value: "870",
    label:
      "870 - Simptome si semne referitoare la aparatul digestiv si la abdomen",
  },
  {
    value: "871",
    label:
      "871 - Simptome si semne referitoare la sistemul nervos si osteomuscular",
  },
  {
    value: "872",
    label: "872 - Simptome si semne referitoare la aparatul urinar",
  },
  {
    value: "873",
    label:
      "873 - Simptome si semne referitoare la constienta, perceptie, comportament si limbaj",
  },
  { value: "874", label: "874 - Simptome si semne generale" },
  {
    value: "875",
    label: "875 - Rezultate anormale ale examenelor de sange fara diagnostic",
  },
  {
    value: "876",
    label:
      "876 - Rezultate anormale ale examenelor de urina fara diagnostic de boala",
  },
  {
    value: "877",
    label:
      "877 - Rezultate anormale ale examinarii altor lichide, substante si tesuturi, fara diagnostic",
  },
  {
    value: "878",
    label:
      "878 - Rezultate anormale ale imaginii diagnostice si probelor functionale, fara diagnostic",
  },
  { value: "879", label: "879 - Leziunea traumatica superficiala a capului" },
  { value: "880", label: "880 - Plaga deschisa a capului" },
  { value: "881", label: "881 - Fractura craniului si oaselor fetei" },
  {
    value: "882",
    label:
      "882 - Luxatia, entorsa si intinderea de ligamente ale articulatiei capului",
  },
  { value: "883", label: "883 - Leziunea traumatica a nervilor cranieni" },
  { value: "884", label: "884 - Leziunea traumatica a ochiului si orbitei" },
  { value: "885", label: "885 - Leziunea traumatica intracraniana" },
  { value: "886", label: "886 - Zdrobirea traumatica a capului" },
  { value: "887", label: "887 - Amputatia traumatica partiala a capului" },
  {
    value: "888",
    label: "888 - Leziuni traumatice ale capului alte si fara precizare",
  },
  { value: "889", label: "889 - Leziunea traumatica superficiala a gatului" },
  { value: "890", label: "890 - Plaga deschisa a gatului" },
  { value: "891", label: "891 - Fractura gatului" },
  {
    value: "892",
    label:
      "892 - Luxatia, entorsa si intinderea de ligamente ale articulatiei gatului",
  },
  {
    value: "893",
    label:
      "893 - Leziunea traumatica a nervilor si maduvei spinarii la nivelul gatului",
  },
  {
    value: "894",
    label: "894 - Leziunea traumatica a vaselor sangvine la nivelul gatului",
  },
  {
    value: "895",
    label: "895 - Leziunea traumatica a muSchilor si tendoanelor gatului",
  },
  { value: "896", label: "896 - Zdrobirea traumatica a gatului" },
  { value: "897", label: "897 - Amputatia traumatica la nivelul gatului" },
  {
    value: "898",
    label: "898 - Leziuni traumatice ale gatului, alte neprecizate",
  },
  { value: "899", label: "899 - Leziunea traumatica superficiala a toracelui" },
  { value: "900", label: "900 - Plaga deschisa a toracelui" },
  {
    value: "901",
    label: "901 - Fractura de coaste, stern si coloana vertebrala",
  },
  {
    value: "902",
    label:
      "902 - Luxatia, entorsa si intinderea de ligamente ale articulatiei toracelui",
  },
  {
    value: "903",
    label:
      "903 - Leziunea traumatica a nervilor si maduvei spinarii la nivelul toracelui",
  },
  {
    value: "904",
    label: "904 - Leziunea traumatica a vaselor sangvine la nivelul toracelui",
  },
  { value: "905", label: "905 - Leziunea traumatica a inimii" },
  {
    value: "906",
    label:
      "906 - Leziuni traumatice ale ale organelor intratoracice, alte si neprecizate",
  },
  {
    value: "907",
    label:
      "907 - Zdrobirea traumatica a toracelui si amputarea traumatica partiala a toracelui",
  },
  {
    value: "908",
    label: "908 - Leziuni traumatice ale toracelui, alte si fara precizare",
  },
  {
    value: "909",
    label:
      "909 - Leziunea traumatica superficiala a abdomenului, lombelor si bazinului",
  },
  {
    value: "910",
    label: "910 - Plaga deschisa a abdomenului, lombelor si bazinului",
  },
  {
    value: "911",
    label: "911 - Fractura de coloanei vertebrale si a bazinului",
  },
  {
    value: "912",
    label:
      "912 - Luxatia, entorsa si intinderea de ligamente ale articulatiei coloanei vertebrale si bazinului",
  },
  {
    value: "913",
    label:
      "913 - Leziunea nervilor si a coloanei vertebrale lombare si a abdomenului, partea inferioara a spatelui si a bazinului",
  },
  {
    value: "914",
    label:
      "914 - Leziunea traumatica a vaselor sangvine la nivelul abdomenului, lombelor si bazinului",
  },
  {
    value: "915",
    label: "915 - Leziunea traumatica a organelor intraabdominale",
  },
  { value: "916", label: "916 - Leziunea traumatica a organelor pelviene" },
  {
    value: "917",
    label:
      "917 - Zdrobirea si amputatia traumatica partiala a abdomenului, lombelor si bazinului",
  },
  {
    value: "918",
    label:
      "918 - Leziuni traumatice ale abdomenului,lombelor si bazinului alte si fara precizare",
  },
  { value: "919", label: "919 - Leziune traumatica superficiala" },
  { value: "920", label: "920 - Plaga deschisa" },
  { value: "921", label: "921 - Fractura" },
  {
    value: "922",
    label: "922 - Luxatia, entorsa si intinderea de ligamente ale articulatiei",
  },
  { value: "923", label: "923 - Leziunea traumatica a nervilor" },
  { value: "924", label: "924 - Leziunea traumatica a vaselor sanguine" },
  {
    value: "925",
    label: "925 - Leziunea traumatica a mulchilor si tendoanelor",
  },
  { value: "926", label: "926 - Zdrobirea traumatica" },
  { value: "927", label: "927 - Amputatia traumatica" },
  { value: "928", label: "928 - Leziuni traumatice, alte si neprecizate" },
  { value: "929", label: "929 - Leziune traumatica superficiala" },
  { value: "930", label: "930 - Plaga deschisa" },
  { value: "931", label: "931 - Fractura" },
  {
    value: "932",
    label: "932 - Luxatia, entorsa si intinderea de ligamente ale articulatiei",
  },
  { value: "933", label: "933 - Leziunea traumatica a nervilor" },
  { value: "934", label: "934 - Leziunea traumatica a vaselor sanguine" },
  {
    value: "935",
    label: "935 - Leziunea traumatica a muschilor si tendoanelor",
  },
  { value: "936", label: "936 - Zdrobirea traumatica" },
  { value: "937", label: "937 - Amputatia traumatica" },
  { value: "938", label: "938 - Leziuni traumatice, alte si neprecizate" },
  {
    value: "939",
    label: "939 - Leziuni traumatice superficiale corpului cu multe localizari",
  },
  {
    value: "940",
    label: "940 - Plagi deschise ale corpului cu mai multe localizari",
  },
  {
    value: "941",
    label: "941 - Fracturi ale corpului cu alte localizari multiple",
  },
  {
    value: "942",
    label:
      "942 - Luxatii, entorse si intinderi de ligament ale corpului cu localizari multiple",
  },
  {
    value: "943",
    label: "943 - Zdrobiri traumatice ale corpului cu localizari multiple",
  },
  {
    value: "944",
    label: "944 - Amputatii traumatice ale corpului cu localizari multiple",
  },
  {
    value: "945",
    label:
      "945 - Alte leziuni traumatice ale corpului cu localizari multiple, neclasate la alte locuri",
  },
  { value: "946", label: "946 - Leziuni traumatice multiple, fara precizare" },
  { value: "947", label: "947 - Corp strain in partea externa a ochiului" },
  { value: "948", label: "948 - Corp strain in ureche" },
  { value: "949", label: "949 - Corp strain in caile respiratorii" },
  { value: "950", label: "950 - Corp strain in caile digestive" },
  { value: "951", label: "951 - Corp strain in caile genito-urinare" },
  {
    value: "952",
    label: "952 - Arsura si coroziunea capului, gatului si trunchiului",
  },
  { value: "953", label: "953 - Arsura si coroziunea membrului superior" },
  { value: "954", label: "954 - Arsura si coroziunea membrului inferior" },
  {
    value: "955",
    label: "955 - Arsuri si coroziuni limitate la ochi si anexele sale",
  },
  { value: "956", label: "956 - Arsuri si coroziuni ale altor organe interne" },
  {
    value: "957",
    label: "957 - Arsuri si coroziuni ale corpului cu localizari multiple",
  },
  {
    value: "958",
    label: "958 - Arsuri si coroziuni ale corpului cu localizari neprecizate",
  },
  {
    value: "959",
    label: "959 - Arsuri clasate dupa intinderea lezata a suprafetei corpului",
  },
  {
    value: "960",
    label:
      "960 - Coroziuni clasate dupa intinderea lezata a suprafetei corpului",
  },
  { value: "961", label: "961 - Degeratura superficiala" },
  { value: "962", label: "962 - Degeratura cu necroza de tesut" },
  {
    value: "963",
    label: "963 - Degeratura corpului cu localizari multiple si fara precizare",
  },
  {
    value: "964",
    label: "964 - Intoxicatii prin medicamente si substante biologice",
  },
  {
    value: "965",
    label:
      "965 - Efectele toxice ale substantelor, in special nemedicamentoase",
  },
  { value: "966", label: "966 - Efectele radiatiilor fara precizare" },
  { value: "967", label: "967 - Efectele caldurii si ale luminii" },
  { value: "968", label: "968 - Hipotermia" },
  {
    value: "969",
    label: "969 - Efectele presiunii atmosferice si ale presiunii apei",
  },
  { value: "970", label: "970 - Asfixia" },
  { value: "971", label: "971 - sindroame datorite unor maltratari" },
  { value: "972", label: "972 - Efectele altor cauze externe" },
  {
    value: "973",
    label:
      "973 - Complicatii ale actelor medicale de diagnostic si tratament, neclasate la alte locuri",
  },
  {
    value: "974",
    label:
      "974 - Complicatiile protezelor, implantelor si grefelor cardiace si vasculare",
  },
  {
    value: "975",
    label:
      "975 - Alte complicatii ale tratamentului chirurgical si medical, neclasate la alte locuri",
  },
  { value: "976", label: "976 - Accidente de transport" },
  { value: "977", label: "977 - Accidente de transport pe apa" },
  {
    value: "978",
    label: "978 - Accidente de transport aerian si de zbor spatial",
  },
  {
    value: "979",
    label: "979 - Accidente de transport, alte si fara precizare",
  },
  { value: "980", label: "980 - Caderi" },
  { value: "981", label: "981 - Expunere la fortele mecanice" },
  {
    value: "982",
    label:
      "982 - Inecul si submersia accidentala si alte accidente afectind respiratia",
  },
  {
    value: "983",
    label:
      "983 - Expunerea la curentul electric, radiatii, temperaturi si presiuni extreme ale mediului ambiant",
  },
  {
    value: "984",
    label:
      "984 - Expunerea la fum, foc si flacari, contact cu o sursa de caldura si de substante arzinde",
  },
  { value: "985", label: "985 - Expunerea la fortele naturii" },
  {
    value: "986",
    label: "986 - Intoxicatii accidentale prin expunere la substante nocive",
  },
  {
    value: "987",
    label: "987 - Expunerea accidentala la factori, altii si fara precizare",
  },
  { value: "988", label: "988 - Leziuni auto-provocate" },
  { value: "989", label: "989 - Agresiunea" },
  {
    value: "990",
    label: "990 - Evenimente a caror intentie nu este precizata",
  },
  {
    value: "991",
    label: "991 - Interventia fortei publice si actiuni de razboi",
  },
  {
    value: "992",
    label:
      "992 - Complicatiile ingrijirilor medicale si chirurgicale, medicamente si substante biologice care au provocat efecte indezirabile in cursul utilizarii terapeutice",
  },
  {
    value: "993",
    label:
      "993 - Persoane in contact cu serviciile de sanatate pentru diverse investigatii",
  },
  {
    value: "994",
    label:
      "994 - Persoane susceptibile de a fi contaminate cu boli transmisibile",
  },
  {
    value: "995",
    label:
      "995 - Persoane care se adreseaza serviciilor de sanatate pentru motive legate de reproductie",
  },
  {
    value: "996",
    label:
      "996 - Recurgere la servicii de sanatate pentru acte medicale specifice si de ingrijire medicala",
  },
  {
    value: "997",
    label:
      "997 - Persoane cu risc de sanatate conditionat de factori socio-economici sau psiho-sociali",
  },
  {
    value: "998",
    label: "998 - Alte motive de recurgere la serviciile de sanatate",
  },
  {
    value: "999",
    label:
      "999 - Antecedente personale si familiale putind crea riscuri latente de boala si unele afectiuni cu efect asupra starii de sanatate",
  },
];
const Login = () => {
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });
  const [selected, setSelected] = useState(OMS_CODES[0]);
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? OMS_CODES
      : OMS_CODES.filter((person) =>
          person.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  const handleInputChange = (e) => {
    let fieldsCopy = Object.assign({}, fields);

    fieldsCopy[e.target.id] = e.target.value;

    setFields(fieldsCopy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    logInWithEmailAndPassword(fields);
  };
  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Autentifica-te in contul tau
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={fields.email}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Parola
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={fields.password}
                    onChange={handleInputChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div> */}

              <div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Autentificare
                </button>
              </div>
            </form>

            {/* <div>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-4">
                <a
                  href="#"
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                >
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                  <span className="text-sm font-semibold leading-6">
                    Twitter
                  </span>
                </a>

                <a
                  href="#"
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                >
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">
                    GitHub
                  </span>
                </a>
              </div>
            </div> */}
          </div>

          {/* <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="#"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Start a 14 day free trial
            </a>
          </p> */}
        </div>
      </div>
    </>
  );
};

export default Login;
