import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import axios from "axios";

const NewAfmModal = ({ open, setOpen, setClients }) => {
  const cancelButtonRef = useRef(null);
  const [newPatient, setNewPatient] = useState({
    NUME: "",
    PRENUME: "",
    CNP: "",
    TELEFON: "",
  });

  const addClientAfm = (e) => {
    e.preventDefault();

    const res = axios
      .post(
        "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/addClientAfm.php",
        newPatient
      )
      .then((response) => {
        axios
          .get(
            "https://www.panouri-decorative.eu/wp-content/themes/panouri-decorative/getAfmClients.php"
          )
          .then((response) => setClients(response.data));
        setOpen(false);
      });

    toast.promise(
      res,
      {
        pending: "Se adaugă...",
        success: "Dosar adăugat cu succes!",
        error: "A apărut o eroare!",
      },
      {
        style: {
          minWidth: "250px",
        },
      }
    );
  };

  const onChangeNewPatient = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNewPatient((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Adaugă pacient
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Completează datele pacientului pe care vrei să îl adaugi
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <label
                      htmlFor="NUME"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nume
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={onChangeNewPatient}
                        type="text"
                        name="NUME"
                        id="NUME"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="PRENUME"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                    >
                      Prenume
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={onChangeNewPatient}
                        type="text"
                        name="PRENUME"
                        id="PRENUME"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="CNP"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                    >
                      CNP
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={onChangeNewPatient}
                        type="text"
                        name="CNP"
                        id="CNP"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="CNP"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                    >
                      TELEFON
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={onChangeNewPatient}
                        type="text"
                        name="TELEFON"
                        id="TELEFON"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={(e) => {
                      addClientAfm(e);
                    }}
                  >
                    Adaugă
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Renunță
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewAfmModal;
