import Header from "./Header";
import L from "leaflet";
import axios from "axios";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { Marker } from "react-leaflet/Marker";
import { Popup } from "react-leaflet/Popup";
import "leaflet/dist/leaflet.css";
import { iconPerson, iconPersonBlack, iconPersonRed } from "./icons.js";
import Leaflet from "leaflet";
import csvFile from "./lista_afm_csv.csv"; // Asigurați-vă că aveți calea corectă către fișierul CSV
// const xlsxFile = require("./afmlist.csv");
import MarkerClusterGroup from "react-leaflet-cluster";

const csvUrl =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTJec_8eiYyNREo0kJvZq7MZcLg30P_T20P-H66k39vMrbxkHeR06HTIYVJN27aeQ/pub?gid=1812622393&single=true&output=csv";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Harta = () => {
  const [csvData, setCsvData] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  useEffect(() => {
    const fetchXLSXFile = async () => {
      try {
        const response = await fetch(csvUrl); // Înlocuiți cu calea corectă
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Asumăm că există doar o foaie de lucru
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          console.log(jsonData);

          // jsonData este un array de obiecte, unde fiecare obiect reprezintă o linie din CSV
          // Puteți afișa, procesa sau stoca aceste date așa cum doriți
          let newjson = jsonData.slice(1, jsonData.length);
          newjson.forEach((element) => {
            console.log(element[14]);
          });
          setCsvData(newjson);
        };

        reader.readAsBinaryString(blob);
      } catch (error) {
        console.error("Eroare la încărcarea fișierului XLSX:", error);
      }
    };

    fetchXLSXFile();
  }, []); // [] asigură că acest useEffect
  // const [map, setMap] = useState(L);
  const [montatStatus, setMontatStatus] = useState("");
  const [semnatStatus, setSemnatStatus] = useState("");
  const [numeBeneficiar, setNumeBeneficiar] = useState("");
  const [adresaBeneficiar, setAdresaBeneficiar] = useState("");

  return (
    <>
      <Header />
      <div>
        <div>
          <div className="bg-white shadow overflow-hidden sm:rounded-md my-4">
            <div className="flex gap-5 px-4 py-2 flex-wrap">
              <div className="flex-none">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Montaj
                </label>
                <select
                  id="location"
                  name="location"
                  onChange={(e) => setMontatStatus(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue="Canada"
                >
                  <option value={""}>Tot</option>
                  <option value={"Montat"}>Montat</option>
                  <option value={"Nemontat"}>Nemontat</option>
                </select>
              </div>
              <div className="flex-none">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Semnat
                </label>
                <select
                  id="location"
                  name="location"
                  onChange={(e) => setSemnatStatus(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue="Canada"
                >
                  <option value={""}>Tot</option>
                  <option value={"Semnat"}>Semnat</option>
                  <option value={"Nesemnat"}>Nesemnat</option>
                </select>
              </div>
              <div className="flex-none">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nume beneficiar
                </label>
                <input
                  id="location"
                  name="location"
                  onChange={(e) => setNumeBeneficiar(e.target.value)}
                  value={numeBeneficiar}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue="Canada"
                />
              </div>
              <div className="flex-none">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Adresa
                </label>
                <input
                  id="location"
                  name="location"
                  onChange={(e) => setAdresaBeneficiar(e.target.value)}
                  value={adresaBeneficiar}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue="Canada"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 md:px-10 mb-10 pb-10">
          <MapContainer
            center={[44.924990895649486, 25.46056688461607]}
            zoom={9}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup chunkedLoading maxClusterRadius={50}>
              {csvData
                ?.filter((el) => {
                  // verifiy if el[14] is number
                  if (
                    montatStatus &&
                    el[6] != montatStatus &&
                    montatStatus != ""
                  ) {
                    return false;
                  }

                  if (
                    semnatStatus &&
                    el[4] != semnatStatus &&
                    semnatStatus != ""
                  ) {
                    return false;
                  }

                  if (
                    el[2] &&
                    el[2]
                      .toLowerCase()
                      .includes(numeBeneficiar.toLowerCase()) == false &&
                    numeBeneficiar != "" &&
                    numeBeneficiar != null
                  ) {
                    return false;
                  }

                  if (
                    el[13] &&
                    el[13]

                      .toLowerCase()
                      .includes(adresaBeneficiar.toLowerCase()) == false &&
                    adresaBeneficiar != "" &&
                    adresaBeneficiar != null
                  ) {
                    return false;
                  }

                  if (el[14] && el[15] && !isNaN(el[14]) && !isNaN(el[15])) {
                    return true;
                  }

                  return false;
                })
                .map((row, rowIndex) => (
                  <Marker
                    position={[row[14], row[15]]}
                    className="bg-red-200"
                    icon={
                      row[6] == "Montat"
                        ? iconPersonBlack
                        : row[4] == "Nesemnat"
                        ? iconPersonRed
                        : iconPerson
                    }
                  >
                    <Popup>
                      {row[2]} <br /> {row[13]} <br />
                      Tel: {row[9]}
                    </Popup>
                  </Marker>
                ))}
            </MarkerClusterGroup>
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default Harta;
